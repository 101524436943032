import { useSelector } from 'react-redux';
import { NeedANewSleepStudy } from '../../components/SleepStudy';
import { useNavigate } from 'react-router-dom';
import { determineUploadStep } from '../../utils/constants';

function SleepStudyInReview() {
    const navigate = useNavigate();

    const {
        coachingPathWithoutPrescription,
        requiresCharts,
        uploadedCharts,
        uploadedPrescription,
        uploadedSleepStudy,
    } = useSelector((state) => state.files);
    const { isCoachingOnlyPath } = useSelector((state) => state.ui);

    const uploadStep = determineUploadStep({
        coachingPathWithoutPrescription,
        requiresCharts,
        uploadedCharts,
        uploadedPrescription,
        uploadedSleepStudy,
    });

    if (uploadStep === 'prescription') {
        return (
            <div className="layout-outlet-root max-w-3xl space-y-4">
                <h2 className="font-heading text-[44px] lg:text-7xl flex items-center">
                    <span>You're almost done!</span>
                </h2>
                <p className="leading-normal lg:text-xl">
                    You have successfully uploaded your Sleep Study.
                </p>
                <p className="leading-normal lg:text-xl">
                    You have <span className="font-bold">not</span> uploaded your{' '}
                    <span className="font-bold">prescription</span>. Please upload a copy of your
                    prescription so we can determine your eligibility.
                </p>
                <button
                    className="btn-primary w-full"
                    onClick={() => {
                        navigate('../study-upload');
                    }}>
                    Upload Prescription
                </button>
                {!isCoachingOnlyPath && <NeedANewSleepStudy />}
            </div>
        );
    } else if (uploadStep === 'clinical visit summary') {
        return (
            <div className="layout-outlet-root max-w-3xl space-y-4">
                <h2 className="font-heading text-[44px] lg:text-7xl flex items-center">
                    <span>You're almost done!</span>
                </h2>
                <p className="leading-normal lg:text-xl">
                    You have successfully uploaded your Sleep Study and Prescription
                </p>
                <p className="leading-normal lg:text-xl">
                    You have <span className="font-bold">not</span> uploaded your{' '}
                    <span className="font-bold">clinical charts</span>. Please upload a copy of your
                    clinical charts so we can determine your eligibility.
                </p>
                <button
                    className="btn-primary w-full"
                    onClick={() => {
                        navigate('../study-upload');
                    }}>
                    Upload Clinical Charts
                </button>
                <NeedANewSleepStudy />
            </div>
        );
    } else if (isCoachingOnlyPath) {
        return (
            <div className="layout-outlet-root max-w-3xl">
                <h2 className="font-heading text-[44px] lg:text-7xl mb-6 flex items-center">
                    <span>Documents Received</span>
                </h2>
                <p className="mb-4 leading-normal lg:text-xl">There is just one more step!</p>
                <p className="mb-4 leading-normal lg:text-xl">
                    You will receive an additional coaching program intake form via email to
                    complete. Once we receive this form, a GEM SLEEP provider will review your
                    information to see if you are a good fit for the GEM SLEEP COACHING Program.
                </p>
                <p className="mb-4 leading-normal lg:text-xl">
                    You will continue to receive notices via email on next steps!
                </p>
            </div>
        );
    } else {
        return (
            <div className="layout-outlet-root max-w-3xl">
                <h2 className="font-heading text-[44px] lg:text-7xl mb-6 flex items-center">
                    <span>Your Sleep Study is in Good Hands!</span>
                </h2>
                <p className="mb-4 leading-normal lg:text-xl">
                    You have successfully uploaded all of your files.
                </p>
                <p className="mb-4 leading-normal lg:text-xl">
                    Our GEM SLEEP Providers will complete their review within 5-7 business days.
                    Once the review is complete you will receive a notification to review your
                    personalized treatment recommendation.
                </p>
                <p className="mb-4 leading-normal lg:text-xl">
                    If more information is needed, we will contact you directly via the phone number
                    provided.
                </p>
                <p className="mb-8 leading-normal lg:text-xl font-bold">Sleep well!</p>
            </div>
        );
    }
}

export default SleepStudyInReview;
