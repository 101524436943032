import { useSelector } from 'react-redux';
import { Accordion, MaskComparisonGrid } from '..';

const ProductGuidePDF = ({ bundle }) => {
    const { selectedProduct } = useSelector((state) => state.catalog);
    const productForLink = selectedProduct ? selectedProduct : bundle;
    const link = Object.values(productForLink?.customAttributeValues)?.find(
        (att) => att.name === 'Mask System User Guide',
    );

    return link ? (
        <p>
            <a
                href={link.stringValue}
                className="border-b-2 border-gem-green font-semibold text-gray-darker"
                target="_blank"
                rel="noreferrer"
                download>
                Mask Product Guide
            </a>
        </p>
    ) : null;
};

const StarterKitAccordion = ({ bundle }) => {
    const CPAPBundlesAccordionItems = [
        {
            title: "What's included?",
            content: (
                <>
                    <div className="text-xs lg:text-base">
                        All GEM SLEEP STARTER KITS INCLUDE:
                        <ol className="list-decimal pl-4">
                            <li>
                                Your choice of Auto-CPAP Machine which includes: heated tubing,
                                humidifier, water chamber, cellular connectivity, and plug-in.
                            </li>
                            <li>
                                Your selected Mask System (All systems come with the parts necessary
                                for a complete set-up)
                            </li>
                            <li>
                                90 Day Replacement Parts (Machine and parts require regular cleaning
                                and replacement)
                            </li>
                        </ol>
                        <p className="mt-4 text-xs">
                            Specific requirements of employers and/or health plans for managing
                            Sleep Apnea may vary. Please review our CPAP{' '}
                            <a
                                className="link text-xs"
                                href={`/terms/return-and-cancellation-policy`}
                                target="_blank"
                                rel="noreferrer">
                                return policy
                            </a>{' '}
                            prior to purchase.
                        </p>
                    </div>
                </>
            ),
        },
        {
            title: 'Mask comparison guide',
            content: <MaskComparisonGrid />,
        },
        {
            title: 'Resources',
            content: (
                <>
                    <ProductGuidePDF bundle={bundle} />
                    <p>
                        <a
                            href="https://document.resmed.com/documents/products/machine/airsense-11/user-guide/airsense11_user-guide_amer_mul.pdf"
                            className="border-b-2 border-gem-green font-semibold text-gray-darker"
                            target="_blank"
                            rel="noreferrer"
                            download>
                            ResMed AirSense 11 CPAP User Guide
                        </a>
                    </p>
                    <p>
                        <a
                            href="https://www.reacthealth.com/uploads/userfiles/files/documents/Luna%20G3%20APAP%20User%20Manual.pdf"
                            className="border-b-2 border-gem-green font-semibold text-gray-darker"
                            target="_blank"
                            rel="noreferrer"
                            download>
                            React Luna G3 CPAP User Guide
                        </a>
                    </p>
                </>
            ),
        },
    ];

    return (
        <Accordion
            className="mb-12 lg:mb-24"
            items={CPAPBundlesAccordionItems}
            theme="light"
            type="product"
        />
    );
};

export default StarterKitAccordion;
