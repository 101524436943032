import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../features/ui/uiSlice';
import { Link } from 'react-router-dom';
import { ReactComponent as CartIcon } from '../../images/icons/cart.svg';
import { ReactComponent as UploadIcon } from '../../images/icons/upload.svg';
import watchpatImageSrc from '../../images/sleep-study-product/watchpat.webp';

const GetDiagnosed = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { hasOrderedHST } = useSelector((state) => state.orders);

    const uploadedSleepStudyNotApproved =
        !hasOrderedHST && user?.patientProfile?.mdStatus === 'UPLOADED_SLEEP_STUDY_INCOMPLETE';

    return (
        <>
            <div className="layout-outlet-root max-w-[1400px]">
                <h3 className="mb-4 font-heading text-[44px] lg:text-7xl">
                    Let's get you diagnosed
                </h3>
                {uploadedSleepStudyNotApproved ? (
                    <p className="mb-7 max-w-5xl text-sm lg:mb-12 lg:text-lg">
                        The uploaded sleep study you provided has missing information that we need
                        to move ahead with therapy, a new sleep study is required.
                    </p>
                ) : (
                    <p className="mb-7 max-w-5xl text-sm lg:mb-12 lg:text-lg">
                        There are two simple ways to get treatment options from GEM SLEEP: Arrange
                        for a home sleep study, or upload your own sleep study report. A GEM SLEEP
                        Provider will review the report and provide options to start a new treatment
                        or continue your existing treatment through GEM.
                    </p>
                )}

                <div className="relative mb-10 bg-white lg:flex">
                    <div className="relative min-w-[240px] lg:w-[240px]">
                        <img
                            src={watchpatImageSrc}
                            alt="A CPAP bundle mask"
                            className="lg:absolute-center mx-auto object-cover object-center lg:mx-0"
                        />
                    </div>

                    <div className="p-4 pb-6 lg:py-8 lg:px-14">
                        <h5 className="mb-3 font-heading text-4xl lg:text-5xl">
                            {uploadedSleepStudyNotApproved
                                ? 'New Sleep Study Required'
                                : 'Get a home sleep study'}
                        </h5>
                        <p className="mb-4 text-[13px] leading-normal lg:text-base">
                            GEM SLEEP provides WatchPat&trade; devices for your Home Sleep Test.
                            Complete the study with just one night of sleep -- no cords, no wires,
                            no lab -- the entire study is done completely at home.
                        </p>
                        <div className="items-center space-y-4 lg:flex lg:space-y-0 lg:space-x-4">
                            <Link
                                to="/dashboard/diagnosis/gem-sleep-study"
                                className="btn-primary flex items-center justify-between space-x-4">
                                <span>Order a sleep study</span>
                                <CartIcon />
                            </Link>
                        </div>
                    </div>
                </div>

                {!uploadedSleepStudyNotApproved && (
                    <div className="items-start justify-between lg:flex">
                        <div className="lg:mr-24">
                            <h4 className="mb-4 text-lg font-bold lg:text-2xl">
                                Already have a sleep study report?
                            </h4>
                            <p className="mb-4 max-w-5xl text-sm lg:text-lg">
                                No need to get a new test! Just upload the sleep study
                                report/diagnosis from your prior study. A GEM SLEEP Provider will
                                review and provide you with treatment options; which may include: a
                                CPAP machine, CPAP Supplies (for existing machine), or a referral to
                                Dental Sleep Machine.
                            </p>
                            <div className="mb-6 flex flex-row items-center space-x-6 text-lg lg:mb-0">
                                <button
                                    className="font-heading tracking-widest text-green-600 transition hover:text-green-hover"
                                    onClick={() => {
                                        dispatch(toggleModal('sleepReport'));
                                    }}>
                                    What is a sleep study report?
                                </button>
                                <button
                                    className="font-heading tracking-wider text-green-600 transition hover:text-green-hover"
                                    onClick={() => {
                                        dispatch(toggleModal('problemsUploading'));
                                    }}>
                                    Having problems uploading?
                                </button>
                            </div>
                        </div>
                        <Link
                            to="/dashboard/diagnosis/study-upload"
                            className="btn-primary flex items-center justify-between space-x-4">
                            <span>upload my sleep report</span>
                            <UploadIcon />
                        </Link>
                    </div>
                )}
            </div>
        </>
    );
};

export default GetDiagnosed;
