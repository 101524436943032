import { useSelector } from 'react-redux';
import { insuranceCreateSchema } from '../../utils/schemas';
import { useLocation, useNavigate } from 'react-router-dom';
import InsuranceForm, { FormType } from '../../components/Insurance/InsuranceForm';
import { useInsuranceContext } from '../../components/Insurance/InsuranceContext';
import { PageHeaderWithBack } from '../../components/LayoutComponents';
import { toast } from 'react-toastify';

const AddInsurance = () => {
    const { addPatientInsurance } = useInsuranceContext();
    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();

    const insuranceSubmitHandler = async (data) => {
        const addInsuranceResult = await addPatientInsurance(data);
        if (addInsuranceResult?.status === 'error') {
            toast.error(addInsuranceResult.data?.message);
        } else
            navigate(location.state?.from, {
                state: { from: '/dashboard/insurance/add' },
            });
    };

    return (
        <>
            <PageHeaderWithBack
                onBack={() =>
                    navigate(location.state?.from, {
                        state: { from: '/dashboard/insurance/add' },
                    })
                }
                title="Add Insurance"
            />

            <InsuranceForm
                formType={FormType.ADD}
                user={user}
                buttonCopy={`Add Insurance`}
                validationSchema={insuranceCreateSchema}
                onSubmit={insuranceSubmitHandler}
            />
        </>
    );
};

export default AddInsurance;
