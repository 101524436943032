import { useRequestPasswordResetMutation } from '../../app/services/auth';
import { Formik, Form } from 'formik';
import { CustomInput } from '../InputComponents';
import { passwordResetRequestSchema } from '../../utils/schemas';
import { useDispatch } from 'react-redux';
import { toggle } from '../../features/ui/uiSlice';
import posthog from 'posthog-js';

const AccountPasswordReset = () => {
    const dispatch = useDispatch();
    const [requestPasswordReset, { error, isSuccess }] = useRequestPasswordResetMutation();

    return (
        <>
            <button
                className="font-heading font-bold absolute left-5 lg:left-9 top-8 lg:top-16"
                onClick={() => dispatch(toggle({ key: 'passwordResetScreenOpen' }))}>
                Cancel
            </button>
            <h5 className="font-heading font-bold mb-5 text-4xl lg:text-5xl">Reset password</h5>
            <p className="text-xs lg:text-base mb-12">
                Enter the email you used to create your GEM account and we'll send you a link to
                reset your password.
            </p>

            {error && error.data?.message && (
                <p className="font-bold text-red mb-6">{error.data.message}</p>
            )}

            {isSuccess && (
                <p className="font-bold text-green-400 mb-6">
                    Success, check your email for your password reset link.
                </p>
            )}

            <Formik
                initialValues={{ email: '' }}
                validationSchema={passwordResetRequestSchema}
                onSubmit={async (data) => {
                    await requestPasswordReset({
                        email: data.email,
                        redirectUrl: window.location.origin + '/reset-password',
                    });

                    posthog?.capture('Submitted password reset request');
                }}>
                <Form className="ph-no-capture space-y-7">
                    <CustomInput
                        type="email"
                        name="email"
                        label="Email"
                        autoComplete="username"
                        theme="light"
                    />
                    <button className="btn-primary w-full" type="submit">
                        Submit request
                    </button>
                </Form>
            </Formik>
        </>
    );
};

export default AccountPasswordReset;
