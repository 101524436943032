import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useGetAdminProductsQuery } from '../../../app/services/shop';
import ProductEditForm from './ProductEditForm';
import './shop.scss';

const Products = () => {
    const { data } = useGetAdminProductsQuery();
    const { products, variantTypes } = data || {};

    const [isEditing, setIsEditing] = useState(false);
    const [activeProduct, setActiveProduct] = useState(null);
    const [productsList, setProductsList] = useState([]);
    const [sortField, setSortField] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');

    const sortFields = [
        { name: 'Name', value: 'name' },
        { name: 'Updated At', value: 'updatedAt' },
    ];

    const getProductImage = (product) => {
        return (
            (product.images?.length > 0 && product.images[0]) ||
            product.variants?.find((v) => v.featuredImageUrl)?.featuredImageUrl ||
            null
        );
    };

    const getProductPrice = (product) => {
        const price = product.variants?.length > 0 && product.variants[0].price;

        return price ? parseFloat(price).toFixed(2) : 'Unknown';
    };

    useEffect(() => {
        const sortProducts = () => {
            const p = [...products];
            p.sort((a, b) => {
                const propType = typeof a[sortField];
                let propA = propType === 'string' ? a[sortField].toUpperCase() : a[sortField];
                let propB = propType === 'string' ? b[sortField].toUpperCase() : b[sortField];
                if (propA < propB) {
                    return sortOrder === 'asc' ? -1 : 1;
                }
                if (propA > propB) {
                    return sortOrder === 'asc' ? 1 : -1;
                }
                return 0;
            });

            setProductsList(p);
        };

        if (products) {
            sortProducts();
        }
    }, [products, sortField, sortOrder]);

    return (
        <>
            <motion.div
                className="space-y-10"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                {products && productsList.length === 0 && <p>No products</p>}
                <header className="product-display-options">
                    <div className="flex w-1/2 sorting-options">
                        <div className="">
                            <label
                                className="text-sm font-bold text-gray-light"
                                htmlFor="sortField">
                                Sort by
                            </label>
                            <select
                                className="text-sm font-bold text-gray-light"
                                name="sortField"
                                id="sortField"
                                value={sortField}
                                onChange={(ev) => {
                                    setSortField(ev.target.value);
                                }}>
                                {sortFields.map((field, index) => (
                                    <option key={`sort-field-${index}`} value={field.value}>
                                        {field.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label
                                className="text-sm font-bold text-gray-light"
                                htmlFor="sortOrder">
                                Order
                            </label>
                            <select
                                className="text-sm font-bold text-gray-light"
                                name="sortOrder"
                                id="sortOrder"
                                value={sortOrder}
                                onChange={(ev) => {
                                    setSortOrder(ev.target.value);
                                }}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </div>
                    </div>
                    <div className="w-1/2 text-right">
                        <button
                            className="btn btn-primary text-sm py-4 px-6"
                            onClick={() => {
                                setActiveProduct(null);
                                setIsEditing(true);
                            }}>
                            Add Product
                        </button>
                    </div>
                </header>
                {products && (
                    <ul className="products-list mb-6">
                        {productsList.map((product) => (
                            <li key={product.nikoId} className="product-preview">
                                <button
                                    className="product-preview-btn"
                                    onClick={() => {
                                        setActiveProduct(product);
                                        setIsEditing(true);
                                    }}>
                                    <div
                                        className="product-preview-img"
                                        style={{
                                            backgroundImage: `url(${getProductImage(product)})`,
                                        }}></div>
                                    <div className="product-preview-details">
                                        <p className="text-sm font-bold text-left">
                                            {product.name}
                                        </p>
                                        <p className="text-sm font-bold text-gray-light product-price">
                                            ${getProductPrice(product)}
                                        </p>
                                    </div>
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </motion.div>
            <ProductEditForm
                allProducts={products}
                variantTypes={variantTypes}
                product={activeProduct}
                active={isEditing}
                closeHandler={() => {
                    setIsEditing(false);
                }}
            />
        </>
    );
};

export default Products;
