import Select from 'react-select';
import { adminSelectCustomStyles } from '../../../utils/constants';
import { useGetPayersQuery } from '../../../app/services/insurance';
import {
    useAddCredentialPayerMutation,
    useRemoveCredentialPayerMutation,
} from '../../../app/services/admin/insurance';
import { useState } from 'react';

const PayerCredentials = ({ adminUser }) => {
    //Local State
    const [payerId, setPayerId] = useState(null);

    //Queries
    const { data: payers, isLoading: payersLoading } = useGetPayersQuery();
    const [addCredentialPayer] = useAddCredentialPayerMutation();
    const [removeCredentialPayer] = useRemoveCredentialPayerMutation();

    return (
        <div className="rounded-xl bg-gray-darker p-5">
            <p className="mb-3 text-xl font-bold">Payer Credentials</p>

            {payersLoading ? (
                <></>
            ) : (
                <>
                    <div className="relative flex items-center space-x-4">
                        <Select
                            name="payerId"
                            placeholder="Select Payer"
                            options={payers
                                .filter((payer) => payer.payerType === 'INSURANCE')
                                .map((payer) => ({
                                    label: payer.name,
                                    value: payer.id,
                                }))}
                            styles={adminSelectCustomStyles}
                            className="w-1/2"
                            onChange={(newValue) => {
                                setPayerId(newValue);
                            }}
                        />
                        <button
                            disabled={!payerId}
                            className="btn-primary-small"
                            onClick={async (ev) => {
                                await addCredentialPayer({
                                    userId: adminUser.id,
                                    payerId: payerId,
                                });
                            }}>
                            Add Payer
                        </button>
                    </div>
                    <table className="gem-payers table-fixed">
                        <thead>
                            <tr className="border-b">
                                <th className="w-1/6 p-2 text-left">
                                    <h4>Name</h4>
                                </th>
                                <th className="w-1/6 p-2 text-left">
                                    <h4>Actions</h4>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {adminUser.providerCredentials.map((credential) => (
                                <tr key={credential.id} className="border-b">
                                    <td className="p-2 text-left">{credential.payer.name}</td>
                                    <td className="p-2 text-left">
                                        <button
                                            onClick={async (ev) => {
                                                await removeCredentialPayer({
                                                    userId: adminUser.id,
                                                    payerId: credential.payer.id,
                                                });
                                            }}
                                            className="btn-primary-small">
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};

export default PayerCredentials;
