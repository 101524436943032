import { motion } from 'framer-motion';
import Lottie from 'lottie-react';
import { overlayVariants } from '../../utils/constants';
import insuranceSpinnerAnimation from '../../utils/insurance-spinner-animation.json';

const Spinner = ({ header, message }) => {
    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={overlayVariants}
            className="absolute top-0 left-0 z-20 flex h-full w-full flex-col items-center justify-center bg-gray-neutral">
            <h3 className="text-center font-heading text-4xl lg:text-6xl">
                Checking <br />
                <span className="text-gem-green">{header}</span>
            </h3>
            <Lottie
                animationData={insuranceSpinnerAnimation}
                loop={true}
                className="mx-auto max-w-md"
            />
            <p className="text-sm font-bold">{message}</p>
        </motion.div>
    );
};

export default Spinner;
