import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDeleteAdminQueryMutation } from '../../../app/services/admin/search';
import Modal from '../../Modal/Modal';

const DeleteQuery = ({ query }) => {
    const { user } = useSelector((state) => state.auth);

    //Used for modal for editting or deleting queries
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteAdminQuery] = useDeleteAdminQueryMutation();

    const queryDeleteSubmitHandler = async () => {
        await deleteAdminQuery(query.id);
        setModalOpen(false);
    };

    return (
        <>
            <button
                //Disabled for queries which the user did not create
                disabled={query?.userId !== user?.id}
                onClick={() => {
                    setModalOpen(true);
                }}
                className="btn-secondary-small ml-4 basis-1/3 text-base lg:text-lg">
                Delete
            </button>
            <Modal
                showClose={true}
                isOpen={modalOpen}
                closeHandler={() => {
                    setModalOpen(false);
                }}>
                <>
                    <span className="font-heading text-xl block p-2">
                        Are you sure you want to delete this query?
                    </span>
                    <span className="font-heading font-bold text-3xl block p-2 text-center bg-gray text-white w-3/4 m-auto">
                        {query?.label}
                    </span>
                </>
                <div className="relative flex w-full sm:w-1/2 items-center text-white mx-2 mt-4">
                    <form className="w-full" onSubmit={queryDeleteSubmitHandler}>
                        <button
                            type="submit"
                            className="btn-primary-small w-full text-base lg:text-lg">
                            Yes
                        </button>
                    </form>
                    <button
                        onClick={() => setModalOpen(false)}
                        className="btn-secondary-small w-full text-base lg:text-lg mx-2">
                        No
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default DeleteQuery;
