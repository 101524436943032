import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { addDays, format } from 'date-fns';
import TimezoneSelect from 'react-timezone-select';
import { formatInTimeZone } from 'date-fns-tz';

import {
    AVAILABLE_SLOTS_FOR_RANGE,
    DAYS_AVAILABLE_FOR_RANGE,
} from '../../Appointments/AppointmentBooking/gqlQueries';
import { adminSelectCustomStyles, states } from '../../../utils/constants';
import { useGetAppointmentTypesQuery } from '../../../app/services/appointments';
import { useGetAvailablePractitionersAdminQuery } from '../../../app/services/admin/appointments';
import { useGetPayersQuery } from '../../../app/services/insurance';
import { useLazyQuery } from '@apollo/client';
import { useGetAdminUsersQuery } from '../../../app/services/admin/admin';
import { useSearchParams } from 'react-router-dom';

const initialFilters = {
    providerId: null,
    payerId: null,
    state: null,
    appointmentType: null,
    month: new Date(),
};

const timeZones = {
    'America/Boise': 'Mountain Time',
    'America/Chicago': 'Central Time',
    'America/Detroit': 'Eastern Time',
    'America/Juneau': 'Alaska',
    'America/Los_Angeles': 'Pacific Time',
    'America/Phoenix': 'Arizona',
    GMT: 'UTC',
    'Pacific/Guam': 'Guam, Port Moresby',
    'Pacific/Honolulu': 'Hawaii',
    'Pacific/Midway': 'Midway Island, Samoa',
};

const defaultTimeZone = Object.keys(timeZones).includes(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
)
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : 'America/Chicago';

const Availability = () => {
    //Local state values
    const [filters, setFilters] = useState({ ...initialFilters });
    const [dayChosen, setDayChosen] = useState(null);
    const [idChosen, setIdChosen] = useState(null);
    const [selectedTimezone, setSelectedTimezone] = useState(defaultTimeZone);
    const [resetSearch, setResetSearch] = useState(true);

    //Queries
    const { data: payers, isLoading: payersLoading } = useGetPayersQuery();
    const { data: appointmentTypes } = useGetAppointmentTypesQuery();
    const providerUserQuery = [{ userRoles: { some: { role: 'MD' } } }];
    const { data: providers } = useGetAdminUsersQuery(providerUserQuery);
    const { data: practitioners, isLoading: practitionersLoading } =
        useGetAvailablePractitionersAdminQuery(
            {
                payerId: filters.payerId,
                state: filters.state,
            },
            {
                refetchOnMountOrArgChange: true,
            },
        );

    const payerOptions = payers
        ?.filter((payer) => payer.enabled)
        .map((payer) => ({ value: payer.id, label: payer.name }));
    const appointmentTypeOptions = appointmentTypes?.map((apptType) => ({
        value: apptType.id,
        label: apptType.name,
    }));
    const providersWithHealthieIds = providers?.results?.filter(
        (provider) => provider.healthieProviderId,
    );

    //Create a map of healthieIds to provider names
    const providerNamesById = new Map();
    providersWithHealthieIds?.forEach(
        (provider) =>
            provider.healthieProviderId &&
            providerNamesById.set(
                provider.healthieProviderId,
                provider.firstName + ' ' + provider.lastName,
            ),
    );

    const providerOptions = providersWithHealthieIds?.map((provider) => ({
        value: provider.healthieProviderId,
        label: providerNamesById.get(provider.healthieProviderId),
    }));

    //GraphQL Queries
    const [loadDayAvailability, { data: availableDaysData, loading: daysLoading }] =
        useLazyQuery(DAYS_AVAILABLE_FOR_RANGE);

    const [loadSlotAvailability, { data: availableSlotsData, loading: slotsLoading }] =
        useLazyQuery(AVAILABLE_SLOTS_FOR_RANGE);

    //Reset the search
    function reset() {
        setFilters(initialFilters);
        setSelectedTimezone(defaultTimeZone);
        setDayChosen(null);
        setIdChosen(null);
        setResetSearch(true);
    }

    //Search for the filtered Provider, otherwise those returned in the practitioner query
    const providersToSearch = {
        provider_id: filters.providerId
            ? filters.providerId
            : practitioners?.length > 0
              ? practitioners[0]
              : null,
        provider_ids: filters.providerId
            ? [filters.providerId]
            : practitioners
              ? practitioners
              : null,
    };

    //Determine if search params were set navigating from map, if so, set filters and remove search params
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if (searchParams.size > 0) {
            setFilters({
                providerId: null,
                payerId: parseInt(searchParams.get('payerId')),
                state: searchParams.get('state'),
                appointmentType:
                    searchParams.get('appointmentTypeId') !== 'null'
                        ? searchParams.get('appointmentTypeId')
                        : null,
                month: new Date(),
            });
        }
        setSearchParams({});
    }, [searchParams, setSearchParams]);

    if (payersLoading) {
        return <p>Loading...</p>;
    } else {
        return (
            <div className="flex flex-col xl:flex-row space-y-4 xl:space-y-0 xl:space-x-8">
                <div className="space-y-4 min-h-[50vh]">
                    <div className="space-y-2 rounded-xl bg-gray-darker space-y-2 p-4 text-center sm:text-left">
                        <div className="flex flex-col sm:flex-row space-x-2 items-center">
                            <p className="w-[80px]">Provider:</p>
                            <Select
                                options={providerOptions}
                                className="w-[250px]"
                                onChange={(selectedOption) =>
                                    setFilters({ ...filters, providerId: selectedOption.value })
                                }
                                placeholder={'Select Payer'}
                                styles={adminSelectCustomStyles}
                                value={
                                    providerOptions?.find(
                                        (provider) => provider.value === filters.providerId,
                                    ) ?? null
                                }
                            />
                        </div>
                        <div className="flex flex-col sm:flex-row space-x-2 items-center">
                            <p className="w-[80px]">Payer:</p>
                            <Select
                                options={payerOptions}
                                className="w-[250px]"
                                onChange={(selectedOption) =>
                                    setFilters({ ...filters, payerId: selectedOption.value })
                                }
                                placeholder={'Select Payer'}
                                styles={adminSelectCustomStyles}
                                value={
                                    payerOptions?.find(
                                        (payer) => payer.value === filters.payerId,
                                    ) ?? null
                                }
                            />
                        </div>
                        <div className="flex flex-col sm:flex-row space-x-2 items-center">
                            <p className="w-[80px]">State:</p>
                            <Select
                                options={states.map((state) => ({
                                    value: state.value,
                                    label: state.label,
                                }))}
                                className="w-[250px]"
                                onChange={(selectedOption) =>
                                    setFilters({ ...filters, state: selectedOption.value })
                                }
                                placeholder={'Select State'}
                                styles={adminSelectCustomStyles}
                                value={
                                    states.find((state) => state.value === filters.state) ?? null
                                }
                            />
                        </div>
                        <div className="flex flex-col sm:flex-row space-x-2 items-center">
                            <p className="w-[80px]">Type:</p>
                            <Select
                                options={appointmentTypeOptions}
                                className="w-[250px]"
                                onChange={(selectedOption) =>
                                    setFilters({
                                        ...filters,
                                        appointmentType: selectedOption.value,
                                    })
                                }
                                placeholder={'Select Appt Type'}
                                styles={adminSelectCustomStyles}
                                value={
                                    appointmentTypeOptions?.find(
                                        (apptType) => apptType.value === filters.appointmentType,
                                    ) ?? null
                                }
                            />
                        </div>
                        <div className="flex flex-col sm:flex-row space-x-2 items-center">
                            <p className="w-[80px]">Month:</p>
                            <DatePicker
                                showMonthYearPicker
                                dateFormat="MM/yyyy"
                                className="appearance-none border border-gray rounded bg-transparent p-2 text-sm text-white w-[250px]"
                                selected={filters.month}
                                onChange={(date) => {
                                    setFilters({ ...filters, month: date });
                                }}
                            />
                        </div>
                        <div className="flex flex-col sm:flex-row space-x-2 items-center">
                            <p className="w-[80px]">Timezone:</p>
                            <TimezoneSelect
                                value={selectedTimezone}
                                onChange={setSelectedTimezone}
                                styles={adminSelectCustomStyles}
                                timezones={timeZones}
                                className="items-center w-[250px]"
                            />
                        </div>
                        <div className="flex space-x-2 items-center justify-center sm:justify-start py-4">
                            <button
                                className="btn-primary-small w-[80px]"
                                disabled={practitionersLoading || practitioners?.length === 0}
                                onClick={() => {
                                    setResetSearch(false);
                                    setDayChosen(null);
                                    loadDayAvailability({
                                        variables: {
                                            org_level: true,
                                            timezone: selectedTimezone.value,
                                            appt_type_id: filters.appointmentType,
                                            ...providersToSearch,
                                            date_from_month: new Date(filters.month),
                                        },
                                    });
                                }}>
                                Search
                            </button>
                            <button
                                className="btn-secondary-small w-[80px]"
                                onClick={() => {
                                    reset();
                                }}>
                                Reset
                            </button>
                        </div>
                    </div>

                    <div className="rounded-xl bg-gray-darker space-y-2 p-4">
                        <p className="font-bold text-gem-green">Providers</p>
                        {filters.providerId ? (
                            <p
                                className={`border rounded w-full bg-white sm:w-[335px] px-2 text-gray`}>
                                {filters.providerId + ' '}
                                <span className="text-black">
                                    {providerNamesById.get(filters.providerId) ?? 'Unknown'}
                                </span>
                            </p>
                        ) : practitioners?.length > 0 ? (
                            practitioners.map((id) => (
                                <p
                                    key={'provider-list-' + id}
                                    className={`border rounded w-full sm:w-[335px] px-2 ${id === idChosen ? 'bg-green-200' : 'bg-white'} text-gray hover:bg-green-200 hover:cursor-pointer`}
                                    onClick={() => {
                                        setIdChosen(id);
                                    }}>
                                    {id + ' '}
                                    <span className="text-black">
                                        {providerNamesById.get(id) ?? 'Unknown'}
                                    </span>
                                </p>
                            ))
                        ) : (
                            <p>No practitioners match this search</p>
                        )}
                        {idChosen !== null && (
                            <button
                                className="btn-secondary-small"
                                onClick={() => setIdChosen(null)}>
                                Clear Selection
                            </button>
                        )}
                    </div>
                </div>
                {availableDaysData && !resetSearch && (
                    <div className="flex flex-col sm:flex-row rounded-xl bg-gray-darker p-5 space-y-2 sm:space-y-0 sm:space-x-8">
                        <div className="space-y-2">
                            <p className="font-bold text-gem-green">Days Available</p>
                            {daysLoading ? (
                                <p>Loading...</p>
                            ) : (
                                <>
                                    {availableDaysData?.daysAvailableForRange.map((day) => (
                                        <p
                                            key={'available-day-' + day}
                                            className={`border rounded w-[120px] px-2 ${day === dayChosen ? 'bg-green-200' : 'bg-white'} text-black hover:bg-green-200 hover:cursor-pointer`}
                                            onClick={() => {
                                                setDayChosen(day);
                                                loadSlotAvailability({
                                                    variables: {
                                                        org_level: true,
                                                        timezone:
                                                            Intl.DateTimeFormat().resolvedOptions()
                                                                .timeZone || 'America/New_York',
                                                        appt_type_id: filters.appointmentType,
                                                        ...providersToSearch,
                                                        start_date: day.toString(),
                                                        end_date: addDays(day, 1).toString(),
                                                    },
                                                });
                                            }}>
                                            {format(day, 'MM/dd/yyyy')}
                                        </p>
                                    ))}
                                </>
                            )}
                        </div>
                        <div>
                            <p className="font-bold text-gem-green">Times Available</p>
                            {!dayChosen ? null : slotsLoading ? (
                                <p>Loading...</p>
                            ) : (
                                <div
                                    className={`grid grid-cols-${Math.ceil(availableSlotsData?.availableSlotsForRange.length / 16)} gap-2 pt-2`}>
                                    {availableSlotsData?.availableSlotsForRange
                                        .filter(
                                            (slot) =>
                                                !new Date(slot.date)
                                                    .getMinutes()
                                                    .toString()
                                                    .endsWith('5'),
                                        )
                                        .map((slot) => (
                                            <div
                                                key={'available-slot-' + slot.date}
                                                className={`daisy-tooltip daisy-tooltip-right daisy-tooltip-success text-left flex items center`}
                                                data-tip={
                                                    providerNamesById.get(slot.user_id) ?? 'Unknown'
                                                }>
                                                <p
                                                    className={`border rounded w-[120px] px-2 text-black ${idChosen === slot.user_id ? 'bg-gem-purple' : 'bg-white'} hover:bg-green-200 hover:cursor-pointer`}>
                                                    {formatInTimeZone(
                                                        new Date(slot.date),
                                                        typeof selectedTimezone === 'object' &&
                                                            selectedTimezone.value
                                                            ? selectedTimezone.value
                                                            : selectedTimezone,
                                                        'h:mm a',
                                                    )}
                                                </p>
                                            </div>
                                        ))}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
};

export default Availability;
