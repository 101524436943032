import { useField } from 'formik';

const BigInput = ({ className, ...props }) => {
	const [field, meta] = useField(props);

	return (
		<div className={`relative ${className}`}>
			<input
				className="bg-transparent w-full border-b border-b-purple-medium placeholder:text-offwhite/30 outline-none text-3xl lg:text-6xl"
				{...field}
				{...props}
			/>
			{meta.touched && meta.error && (
				<p className="text-xs text-red font-bold mt-4 absolute -bottom-3 translate-y-full">{meta.error}</p>
			)}
		</div>
	);
};

export default BigInput;
