import { overlayVariants } from '../../../utils/constants';
import { motion } from 'framer-motion';
import { useCheckoutContext } from '../../Checkout/CheckoutContext';
import { useInsuranceContext } from '../InsuranceContext';

const CurrentInsuranceInfo = ({ checkIfElibigilityCheckRequired }) => {
    const { setCheckoutStep, skipInsurance } = useCheckoutContext();
    const { insurance } = useInsuranceContext();

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={overlayVariants}
            className="space-y-5">
            <h5 className="font-heading text-4xl font-bold lg:text-5xl">Use This Insurance?</h5>
            <p>Here is the insurance we have saved for you. Is it still current?</p>

            <div className="space-y-6">
                <p className="text-right">
                    <button
                        className="font-bold text-gem-green"
                        onClick={() => {
                            setCheckoutStep('insurance-details');
                        }}>
                        Add NEW Insurance
                    </button>
                </p>
                <div className="space-y-6 border-8 border-purple-medium border-opacity-20 p-8 lg:p-14">
                    <p>
                        <span className="text-xs text-gray-six lg:text-sm">Insurance Name</span>
                        <span className="block w-full appearance-none border-b border-gray-darker bg-transparent pb-4 font-semibold text-black  transition duration-200 ease-out-quad lg:text-lg">
                            {insurance?.payer?.name}
                        </span>
                    </p>
                    {insurance?.payer?.payerType === 'OTHER' && (
                        <p>
                            <span className="text-xs text-gray-six lg:text-sm">
                                Other Insurance Name
                            </span>
                            <span className="block w-full appearance-none border-b border-gray-darker bg-transparent pb-4 font-semibold text-black  transition duration-200 ease-out-quad lg:text-lg">
                                {insurance?.otherPayerName}
                            </span>
                        </p>
                    )}

                    {insurance?.payer?.payerType !== 'OTHER' && (
                        <>
                            <p>
                                <span className="text-xs text-gray-six lg:text-sm">
                                    Member ID/Policy Number
                                </span>
                                <span className="block w-full appearance-none border-b border-gray-darker bg-transparent pb-4 font-semibold text-black  transition duration-200 ease-out-quad lg:text-lg">
                                    {insurance?.memberId}
                                </span>
                            </p>
                            {insurance?.payer?.requiresGroupId && ( 
                            <p>
                                <span className="text-xs text-gray-six lg:text-sm">
                                    Group ID/Group Number
                                </span>
                                <span className="block w-full appearance-none border-b border-gray-darker bg-transparent pb-4 font-semibold text-black  transition duration-200 ease-out-quad lg:text-lg">
                                    {insurance?.groupId}
                                </span>
                            </p>
                             )}
                        </>
                    )}
                </div>
                <button
                    className="btn-primary w-full"
                    onClick={() => {
                        if (checkIfElibigilityCheckRequired(insurance)) {
                            setCheckoutStep('checking-eligibility');
                        }
                    }}>
                    Continue
                </button>
            </div>

            <p className="text-center">
                <button
                    className="btn-shell"
                    onClick={() => {
                        skipInsurance({ showAlert: true });
                    }}
                    type="button">
                    Continue without insurance
                </button>
            </p>
        </motion.div>
    );
};

export default CurrentInsuranceInfo;
