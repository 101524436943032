import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ReactComponent as ReadIcon } from '../../images/icons/read.svg';
import { ReactComponent as PlayIcon } from '../../images/icons/play.svg';
import { ReactComponent as Logo } from '../../images/logo.svg';

const wrapperClasses =
    'flex h-full flex-col bg-white transition duration-200 ease-out-quad hover:shadow-xl';

const VariableLink = ({ to, children }) => {
    return to.indexOf('http') === 0 ? (
        <a href={to} target="_blank" rel="noreferrer" className={wrapperClasses}>
            {children}
        </a>
    ) : (
        <Link to={to} className={wrapperClasses}>
            {children}
        </Link>
    );
};

const SupportCard = ({
    variants,
    type = 'article',
    title,
    to,
    imageUrl,
    className,
    data: blogPost,
}) => {
    const content = (
        <>
            <div className="aspect-w-8 aspect-h-5 bg-gray-six">
                {(imageUrl || blogPost['main-image']?.url) ? <img
                    src={imageUrl ?? blogPost['main-image']?.url}
                    alt=""
                    className="object-cover object-center"
                /> : <Logo className="object-cover object-center" />}
            </div>
            <div className="flex-grow p-5">
                <p className="mb-2 flex items-center space-x-2">
                    {type === 'video' ? <PlayIcon /> : <ReadIcon />}

                    <span className="font-heading text-sm tracking-widest">
                        {type === 'video' ? 'watch' : 'read'}
                    </span>
                </p>
                <h3 className="text-[15px] font-bold leading-tight lg:text-lg">
                    {title ?? blogPost.name}
                </h3>
            </div>
        </>
    );

    return (
        <motion.div
            variants={variants}
            transition={{ ease: [0.5, 1, 0.89, 1] }}
            className={`h-full ${className}`}>
            <VariableLink to={to ?? `blog/${blogPost?.slug}`}>{content}</VariableLink>
        </motion.div>
    );
};

export default SupportCard;
