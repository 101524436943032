import { AnimatePresence, motion } from 'framer-motion';
import { StrictMode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useCreateSquarePaymentMutation } from '../../app/services/orders';
import { useGetCartQuery } from '../../app/services/cart';
import { setConsultRequired } from '../../features/cart/cartSlice';
import { confirmCurrentAppointment } from '../../features/ui/uiSlice';

import {
    ConsultBooking,
    RequiredConsultBooking,
    OrderConfirmation,
    Address,
    Payment,
    ItemizedCart,
    ResupplySignup,
} from '../../components/Checkout';
import { Spinner } from '../../components';
import CoveredBenefit from '../../components/InsuranceAlternative/CoveredBenefit';
import CoveredBenefitCheck from '../../components/InsuranceAlternative/CoveredBenefitCheck';
import { useCheckoutContext } from '../../components/Checkout/CheckoutContext';
import { InsuranceCheck, useInsuranceContext } from '../../components/Insurance';
import posthog from 'posthog-js';

const Checkout = () => {
    const dispatch = useDispatch();

    //Redux State Values
    const {
        consultRequired,
        cpapInCart,
        hstInCart,
        isConsultOrder,
        orderItems,
        subtotal,
        stateRequiredTelehealth,
        usingInsurance,
    } = useSelector((state) => state.cart);
    const { user } = useSelector((state) => state.auth);

    //Local State
    const [ready, setReady] = useState(false);
    //Track item ordered for success page since cart clears, 'hst', 'cpap', 'consult', 'other'
    const [itemOrdered, setItemOrdered] = useState(null);
    const [showAddressForm, setShowAddressForm] = useState(
        user && (!user?.street1 || !user?.city || !user?.state || !user?.zip),
    );

    //Queries
    const [createSquarePayment, { error: createPaymentError, isLoading: paymentProcessing }] =
        useCreateSquarePaymentMutation();
    const { refetch: refetchCart } = useGetCartQuery();

    //Context
    const { checkoutStep, setCheckoutStep } = useCheckoutContext();
    const { hasInsuranceAlternative, insurance, insuranceFetching } = useInsuranceContext();

    //Used to determine which checkout steps should show the itemized cart
    const stepsToShowItemizedCart = ['payment-information', 'consult-booking', 'address-page'];

    const insuranceCheckSteps = [
        'check-coverage',
        'checking-eligibility',
        'insurance-review',
        'insurance-details',
        'insurance-card-question',
        'insurance-card',
        'not-found',
        'not-eligible',
        'is-eligible',
    ];

    useEffect(() => {
        if (!insuranceFetching) setReady(true);
    }, [insuranceFetching]);

    useEffect(() => {
        if ((stateRequiredTelehealth || insurance?.payer?.consultRequiredForHST) && hstInCart)
            dispatch(setConsultRequired(true));
    }, [stateRequiredTelehealth, hstInCart, insurance, dispatch]);

    useEffect(() => {
        window.scrollTo(0, 0);
        posthog?.capture('Checkout Step Changed', { checkoutStep: checkoutStep });
    }, [checkoutStep]);

    //If the user has an insurance alternative, direct them to the appropriate page when they land on navigating to checkout
    useEffect(() => {
        if (!checkoutStep && user && !insuranceFetching && hstInCart !== null) {
            if (hasInsuranceAlternative) {
                if (hstInCart) setCheckoutStep('insurance-alternative');
                else setCheckoutStep('insurance-alternative-check');
            } else setCheckoutStep('check-coverage');
        }
    }, [
        user,
        checkoutStep,
        insuranceFetching,
        hstInCart,
        setCheckoutStep,
        hasInsuranceAlternative,
    ]);

    const paymentSubmitHandler = async (paymentToken) => {
        //Set spinner during payment submission
        setCheckoutStep('submitting-payment');

        //Make sure if it is a consult order or a consult is required that the cart still has an appointment attached
        if (consultRequired || isConsultOrder) {
            const currentCart = await refetchCart();
            if (!currentCart.data.appointments?.length > 0) {
                //If there is no appointment, toast the error and set the checkout step back to booking
                toast.error('Your appointment is no longer reserved. Please book again.');
                if (consultRequired) {
                    setCheckoutStep('required-consult-booking');
                } else if (isConsultOrder) {
                    setCheckoutStep('consult-booking');
                }
                //Return out so that the following code does not run
                return;
            }
        }

        //Set local state to know what to display on confirmation
        setItemOrdered(
            hstInCart ? 'hst' : cpapInCart ? 'cpap' : isConsultOrder ? 'consult' : 'other',
        );

        const paymentResult = await createSquarePayment({
            orderType: usingInsurance ? 'INSURANCE' : 'CASH',
            sourceId: paymentToken,
        });

        if (paymentResult?.data?.paymentId) {
            paymentSuccess();
        } else {
            toast.error('There was an error with your payment');
            setCheckoutStep('payment-information');
        }
    };

    const handleZeroPayment = () => paymentSubmitHandler('CASH');

    function proceedToPayment() {
        subtotal > 0 ? setCheckoutStep('payment-information') : handleZeroPayment();
    }

    const paymentSuccess = async () => {
        setCheckoutStep('checkout-success');
        dispatch(confirmCurrentAppointment());
    };

    const onUseInsurance = () => {
        if (consultRequired) {
            setCheckoutStep('required-consult-booking');
        } else if (isConsultOrder) {
            setCheckoutStep('consult-booking');
        } else {
            setCheckoutStep('address-page');
        }
    };

    if (orderItems?.length === 0 && checkoutStep !== 'checkout-success') {
        return (
            <div className="layout-outlet-root text-gray-darker">
                <h2 className="font-heading text-[44px] lg:text-7xl">No products in cart.</h2>
            </div>
        );
    }

    return (
        ready && (
            <StrictMode>
                <div className="text-gray-darker xl:flex xl:space-x-10 xl:divide-x xl:divide-gray/25">
                    <div
                        className={`${
                            checkoutStep !== 'required-consult-booking' &&
                            'px-5 lg:px-[60px] mb-3 max-w-3xl'
                        } lg:flex-1 ${checkoutStep === 'checkout-success' ? 'mx-auto' : ''}`}>
                        {createPaymentError && (
                            <p className="font-bold text-red">
                                The payment method provided was declined. Please try your credit
                                card again or provide a different one. For additional assistance
                                please contact GEM SLEEP.
                            </p>
                        )}
                        <AnimatePresence>
                            {!checkoutStep && null}
                            {checkoutStep === 'insurance-alternative' && <CoveredBenefit />}
                            {checkoutStep === 'insurance-alternative-check' && (
                                <CoveredBenefitCheck />
                            )}
                            {insuranceCheckSteps.includes(checkoutStep) && (
                                <InsuranceCheck onInsuranceStepsComplete={onUseInsurance} />
                            )}

                            {checkoutStep === 'consult-booking' && (
                                <ConsultBooking
                                    onBookingComplete={() => {
                                        subtotal > 0
                                            ? setCheckoutStep('payment-information')
                                            : handleZeroPayment();
                                    }}
                                />
                            )}

                            {checkoutStep === 'required-consult-booking' && (
                                <RequiredConsultBooking
                                    requiredByState={stateRequiredTelehealth}
                                    onBookingComplete={() => {
                                        setCheckoutStep('address-page');
                                    }}
                                />
                            )}
                            {checkoutStep === 'address-page' && (
                                <Address
                                    proceedToPayment={proceedToPayment}
                                    setShowAddressForm={setShowAddressForm}
                                    showAddressForm={showAddressForm}
                                    setCheckoutStep={setCheckoutStep}
                                    user={user}
                                />
                            )}

                            {checkoutStep === 'resupply-signup' && (
                                <ResupplySignup proceedToPayment={proceedToPayment} />
                            )}

                            {checkoutStep === 'payment-information' && (
                                <Payment
                                    handleZeroPayment={handleZeroPayment}
                                    paymentSubmitHandler={paymentSubmitHandler}
                                    user={user}
                                    paymentProcessing={paymentProcessing}
                                />
                            )}

                            {checkoutStep === 'submitting-payment' && (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className="mx-auto max-w-3xl pt-16 text-center">
                                    <AnimatePresence>
                                        <Spinner
                                            key="submitting payment"
                                            header={'your order'}
                                            message="This will just take a second"
                                        />
                                    </AnimatePresence>
                                </motion.div>
                            )}

                            {checkoutStep === 'checkout-success' && (
                                <OrderConfirmation
                                    withRequiredConsult={consultRequired}
                                    itemOrdered={itemOrdered}
                                />
                            )}
                        </AnimatePresence>
                    </div>
                    {stepsToShowItemizedCart.includes(checkoutStep) && <ItemizedCart />}
                </div>
            </StrictMode>
        )
    );
};

export default Checkout;
