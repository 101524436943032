import { useState } from 'react';
// import { useSelector } from 'react-redux';
import {
    useCreateTreatmentMutation,
    useDeleteTreatmentMutation,
    useGetTreatmentsQuery,
    useUpdateTreatmentMutation,
} from '../../../../app/services/admin/treatment';
import { useGetPartnerOrganizationsQuery } from '../../../../app/services/insurance';

// import { adminItemsSelector } from '../../../../features/catalog/catalogSlice';
// import { SelectItemFromCatalog } from '../../Orders';

import { FaRegEdit } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';
import { initialComplianceOptions } from '../../../../utils/constants';
import { toast } from 'react-toastify';

const TreatmentTypes = ['CPAP', 'DENTAL'];

const cpapDevices = {
    ResMed: ['AirSense 11'],
    'React Health': ['Luna G3'],
    ResVent: ['iBreeze-20A'],
};

const formatTreatmentDate = (d) => {
    const dateString = d.toISOString().substring(0, 10);
    const dateParts = dateString.split('-');
    return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
};

// function determineMaskBySku(sku, catalogItems) {
//     if (!sku) {
//         return { maskName: 'None', maskSize: '' };
//     }

//     const itemBySku = catalogItems?.find((o) =>
//         o?.itemData?.variations?.find(
//             (v) => v.itemVariationData?.sku.toLowerCase() === sku.toLowerCase(),
//         ),
//     );

//     if (!itemBySku) {
//         return { maskName: 'Not in Catalog', maskSize: '' };
//     }

//     const size = itemBySku.itemData.variations.find(
//         (variation) => variation.itemVariationData.sku === sku,
//     ).itemVariationData.name;

//     return { maskName: itemBySku.itemData.name, maskSize: size };
// }

const CurrentTreatments = ({ patient }) => {
    const { data: treatments, isFetching } = useGetTreatmentsQuery(patient.id, {
        skip: !patient.id,
    });
    const [createTreatment, { error: createTreatmentError }] = useCreateTreatmentMutation();
    const [updateTreatment, { error: updateTreatmentError }] = useUpdateTreatmentMutation();
    const [deleteTreatment] = useDeleteTreatmentMutation();
    const { data: treatmentProviders } = useGetPartnerOrganizationsQuery({ paginated: false });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [addFormActive, setAddFormActive] = useState(false);

    const defaultTreatmentFields = {
        treatmentType: 'CPAP',
        treatmentProviderId: null,
        startDate: null,
        endDate: null,
        isActive: true,
    };

    const addTreatmentHandler = async (treatmentFields) => {
        if (!isSubmitting) {
            setIsSubmitting(true);

            const response = await createTreatment({
                userId: patient.id,
                treatmentFields: treatmentFields,
            });

            setIsSubmitting(false);
            setAddFormActive(false);
            return response;
        }
    };

    const updateTreatmentHandler = async (treatmentFields) => {
        if (!isSubmitting) {
            setIsSubmitting(true);
            const { id, ...rest } = treatmentFields;
            const response = await updateTreatment({
                userId: patient.id,
                treatmentId: id,
                treatmentFields: rest,
            });
            setIsSubmitting(false);
            return response;
        }
    };

    const deleteTreatmentHandler = async (treatmentId) => {
        if (!isSubmitting) {
            setIsSubmitting(true);
            await deleteTreatment({ userId: patient.id, treatmentId });
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            {(treatments && treatments.length > 0) || addFormActive ? (
                <ul className="lg:grid xl:grid-cols-2 lg:gap-x-2">
                    {treatments.map((treatment) => (
                        <li key={treatment.id}>
                            <TreatmentLineItem
                                treatment={treatment}
                                addOrUpdateTreatmentHandler={updateTreatmentHandler}
                                deleteOrCancelTreatmentHandler={deleteTreatmentHandler}
                                treatmentProviders={treatmentProviders}
                            />
                        </li>
                    ))}
                    {addFormActive && (
                        <li key="new">
                            <TreatmentLineItem
                                treatment={defaultTreatmentFields}
                                addOrUpdateTreatmentHandler={addTreatmentHandler}
                                deleteOrCancelTreatmentHandler={() => setAddFormActive(false)}
                                treatmentProviders={treatmentProviders}
                                setAddFormActive={setAddFormActive}
                            />
                        </li>
                    )}
                </ul>
            ) : (
                <div className="my-4">
                    <p>No current treatments found.</p>
                </div>
            )}

            {!addFormActive && !isFetching && (
                <button
                    className={`mt-4 btn-primary-small`}
                    value="Add Treatment"
                    onClick={() => {
                        setAddFormActive(true);
                    }}>{`Add Treatment`}</button>
            )}

            {isFetching && <div className="p-8">Loading...</div>}

            <div>
                {createTreatmentError && (
                    <p className="text-red-500">{`Error creating new treatment: ${createTreatmentError}`}</p>
                )}
                {updateTreatmentError && (
                    <p className="text-red-500">{`Error updating treatment: ${updateTreatmentError}`}</p>
                )}
            </div>
        </div>
    );
};

const TreatmentLineItem = ({
    treatment,
    addOrUpdateTreatmentHandler,
    deleteOrCancelTreatmentHandler,
    treatmentProviders,
    setAddFormActive,
}) => {
    const [isEditing, setIsEditing] = useState(treatment.id ? false : true);
    const initialValues = { ...treatment };
    const [values, setValues] = useState({ ...treatment });

    // const { catalogItems } = useSelector((state) => state.catalog);
    // const { maskName, maskSize } = determineMaskBySku(
    //     values.maskSku ? values.maskSku : treatment.maskSku,
    //     catalogItems,
    // );

    //Determine mask options to select
    // const itemOptions = useSelector(adminItemsSelector);
    // const maskOptions = itemOptions
    //     .filter((option) => option.label.toLowerCase().includes('mask'))
    //     .filter((option) => option.items.length > 0);

    const deleteTreatment = () => {
        // open the modal to confirm the deletion of the treatment
        if (!treatment.id) {
            // call cancel handler
            deleteOrCancelTreatmentHandler(undefined);
        } else {
            const confirmDelete = window.confirm('Are you sure you want to delete this treatment?');
            if (confirmDelete) {
                deleteOrCancelTreatmentHandler(treatment.id);
            }
        }
    };

    return (
        <form
            onSubmit={async (e) => {
                e.preventDefault();
                const response = await addOrUpdateTreatmentHandler(values);
                if (response.error) {
                    toast.error(response.error.data.message);
                } else {
                    setIsEditing(false);
                }
            }}
            className="relative px-4 py-4 border-b sm:border sm:rounded"
            style={{ borderColor: 'rgba(250,250,250,0.2)' }}>
            {!isEditing && (
                <div
                    className="absolute p-2 pr-4 flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-2 w-[50px] sm:w-[135px] xl:w-[250px]"
                    style={{ top: 0, right: 0 }}>
                    <button
                        className="btn-secondary-small bg-transparent w-[30px] sm:w-[120px] flex justify-center"
                        type="button"
                        onClick={() => setIsEditing(true)}
                        style={{
                            top: '8px',
                            right: '8px',
                            border: '1px solid rgba(255,255,255,0.1)',
                        }}>
                        <FaRegEdit className="block sm:hidden" />
                        <span className="hidden sm:block">Edit</span>
                    </button>
                    <button
                        className="btn-secondary-small w-[30px] sm:w-[120px] flex justify-center"
                        type="button"
                        onClick={() => deleteTreatment()}>
                        <MdDeleteOutline className="block sm:hidden" />
                        <span className="hidden sm:block">Delete</span>
                    </button>
                </div>
            )}

            {isEditing && (
                <div className="absolute p-2 flex space-x-2 pr-4" style={{ top: 0, right: 0 }}>
                    <button type="submit" className="btn-primary-small w-[70px] sm:w-[120px]">
                        Save
                    </button>
                    <button
                        className="btn-secondary-small w-[70px] sm:w-[120px]"
                        onClick={() => {
                            setIsEditing(false);
                            if (setAddFormActive) setAddFormActive(false);
                        }}>
                        Cancel
                    </button>
                </div>
            )}

            <div
                className={`grid ${isEditing ? 'sm:grid-cols-[170px_1fr]' : 'grid-cols-[170px_1fr]'}  gap-y-4 items-center`}>
                <>
                    <label className="text-gray mr-4 mt-2">Active: </label>
                    <span>{!isEditing && treatment.isActive.toString()}</span>
                </>
                <>
                    <label className="text-gray mr-4">Treatment: </label>

                    {!isEditing ? (
                        <span>{treatment.treatmentType}</span>
                    ) : (
                        <select
                            name="treatmentType"
                            className="appearance-none bg-transparent w-full"
                            defaultValue={initialValues.treatmentType}
                            onChange={(e) => {
                                setValues({ ...values, treatmentType: e.target.value });
                            }}>
                            {TreatmentTypes.map((tt, index) => (
                                <option key={`${tt}-${index}`} value={tt}>
                                    {tt}
                                </option>
                            ))}
                        </select>
                    )}
                </>
                <>
                    <label className="text-gray mr-4">Provider: </label>

                    {!isEditing ? (
                        <span>
                            {treatment.treatmentProvider?.name
                                ? treatment.treatmentProvider?.name
                                : 'GEM'}
                        </span>
                    ) : (
                        <select
                            name="treatmentProvider"
                            className="appearance-none bg-transparent w-full"
                            defaultValue={initialValues.treatmentProviderId}
                            onChange={(e) => {
                                setValues({
                                    ...values,
                                    treatmentProviderId: e.target.value,
                                });
                            }}>
                            <option key="GEM" value="">
                                GEM
                            </option>
                            {treatmentProviders?.results
                                .filter((provider) => {
                                    if (values.treatmentType === 'CPAP') {
                                        return provider.organizationType === 'DME_PROVIDER';
                                    } else {
                                        return provider.organizationType === 'DENTAL';
                                    }
                                })
                                .map((provider) => (
                                    <option key={provider.id} value={provider.id}>
                                        {provider.name}
                                    </option>
                                ))}
                        </select>
                    )}
                </>
                <>
                    <label className="text-gray mr-4">Start Date: </label>
                    {!isEditing ? (
                        <span>
                            {treatment.startDate
                                ? formatTreatmentDate(new Date(treatment.startDate))
                                : 'None'}
                        </span>
                    ) : (
                        <input
                            type="date"
                            name="startDate"
                            defaultValue={
                                treatment.startDate
                                    ? new Date(treatment.startDate).toISOString().substring(0, 10)
                                    : null
                            }
                            className="appearance-none bg-transparent text-white max-w-[150px]"
                            style={{ colorScheme: 'dark' }}
                            onChange={(e) => {
                                setValues({ ...values, startDate: new Date(e.target.value) });
                            }}
                        />
                    )}
                </>

                <>
                    <label className="text-gray mr-4">End Date: </label>
                    {!isEditing ? (
                        <span>
                            {treatment.endDate
                                ? formatTreatmentDate(new Date(treatment.endDate))
                                : 'None'}
                        </span>
                    ) : (
                        <input
                            type="date"
                            name="endDate"
                            defaultValue={
                                treatment.endDate
                                    ? new Date(treatment.endDate).toISOString().substring(0, 10)
                                    : null
                            }
                            className="appearance-none bg-transparent text-white max-w-[150px]"
                            style={{ colorScheme: 'dark' }}
                            onChange={(e) => {
                                setValues({ ...values, endDate: new Date(e.target.value) });
                            }}
                        />
                    )}
                </>

                <>
                    <label className="text-gray mr-4">Manufacturer: </label>
                    {!isEditing ? (
                        <span>{treatment.deviceManufacturer}</span>
                    ) : (
                        <select
                            name="deviceModel"
                            defaultValue={treatment.deviceManufacturer}
                            className="appearance-none bg-transparent text-white"
                            style={{ colorScheme: 'dark' }}
                            onChange={(e) => {
                                setValues({
                                    ...values,
                                    deviceManufacturer: e.target.value,
                                    deviceModel: '',
                                });
                            }}>
                            <option disabled selected value={null}>
                                {' '}
                                -- Select Manufacturer --{' '}
                            </option>
                            {Object.keys(cpapDevices).map((manufacturer) => (
                                <option key={manufacturer} value={manufacturer}>
                                    {manufacturer}
                                </option>
                            ))}
                        </select>
                    )}
                </>
                <>
                    <label className="text-gray mr-4">Model: </label>
                    {!isEditing ? (
                        <span>{values.deviceModel}</span>
                    ) : (
                        <select
                            name="deviceModel"
                            value={values.deviceModel}
                            style={{ colorScheme: 'dark' }}
                            onChange={(e) => {
                                setValues({ ...values, deviceModel: e.target.value });
                            }}>
                            <option disabled selected value="">
                                {' '}
                                -- Select Model --{' '}
                            </option>
                            {cpapDevices[values.deviceManufacturer]?.map((model) => (
                                <option key={model} value={model}>
                                    {model}
                                </option>
                            ))}
                        </select>
                    )}
                </>

                <>
                    <label className="text-gray mr-4">Serial Number: </label>
                    {!isEditing ? (
                        <span>{treatment.deviceSerial}</span>
                    ) : (
                        <input
                            type="string"
                            name="deviceSerial"
                            defaultValue={treatment.deviceSerial}
                            className="appearance-none bg-transparent text-white border rounded px-2"
                            style={{ colorScheme: 'dark' }}
                            onChange={(e) => {
                                setValues({ ...values, deviceSerial: e.target.value });
                            }}
                        />
                    )}
                </>

                <>
                    <label className="text-gray mr-4">Initial Compliance: </label>
                    {!isEditing ? (
                        <span>{treatment.initialCompliance}</span>
                    ) : (
                        <select
                            name="initialCompliance"
                            className="appearance-none bg-transparent w-full"
                            defaultValue={initialValues.initialCompliance}
                            onChange={(e) => {
                                setValues({
                                    ...values,
                                    initialCompliance:
                                        e.target.value !== 'None' ? e.target.value : null,
                                });
                            }}>
                            <option key="None">None</option>
                            {initialComplianceOptions.map((value) => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    )}
                </>

                {/* <>
                    <label className="text-gray mr-4">Mask SKU: </label>
                    <div>
                        {!isEditing ? (
                            <>{treatment.maskSku && <span>{treatment.maskSku}</span>}</>
                        ) : (
                            <>
                                <input
                                    type="string"
                                    name="maskSku"
                                    value={values.maskSku}
                                    className="appearance-none bg-transparent text-white border rounded px-2 max-w-[150px] mr-4"
                                    style={{ colorScheme: 'dark' }}
                                    onChange={(e) => {
                                        setValues({ ...values, maskSku: e.target.value });
                                    }}
                                />
                                <SelectItemFromCatalog
                                    itemOptions={maskOptions}
                                    label="Select Mask"
                                    onSubmit={(mask) => {
                                        setValues({
                                            ...values,
                                            maskSku: mask.sku,
                                        });
                                    }}
                                />
                            </>
                        )}
                    </div>
                </>

                <>
                    <label className="text-gray mr-4">Mask Name: </label>
                    <span>{maskName + ' ' + maskSize}</span>
                </> */}
            </div>
        </form>
    );
};

export default CurrentTreatments;
