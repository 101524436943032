import { useGetWebflowTermsQuery } from '../../app/services/webflow';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const TermsPage = ({ theme }) => {
    const { termsSlug } = useParams();
    const { data: webflowTerms, isLoading } = useGetWebflowTermsQuery();
    const termsContent = webflowTerms?.items.find((t) => t.slug === termsSlug);

    return (
        <div
            className={`${
                theme === 'light' ? 'layout-outlet-root' : 'bg-purple-dark text-white'
            } relative min-h-screen`}>
            <div className="container max-w-xl space-y-10 text-center lg:max-w-4xl">
                {isLoading && <p>Loading...</p>}
                <div className="terms-content-wrapper mb-4 pb-4">
                    {termsContent && (
                        <div
                            className="webflow-modal-content--agree-to-benefits text-left"
                            dangerouslySetInnerHTML={{
                                __html: termsContent['main-content'],
                            }}></div>
                    )}
                    {!isLoading && !termsContent && (
                        <p className="text-2xl">Apologies. No content found for this page.</p>
                    )}
                </div>
                <footer className="mt-5 border-t border-gray-medium py-4">
                    <p className="mt-4 text-2xl">
                        {' '}
                        <Link
                            to={theme === 'light' ? '/dashboard' : '/'}
                            className="inline-block border-b-2 border-green-400 font-bold">
                            Back to Home
                        </Link>
                        .
                    </p>
                </footer>
            </div>
        </div>
    );
};

export default TermsPage;
