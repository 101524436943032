import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../app/services/auth';
import { getMostRecentFileByType } from '../../utils/constants';

const initialState = {
    coachingPathWithoutPrescription: false,
    files: null,
    hasPrescription: false,
    requiresCharts: false,
    uploadedCharts: null,
    uploadedPrescription: null,
    uploadedSleepStudy: null,
    uploadedSleepStudyApproved: false,
};

export const filesSlice = createSlice({
    name: 'files',
    initialState: initialState,
    reducers: {
        setCoachingPathWithoutPrescription: (state, { payload }) => {
            state.coachingPathWithoutPrescription = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
                return initialState;
            })
            .addMatcher(api.endpoints.getMe.matchFulfilled, (state, { payload }) => {
                if (!payload) return;

                if (payload.patientProfile?.coachingStatus !== 'NOT_INITIATED') {
                    state.requiresCharts = true;
                }

                state.files = payload.files;

                const charts = getMostRecentFileByType(payload.files, 'CLINICAL_CHARTS');

                if (charts) {
                    state.uploadedCharts = charts;
                }

                const prescription = getMostRecentFileByType(payload.files, 'PRESCRIPTION');

                if (prescription) {
                    state.hasPrescription = true;
                }

                const uploadedSleepStudy = getMostRecentFileByType(
                    payload.files,
                    'UPLOADED_SLEEP_STUDY',
                );

                if (uploadedSleepStudy) {
                    state.uploadedSleepStudy = uploadedSleepStudy;
                    state.uploadedSleepStudyApproved = uploadedSleepStudy.status === 'APPROVED';
                }

                const uploadedPrescription = getMostRecentFileByType(
                    payload.files,
                    'UPLOADED_PRESCRIPTION',
                );

                if (uploadedPrescription) {
                    state.uploadedPrescription = uploadedPrescription;
                }

                const uploadedSleepStudyWithPrescription = getMostRecentFileByType(
                    payload.files,
                    'UPLOADED_SLEEP_STUDY_WITH_PRESCRIPTION',
                );

                if (uploadedSleepStudyWithPrescription) {
                    state.uploadedPrescription = uploadedSleepStudyWithPrescription;
                    state.uploadedSleepStudy = uploadedSleepStudyWithPrescription;
                    state.uploadedSleepStudyApproved =
                        uploadedSleepStudyWithPrescription.status === 'APPROVED';
                }

                //If they have uploaded sleep study and charts without prescription as a coaching candidate, mark as needing prescription
                if (
                    state.uploadedSleepStudy &&
                    !state.uploadedPrescription &&
                    state.uploadedCharts &&
                    payload.patientProfile?.coachingStatus !== 'NOT_INITIATED'
                ) {
                    state.coachingPathWithoutPrescription = true;
                }
            });
    },
});

export const { setCoachingPathWithoutPrescription } = filesSlice.actions;

export default filesSlice.reducer;
