import { useField } from 'formik';
import { FaCheck } from 'react-icons/fa';

const CustomCheckbox = ({ label, theme = 'dark', className, ...props }) => {
    const [field, meta, helpers] = useField(props);

    return (
        <>
            <div className={`relative flex items-center ${className}`}>
                <input
                    type="checkbox"
                    style={{ backgroundColor: `${field.value ? '#107e40' : 'transparent'}` }}
                    className={`h-6 w-6 flex-shrink-0 appearance-none border-4 bg-transparent ${
                        field.value ? 'border-green-600 bg-green-600' : 'border-green-400'
                    } float-left mr-2 cursor-pointer bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none`}
                    {...field}
                    {...props}
                    onChange={(e) => {
                        helpers.setValue(e.target.checked);
                        setTimeout(() => {
                            helpers.setTouched(true);
                        }, 500);
                    }}
                    onBlur={(e) => {
                        helpers.setTouched(true);
                    }}
                />
                <FaCheck
                    className={`pointer-events-none absolute left-3 w-2 -translate-x-1/2 scale-150 text-white opacity-0 transition ${
                        field.value ? 'opacity-100' : ''
                    }`}
                />
                <label
                    htmlFor={props.id || props.name}
                    className={`text-xs lg:text-sm ${theme === 'light' ? '' : 'text-gray-light'}`}>
                    {label}
                </label>
            </div>

            {meta.touched && meta.error && (
                <p className="ml-4 text-sm font-bold text-red">{meta.error}</p>
            )}
        </>
    );
};

export default CustomCheckbox;
