import { api } from '../auth';
import {
    constructPaginatedOrderHistoryQuery,
    constructPaginatedPatientQuery,
} from '../../../utils/constants';

function providesPartialList(resultsWithIds, tagType) {
    return resultsWithIds?.results
        ? [
              { type: tagType, id: 'PARTIAL_LIST' },
              ...resultsWithIds.results.map(({ id }) => ({ type: tagType, id })),
          ]
        : [{ type: tagType, id: 'PARTIAL_LIST' }];
}

const searchAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        deleteAdminQuery: builder.mutation({
            query: (id) => ({
                url: `/admin/queries/${id}`,
                method: 'DELETE',
                body: { id: id },
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Queries' }],
        }),
        getAdminQueries: builder.query({
            query: (queryType) => ({
                url: `/admin/queries?queryType=${queryType}`,
                method: 'GET',
            }),
            providesTags: (result) => providesPartialList(result, 'Queries'),
        }),
        getUsers: builder.query({
            query: ({
                accountCreationStatus,
                appointments,
                careTeam,
                coachingStatus,
                cpapOrderStatus,
                dentalReferralStatus,
                homeSleepStudyStatus,
                initialCompliance,
                insurance,
                isDentalReferred,
                mdStatus,
                needsSleepStudyRetest,
                orderBy,
                querySearch,
                referFromOrganization,
                referredForCpap,
                referToSleepClinic,
                resupply,
                searchTermValue,
                searchTermType,
                skip,
                supplyOrdersStatus,
                state,
                take,
            }) => {
                const formattedQuery = constructPaginatedPatientQuery({
                    accountCreationStatus,
                    appointments,
                    careTeam,
                    coachingStatus,
                    cpapOrderStatus,
                    dentalReferralStatus,
                    homeSleepStudyStatus,
                    initialCompliance,
                    insurance,
                    isDentalReferred,
                    mdStatus,
                    needsSleepStudyRetest,
                    orderBy,
                    querySearch,
                    referFromOrganization,
                    referredForCpap,
                    referToSleepClinic,
                    resupply,
                    searchTermType,
                    searchTermValue,
                    skip,
                    supplyOrdersStatus,
                    state,
                    take,
                });
                return {
                    url: '/users',
                    method: 'POST',
                    body: formattedQuery,
                };
            },
            providesTags: (result) => providesPartialList(result, 'User'),
        }),
        postAdminQuery: builder.mutation({
            query: ({
                accountCreationStatus,
                appointments,
                appointmentStatus,
                appointmentType,
                careTeam,
                coachingStatus,
                cpapOrderStatus,
                dentalReferralStatus,
                endDate,
                estimateStatus,
                fulfillmentStatus,
                homeSleepStudyStatus,
                initialCompliance,
                insurance,
                insuranceStatus,
                isDentalReferred,
                lastCompliance,
                mdStatus,
                needsSleepStudyRetest,
                orderBy,
                orderByKey,
                orderDesc,
                orderItemType,
                orderStatus,
                orderType,
                priorAuthStatus,
                querySearch,
                queryTabs,
                queryType,
                referFromOrganization,
                referredForCpap,
                referToSleepClinic,
                resupply,
                searchTermValue,
                searchTermType,
                skip,
                supplyOrdersStatus,
                startDate,
                state,
                take,
                //Query specific values
                userId,
                queryName,
                publicQuery,
            }) => {
                let queryToPost;

                if (queryType === 'PATIENT') {
                    queryToPost = constructPaginatedPatientQuery({
                        accountCreationStatus,
                        appointments,
                        careTeam,
                        coachingStatus,
                        cpapOrderStatus,
                        dentalReferralStatus,
                        homeSleepStudyStatus,
                        initialCompliance,
                        insurance,
                        isDentalReferred,
                        mdStatus,
                        needsSleepStudyRetest,
                        orderBy,
                        querySearch,
                        queryType,
                        referFromOrganization,
                        referredForCpap,
                        referToSleepClinic,
                        resupply,
                        searchTermValue,
                        searchTermType,
                        skip,
                        supplyOrdersStatus,
                        state,
                        take,
                    });
                } else {
                    queryToPost = constructPaginatedOrderHistoryQuery({
                        appointmentStatus,
                        appointmentType,
                        careTeam,
                        coachingStatus,
                        cpapOrderStatus,
                        estimateStatus,
                        fulfillmentStatus,
                        homeSleepStudyStatus,
                        mdStatus,
                        initialCompliance,
                        insurance,
                        insuranceStatus,
                        lastCompliance,
                        startDate,
                        endDate,
                        orderDesc,
                        orderByKey,
                        orderItemType,
                        orderStatus,
                        orderType,
                        priorAuthStatus,
                        querySearch,
                        referFromOrganization,
                        skip,
                        take,
                        searchTermType,
                        searchTermValue,
                        supplyOrdersStatus,
                    });
                }

                return {
                    url: '/admin/queries',
                    method: 'POST',
                    body: {
                        userId: userId,
                        queryName: queryName,
                        queryString: queryToPost,
                        queryTabs: queryTabs,
                        queryType: queryType,
                        publicQuery: publicQuery,
                    },
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Queries', id: 'PARTIAL_LIST' }],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetAdminQueriesQuery,
    useGetUsersQuery,
    useDeleteAdminQueryMutation,
    usePostAdminQueryMutation,
} = searchAPI;
