import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useGetAdminQueriesQuery } from '../../../app/services/admin/search';
import { resetFilters, setStatusFilter } from '../../../features/admin/adminSlice';
import { adminSelectCustomStyles, getQueryValues } from '../../../utils/constants';
import DeleteQuery from './DeleteQuery';

const Queries = ({ setColumnsToDisplay, setPage, queryType }) => {
    const dispatch = useDispatch();
    const { data: queries, error } = useGetAdminQueriesQuery(queryType);
    const { availableFilterType, filterType, filterComponents } = getQueryValues(queryType);
    //Select all of admin state,
    const adminState = useSelector((state) => state.admin);
    //Default to the query search if it has been set to allow returning to page to maintain state
    const [query, setQuery] = useState(adminState[filterType].querySearch);

    //Reset both status and careteam queries, reset query selection, remove all status filters
    const clearQuerySearch = async () => {
        dispatch(
            setStatusFilter({
                type: filterType,
                key: 'querySearch',
                value: null,
            }),
        );
    };

    const querySearchHandler = async () => {
        //Remove all filters and searches and reset any searches run by them
        dispatch(resetFilters({ availableFilterType, filterType, filterComponents }));
        setPage(0);

        //Set columns to display based on the query being searched
        if (query.queryTabs && setColumnsToDisplay) {
            setColumnsToDisplay(query.queryTabs);
        }

        //Dispatch new search and set Users per Page to match
        dispatch(
            setStatusFilter({
                type: filterType,
                key: 'querySearch',
                value: query,
            }),
        );
    };

    return (
        <div className="col-start-1 col-span-6 py-2">
            <p className="mb-3 font-semibold">Queries:</p>
            <div className="flex flex-wrap items-center">
                <Select
                    key={queries?.length}
                    options={queries?.map((query) => ({
                        label: query.queryName,
                        value: query.queryString,
                        queryTabs: query.queryTabs,
                        id: query.id,
                        raw: query.raw,
                        userId: query.userId,
                    }))}
                    className="basis-full md:basis-1/2 pr-4"
                    onChange={setQuery}
                    placeholder={'Choose Query'}
                    styles={adminSelectCustomStyles}
                    defaultValue={null}
                    value={query}
                />
                <div className="flex items-center basis-full md:basis-1/2 mt-4 pr-4 md:mt-0">
                    <button
                        disabled={!query}
                        onClick={() => querySearchHandler()}
                        className="btn-primary-small basis-1/3 text-base lg:text-lg mr-4">
                        Search
                    </button>
                    <button
                        disabled={!query}
                        onClick={() => {
                            clearQuerySearch();
                            setQuery(null);
                            setPage(0);
                        }}
                        className="btn-secondary-small basis-1/3 text-base lg:text-lg">
                        Clear
                    </button>
                    <DeleteQuery query={query} />
                </div>
            </div>
            {error && (
                <p className="text-sm font-bold text-red pt-4">
                    {error.data?.statusCode}: {error.data?.message}
                </p>
            )}
        </div>
    );
};

export default Queries;
