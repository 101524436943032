import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AppointmentReminder = ({ type = 'default' }) => {
	const { currentAppointment } = useSelector((state) => state.ui);

	return currentAppointment?.confirmed ? (
		type === 'nav' ? (
			<div className="mt-7 space-y-4 rounded-xl bg-purple-lightest/20 p-5 text-xs text-gray-neutral lg:mt-12 lg:p-6 lg:text-sm">
				<p className="font-medium">Your upcoming appointment is on:</p>
				<Link
					className="inline-block w-full rounded-full bg-gem-purple p-3 text-center font-bold text-white"
					to="/dashboard/appointments/my-appointment">
					{currentAppointment.start}
				</Link>
			</div>
		) : (
			<div className="mb-5 bg-white p-5 lg:flex lg:items-center lg:justify-between">
				<p>
					<span className="font-heading tracking-widest text-gem-purple lg:text-lg">Don't forget</span> <br />
					<b className="lg:text-xl">You have an upcoming GEM SLEEP appointment.</b>
				</p>
				<Link
					className="inline-block rounded-full bg-gem-purple p-3 text-center font-bold text-white"
					to="/dashboard/appointments/my-appointment">
					{currentAppointment.start}
				</Link>
			</div>
		)
	) : null;
};

export default AppointmentReminder;
