import { useDispatch, useSelector } from 'react-redux';
import { useGetFormattedSupplies } from '../../utils/useGetFormattedSupplies';
import { toggleModal } from '../../features/ui/uiSlice';
import { ProductImage } from '..';
import reorderCtaBg from '../../images/reorder-cta-bg.jpg';
import { formatSizeStrings } from '../../utils/constants';
import ProgressBadges from './ProgressBadges';
import { DentalReferral } from '.';

const CPAPTherapyReorder = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { formattedSupplies } = useGetFormattedSupplies();

    return (
        <>
            <h4 className="mb-7 flex items-center font-heading text-3xl lg:mb-12 lg:text-5xl">
                <span className="mr-4">cpap therapy</span>
                <ProgressBadges />
            </h4>
            <ul className="mb-7 space-y-5 lg:mb-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
                {formattedSupplies?.map((supply, idx) => (
                    <li
                        key={`lastOrderOpt-${idx}`}
                        className={`flex items-center space-x-5 rounded-xl p-3 transition ease-out-quad ${
                            supply.needsReorder && 'hover:cursor-pointer hover:bg-[#E4E7EB]'
                        }`}
                        onClick={() => {
                            if (supply.needsReorder) dispatch(toggleModal('reorderSupplies'));
                        }}>
                        <div
                            className={`h-14 w-14 min-w-[56px] overflow-hidden rounded-full ${
                                supply.needsReorder && 'border-2 border-red'
                            } bg-white lg:h-24 lg:w-24 lg:min-w-[96px]`}>
                            <ProductImage
                                sku={supply.sku}
                                alt={supply.name}
                                className="h-full w-full object-contain object-center"
                            />
                        </div>
                        <div className="text-xs lg:text-sm">
                            <p className="font-bold">
                                {supply.name}
                                <span className="ml-2 inline-block rounded-xl bg-purple py-1 px-2 text-xs font-bold capitalize text-white">
                                    {formatSizeStrings(supply.size)}
                                </span>
                            </p>
                            <p>{supply.description}</p>
                            {supply.needsReorder && (
                                <p className="text-xs font-bold text-red">Replace soon</p>
                            )}
                        </div>
                    </li>
                ))}
            </ul>

            <div className="mb-7 lg:mb-12 lg:flex">
                <div
                    className="relative flex-1 bg-black bg-cover bg-no-repeat p-9 text-center lg:p-4 lg:text-left"
                    style={{ backgroundImage: `url(${reorderCtaBg})` }}>
                    <span className="text-sm font-bold text-white lg:absolute lg:top-1/2 lg:left-4 lg:-translate-y-1/2 lg:text-lg">
                        Ready to renew your CPAP supplies?
                    </span>
                </div>
                <button
                    className="btn-primary w-full lg:w-[250px]"
                    onClick={() => {
                        dispatch(toggleModal('reorderSupplies'));
                    }}>
                    order supplies now
                </button>
            </div>

            {user.patientProfile.isDentalReferred && <DentalReferral />}
        </>
    );
};

export default CPAPTherapyReorder;
