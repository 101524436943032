import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as FileUploadIcon } from '../../images/file-upload-icon.svg';
import { ReactComponent as PDFIcon } from '../../images/pdf-icon.svg';

function FileSelector({ theme, handleFileSelect, maximumFiles, id }) {
    const [filesToUpload, setFilesToUpload] = useState([]);
    const fileRef = useRef(null);

    useEffect(() => handleFileSelect(filesToUpload), [filesToUpload, handleFileSelect]);

    function handleFileChange(event) {
        var newFileList = [...filesToUpload];
        if (event.target.files) {
            if (event.target.files.length > 0) {
                for (let i = 0; i < event.target.files.length; i++) {
                    if (newFileList.length < 2) {
                        if (
                            ['image/jpeg', 'image/png', 'application/pdf'].includes(
                                event.target.files[i].type,
                            )
                        ) {
                            newFileList.push({
                                id: id,
                                url: URL.createObjectURL(event.target.files[i]),
                                file: event.target.files[i],
                            });
                        } else {
                            toast.error('Wrong file type selected');
                        }
                    } else {
                        toast.error(
                            `A maximum of ${maximumFiles} file${
                                maximumFiles > 1 && 's'
                            } can be uploaded at a time.`,
                        );
                        setFilesToUpload(newFileList);
                        return;
                    }
                }
            }
        }
        setFilesToUpload(newFileList);
    }

    function removeElement(index) {
        const newFilesList = filesToUpload.filter((_, i) => i !== index);
        setFilesToUpload(newFilesList);
        fileRef.current.value = '';
    }

    return (
        <div>
            <div
                className={`${
                    theme === 'slim' ? 'p-4 lg:py-8' : 'px-4 py-2 xs:py-6'
                } relative border border-dashed border-black bg-white text-black group xs:min-h-[164px] lg:h-[360px]`}>
                <input
                    id="fileInput"
                    aria-label="Select files for upload"
                    className="block absolute w-full top-0 left-0 right-0 bottom-0 z-10 appearance-none opacity-0 cursor-pointer"
                    type="file"
                    name="attachments"
                    multiple={maximumFiles > 1}
                    accept="image/jpeg, image/png, application/pdf"
                    onChange={handleFileChange}
                    ref={fileRef}
                />
                {filesToUpload.length === 0 && (
                    <motion.div
                        key="file-upload-section"
                        className="text-center relative"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                        {theme !== 'slim' && (
                            <FileUploadIcon className="inline-block mb-2 xs:mb-4 h-[30px] xs:h-[40px] lg:h-[60px]" />
                        )}
                        <>
                            <p
                                className={`transition font-bold text-green-400 ${
                                    theme === 'slim'
                                        ? 'hidden lg:block lg:text-normal'
                                        : 'lg:text-3xl'
                                } mb-2 xs:mb-5 lg:mb-1 group-hover:text-green-hover`}>
                                {theme === 'slim'
                                    ? 'Click or drag and drop here to select your file to upload.'
                                    : 'Select your file to upload'}
                            </p>
                            {theme !== 'slim' && (
                                <p className="text-lg hidden lg:block">or drag and drop it here.</p>
                            )}
                        </>
                        <p className="text-xs lg:text-sm text-gray-six lg:mt-5">
                            Supported: PDF, JPG, PNG
                        </p>
                    </motion.div>
                )}
                {filesToUpload.length > 0 &&
                    filesToUpload.map((obj, idx) => (
                        <motion.div
                            key={`file-info-section-${idx}`}
                            className="flex flex-wrap items-center justify-center relative z-20"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}>
                            {obj.file.type === 'application/pdf' ? (
                                <>
                                    <PDFIcon className="w-[125px] h-[125px] lg:w-[200px] lg:h-[200px]" />
                                    <p className="whitespace-nowrap overflow-hidden text-ellipsis text-center w-[150px]">
                                        {obj.file.name}
                                    </p>
                                </>
                            ) : (
                                <div className="w-[125px] h-[125px] lg:w-[200px] lg:h-[200px] items-center justify-center">
                                    <img
                                        src={obj.url}
                                        alt={`Front of Card`}
                                        className="max-w-[125px] max-h-[125px] lg:max-w-[200px] lg:max-h-[200px] m-auto"
                                    />
                                </div>
                            )}
                            <button
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    removeElement(idx);
                                }}
                                className="btn-secondary w-full mt-2 text-white">
                                Remove
                            </button>
                        </motion.div>
                    ))}
            </div>
        </div>
    );
}

export default FileSelector;
