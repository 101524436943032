import { ReactComponent as LeftArrow } from '../../images/left-arrow.svg';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { useNavigate } from 'react-router';
import { useCheckoutContext } from '../Checkout/CheckoutContext';
import { useDispatch, useSelector } from 'react-redux';
import { toggleInsurance } from '../../features/cart/cartSlice';
import { useInsuranceContext } from '../Insurance/InsuranceContext';
import topRightArrow from '../../images/top-right-arrow.svg';

function CartInsuranceQuestion({ closeHandler, backClickHandler }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { dmeReferral } = useSelector((state) => state.ui);

    const { setCheckoutStep, finishInsuranceCheck } = useCheckoutContext();
    const { insurance } = useInsuranceContext();

    return (
        <div className="relative h-full">
            <nav className="fixed top-8 right-5 lg:right-8 z-10 flex space-x-3">
                <button
                    onClick={closeHandler}
                    className="flex items-center font-heading text-sm font-bold">
                    <span className="tracking-widest">close</span>
                    <span className="relative ml-2 aspect-1 w-6 rounded-full border-2 border-green-400">
                        <CloseIcon className="absolute-center" />
                    </span>
                </button>
            </nav>
            <button className="font-heading font-bold mt-4" onClick={() => backClickHandler()}>
                <LeftArrow className="text-black" />
            </button>

            <p className="font-heading font-bold text-[48px] leading-none mt-8">
                Do you want to add New insurance to this order?
            </p>

            {!insurance && (
                <p className="text-lg my-8">
                    We do not currently have insurance on file for you, do you want to add insurance
                    to this order?
                </p>
            )}

            {insurance && !insurance?.payer.CPAPCovered && (
                <p className="text-lg my-8">
                    Your current insurance doesn't cover CPAP, do you want to add new insurance?
                </p>
            )}

            <button
                className="btn-primary w-full mt-4"
                onClick={() => {
                    //Set insurance to false, set checkout step through finishInsuranceCheck, close modal, navigate to checkout
                    dispatch(toggleInsurance(false));
                    finishInsuranceCheck({ routeToRequiredBooking: false });
                    closeHandler();
                    navigate('/dashboard/checkout');
                }}>
                No, Proceed Without
            </button>
            <button
                className="btn-primary w-full my-4"
                onClick={() => {
                    //Set checkout step to insurance review, close modal, navigate to checkout
                    setCheckoutStep('insurance-details');
                    closeHandler();
                    navigate('/dashboard/checkout');
                }}>
                Yes, Add Insurance
            </button>

            {insurance && insurance.payer?.dmeReferralId !== null && !dmeReferral && (
                <div
                    className="absolute bottom-0 w-full bg-purple-bright p-5 flex items-center justify-between cursor-pointer"
                    onClick={() => {
                        navigate('/dashboard/treatment/comparison');
                        closeHandler();
                    }}>
                    <div className="relative">
                        <p className="font-bold text-white lg:text-xl">
                            Still thinking about a referral?
                        </p>
                        <p className="font-heading text-xs tracking-widest text-gray-neutral lg:text-sm">
                            Learn more about referral to{' '}
                            {insurance?.payer.dmeReferralOrganization?.name}.
                        </p>
                    </div>
                    <img src={topRightArrow} alt="" className="scale-[2.5]" />
                </div>
            )}
        </div>
    );
}

export default CartInsuranceQuestion;
