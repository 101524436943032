import { differenceInCalendarDays, format } from 'date-fns';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    useCancelAppointmentMutation,
    useScheduleAppointmentMutation,
} from '../../../app/services/appointments';
import {
    setAppointmentBookingData,
    setSimpleDynamicModalMessage,
} from '../../../features/ui/uiSlice';
import { MAX_DAYS_OUT_SCHEDULING, toDate } from '../../../utils/constants';
import Spinner from '../../Spinner/Spinner';
import posthog from 'posthog-js';

//Determined whether slots are morning, afternoon, or evening and parses their information creating an array to be displayed
function categorizeSlots(availableSlotsForRange) {
    if (availableSlotsForRange == null) {
        return [[], [], []];
    }

    const morningTimes = [];
    const afternoonTimes = [];
    const eveningTimes = [];

    for (let i = 0; i < availableSlotsForRange.length; i++) {
        const slot = availableSlotsForRange[i];
        const date = toDate(slot.date);

        // return slots with date strings not ending in 5
        // this will need to be revisited if we ever change appointment times
        // to be longer than 10 minutes or if we want gaps between meetings
        if (date.getMinutes().toString().endsWith(5)) {
            // skip
            continue;
        }
        if (date.getHours() < 12) {
            morningTimes.push(slot);
        } else if (date.getHours() < 17) {
            afternoonTimes.push(slot);
        } else {
            eveningTimes.push(slot);
        }
    }

    return [morningTimes, afternoonTimes, eveningTimes];
}

const AvailableSlot = ({ slot, index }) => {
    const dispatch = useDispatch();
    const { appointmentBooking } = useSelector((state) => state.ui);

    return (
        <div
            key={index}
            onClick={() => {
                dispatch(setAppointmentBookingData({ key: 'selectedSlot', value: slot }));
            }}
            className={`bg-[#ffffff] rounded-[13px] h-[27px] w-[73px] flex items-center justify-center text-[#2b2f3a] leading-4 text-[11px] font-normal text-center mr-2.5 mb-2.5 cursor-pointer hover:text-white hover:bg-[#00aa63] ${
                appointmentBooking.selectedSlot === slot ? '!text-[#ffffff] !bg-[#00aa63]' : ''
            }`}>
            {format(toDate(slot.date), 'h:mm a')}
        </div>
    );
};

function AvailableSlots({
    onAppointmentBooked,
    selectedDay,
    data,
    refetch,
    daysLoading,
    slotsLoading,
}) {
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { appointmentBooking, currentAppointment } = useSelector((state) => state.ui);
    const { orderId } = useSelector((state) => state.cart);
    const [scheduleAppointment] = useScheduleAppointmentMutation();
    const [cancelAppointment] = useCancelAppointmentMutation();

    // Set the order Id if rescheduling a cancelled appointment based on previous orderId, otherwise use cart Id
    const appointmentOrderId = appointmentBooking.appointmentToReschedule
        ? appointmentBooking.appointmentToReschedule.orderId
        : orderId;

    const submitBooking = async () => {
        setSubmitting(true);

        // If rescheduling a current appointment cancel this appointment
        if (appointmentBooking.appointmentToReschedule && currentAppointment) {
            cancelAppointment({
                healthieId: currentAppointment.healthieId,
                isRescheduling: true,
            })
                .unwrap()
                .catch((error) => {
                    toast.error(error.data?.message, { theme: 'dark' });
                    return;
                });
        }

        const { data: appointment } = await scheduleAppointment({
            appointmentTypeId: appointmentBooking?.selectedAppointmentType?.id,
            providerId: appointmentBooking.selectedSlot.user_id,
            datetime: appointmentBooking.selectedSlot.date,
            orderId: appointmentOrderId,
            confirmed: appointmentBooking.appointmentToReschedule
                ? appointmentBooking.appointmentToReschedule.confirmed
                : false,
        });

        if (appointment) {
            dispatch(
                setAppointmentBookingData([
                    {
                        key: 'bookedAppointment',
                        value: appointment,
                    },
                    {
                        key: 'appointmentToReschedule',
                        value: null,
                    },
                ]),
            );

            setSubmitting(false);

            posthog?.capture('Appointment booked', {
                appointmentType: appointmentBooking?.selectedAppointmentType?.name,
                providerId: appointmentBooking.selectedSlot.user_id,
                datetime: appointmentBooking.selectedSlot.date,
                orderId: appointmentOrderId,
            });

            if (onAppointmentBooked) {
                onAppointmentBooked(appointment);
            } else {
                navigate('/dashboard/appointments/appointment-confirmed');
            }

            return;
        } else {
            // refetch the list of available slots for the UI
            refetch();
            dispatch(
                setSimpleDynamicModalMessage(
                    'There was an error scheduling your appointment. Please try again.',
                ),
            );
            setSubmitting(false);
            return;
        }
    };

    //Creating array to be mapped
    const timeArrays = categorizeSlots(data?.availableSlotsForRange);

    return submitting ? (
        <Spinner
            key="booking spinner"
            header="your booking info"
            message="This will just take a moment."
        />
    ) : (
        <div className="flex overflow-hidden flex-col relative bg-[#00aa631a] rounded-none sm:rounded-xl min-h-[250px] sm:min-h-[200px] w-full max-w-full py-5 px-2.5 sm:px-[30px] mb-[60px] sm:mb-5">
            <div className="text-center">
                <span className="text-xl font-bold">{format(selectedDay, 'MMMM d, yyyy')}</span>
                <p className="font-normal leading-4 text-[11px] text-[#999999]">
                    Timezone:
                    {Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York'}
                </p>
            </div>

            <div className="flex flex-col mt-4">
                {timeArrays.map(
                    (timeArray, index) =>
                        !!timeArray.length && (
                            <div
                                key={`timeslot ${index}`}
                                className="tracking-[0.6px] leading-normal flex flex-1 text-xl font-medium text-[#182340] text-center mb-4 last:mb-6">
                                <span className="block text-left text-[#2b2f3a] font-normal text-sm leading-[21px] min-w-[76px] mr-5 ml-2.5">
                                    {index === 0
                                        ? 'Morning'
                                        : index === 1
                                          ? 'Afternoon'
                                          : 'Evening'}
                                </span>
                                <div className="flex flex-wrap">
                                    {timeArray.map((slot, index) => (
                                        <AvailableSlot
                                            key={`available-slot-${
                                                index === 0
                                                    ? 'Morning'
                                                    : index === 1
                                                      ? 'Afternoon'
                                                      : 'Evening'
                                            }-${index}`}
                                            slot={slot}
                                            index={index}
                                        />
                                    ))}
                                </div>
                            </div>
                        ),
                )}
            </div>

            {daysLoading || slotsLoading ? (
                <div className="ml-2 sm:ml-0">
                    <p className="mb-4">Checking for available slots...</p>
                </div>
            ) : differenceInCalendarDays(selectedDay, new Date()) > MAX_DAYS_OUT_SCHEDULING ? (
                <div className="ml-2 sm:ml-0">
                    <p className="mb-4 text-center">
                        Sorry, we are not scheduling out this far in advance. If you cannot find an
                        appointment sooner that works with your schedule, please feel free to call
                        us at <span className="font-bold whitespace-nowrap">(833) 946-4436</span>.
                    </p>
                </div>
            ) : timeArrays[0].length === 0 &&
              timeArrays[1].length === 0 &&
              timeArrays[2].length === 0 ? (
                <div className="ml-2 sm:ml-0">
                    <p className="mb-4">
                        Sorry GEM SLEEP does not have any availability on this day!
                    </p>
                    <p>
                        Please select a different day. To advance to the next month click the arrow
                        on the top right of the calendar.
                    </p>
                </div>
            ) : (
                <div className="m-auto">
                    <button
                        className="btn-primary-small text-2xl"
                        disabled={submitting || !appointmentBooking.selectedSlot}
                        onClick={submitBooking}>
                        Confirm Date and Time
                    </button>
                </div>
            )}
        </div>
    );
}

export default AvailableSlots;
