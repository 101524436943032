import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

import { setStatusFilter } from '../../../features/admin/adminSlice';

import { useGetAppointmentTypesQuery } from '../../../app/services/appointments';
import { useGetAdminUsersQuery } from '../../../app/services/admin/admin';
import { adminSelectCustomStyles, appointmentStatusOptions } from '../../../utils/constants';

function ConsultFilters() {
    const dispatch = useDispatch();

    //Redux state values
    const { activeConsultFilters } = useSelector((state) => state.admin);

    //Local state
    const [firstName, setFirstName] = useState(activeConsultFilters.firstName);
    const [lastName, setLastName] = useState(activeConsultFilters.lastName);
    const [startDate, setStartDate] = useState(
        activeConsultFilters.startDate ? new Date(activeConsultFilters.startDate) : null,
    );
    const [endDate, setEndDate] = useState(
        activeConsultFilters.endDate ? new Date(activeConsultFilters.endDate) : null,
    );

    //Collect coaches for querying and construct options for filtering
    const providerUserQuery = [{ userRoles: { some: { role: 'MD' } } }];
    const { data: providers } = useGetAdminUsersQuery(providerUserQuery);
    const providerOptions = providers?.results
        .filter((member) => member.healthieProviderId !== null)
        .map((member) => ({
            value: member.id,
            label: member.firstName + ' ' + member.lastName,
        }));

    const { data: appointmentTypes } = useGetAppointmentTypesQuery();

    return (
        <>
            <div className="mb-2">
                <label>Patient Name:</label>
                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                    <input
                        name="firstName"
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.currentTarget.value)}
                        placeholder="First Name"
                        className="appearance-none border border-gray rounded bg-transparent p-2 text-sm text-white w-full sm:w-1/4"
                    />
                    <input
                        name="lastName"
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.currentTarget.value)}
                        placeholder="Last Name"
                        className="appearance-none border border-gray rounded bg-transparent p-2 text-sm text-white w-full sm:w-1/4"
                    />
                    <button
                        className="btn-primary-small h-[34px] md:h-[42px] w-full sm:w-[150px]"
                        onClick={() => {
                            dispatch(
                                setStatusFilter([
                                    {
                                        type: 'activeConsultFilters',
                                        key: 'firstName',
                                        value: firstName,
                                    },
                                    {
                                        type: 'activeConsultFilters',
                                        key: 'lastName',
                                        value: lastName,
                                    },
                                ]),
                            );
                        }}>
                        Search
                    </button>
                    <button
                        className="btn-secondary-small h-[34px] md:h-[42px] w-full sm:w-[150px]"
                        onClick={() => {
                            dispatch(
                                setStatusFilter([
                                    {
                                        type: 'activeConsultFilters',
                                        key: 'firstName',
                                        value: '',
                                    },
                                    {
                                        type: 'activeConsultFilters',
                                        key: 'lastName',
                                        value: '',
                                    },
                                ]),
                            );
                            setFirstName('');
                            setLastName('');
                        }}>
                        Clear
                    </button>
                </div>
            </div>
            <div className="filters mb-2 flex flex-col lg:flex-row lg:space-x-4">
                <div>
                    <label>Status:</label>
                    <Select
                        options={appointmentStatusOptions}
                        className="min-w-[200px]"
                        onChange={(selectedOption) =>
                            dispatch(
                                setStatusFilter([
                                    {
                                        type: 'activeConsultFilters',
                                        key: 'skip',
                                        value: 0,
                                    },
                                    {
                                        type: 'activeConsultFilters',
                                        key: 'appointmentStatus',
                                        value: selectedOption,
                                    },
                                ]),
                            )
                        }
                        placeholder={'Select Status'}
                        styles={adminSelectCustomStyles}
                        value={activeConsultFilters.appointmentStatus}
                    />
                </div>
                <div>
                    <label>Provider:</label>
                    <Select
                        options={providerOptions}
                        className="min-w-[200px]"
                        onChange={(selectedOption) =>
                            dispatch(
                                setStatusFilter([
                                    {
                                        type: 'activeConsultFilters',
                                        key: 'skip',
                                        value: 0,
                                    },
                                    {
                                        type: 'activeConsultFilters',
                                        key: 'providerId',
                                        value: selectedOption,
                                    },
                                ]),
                            )
                        }
                        placeholder={'Select Provider'}
                        styles={adminSelectCustomStyles}
                        value={activeConsultFilters.providerId}
                    />
                </div>
                <div>
                    <label>Type:</label>
                    <Select
                        options={appointmentTypes}
                        getOptionLabel={(option) => option.name}
                        className="min-w-[200px]"
                        onChange={(selectedOption) =>
                            dispatch(
                                setStatusFilter([
                                    {
                                        type: 'activeConsultFilters',
                                        key: 'skip',
                                        value: 0,
                                    },
                                    {
                                        type: 'activeConsultFilters',
                                        key: 'appointmentType',
                                        value: selectedOption,
                                    },
                                ]),
                            )
                        }
                        placeholder={'Select Status'}
                        styles={adminSelectCustomStyles}
                        value={activeConsultFilters.appointmentType}
                    />
                </div>
                <div className="grid">
                    <label>Date:</label>
                    <DatePicker
                        isClearable
                        className="appearance-none border border-gray rounded bg-transparent p-2 text-sm text-white"
                        selected={startDate}
                        onChange={(date) => {
                            dispatch(
                                setStatusFilter({
                                    type: 'activeConsultFilters',
                                    key: 'startDate',
                                    value: date ? date.toISOString() : null,
                                }),
                            );
                            setStartDate(date);
                        }}
                    />
                </div>
                {(startDate || endDate) && (
                    <div className="grid">
                        <label>Until:</label>
                        <DatePicker
                            isClearable
                            className="appearance-none border border-gray rounded bg-transparent p-2 text-sm text-white"
                            selected={endDate}
                            onChange={(date) => {
                                dispatch(
                                    setStatusFilter({
                                        type: 'activeConsultFilters',
                                        key: 'endDate',
                                        value: date ? date.toISOString() : null,
                                    }),
                                );
                                setEndDate(date);
                            }}
                        />
                    </div>
                )}
                <button
                    className="btn-secondary-small mt-6 h-[34px] md:h-[42px] w-[150px]"
                    onClick={() => {
                        dispatch(
                            setStatusFilter([
                                {
                                    type: 'activeConsultFilters',
                                    key: 'appointmentStatus',
                                    value: null,
                                },
                                {
                                    type: 'activeConsultFilters',
                                    key: 'appointmentType',
                                    value: null,
                                },
                                {
                                    type: 'activeConsultFilters',
                                    key: 'providerId',
                                    value: null,
                                },
                                {
                                    type: 'activeConsultFilters',
                                    key: 'skip',
                                    value: 0,
                                },
                                {
                                    type: 'activeConsultFilters',
                                    key: 'startDate',
                                    value: null,
                                },
                                {
                                    type: 'activeConsultFilters',
                                    key: 'payerName',
                                    value: null,
                                },
                                {
                                    type: 'activeConsultFilters',
                                    key: 'insuranceStatus',
                                    value: null,
                                },
                            ]),
                        );
                        setStartDate(null);
                        setEndDate(null);
                    }}>
                    Clear
                </button>
            </div>
        </>
    );
}

export default ConsultFilters;
