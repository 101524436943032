import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { setAssessmentStep } from '../../../features/assessment/assessmentSlice';
import sleepingMan from '../../../images/sleeping-man.jpg';
import userJourneyMobile from '../../../images/user-journey-flow-mobile.svg';
import userJourney from '../../../images/user-journey-flow.svg';
import { usePostHog } from 'posthog-js/react';

const HasSleepApnea = () => {
    const dispatch = useDispatch();
    const posthog = usePostHog();

    const [screenIndex, setScreenIndex] = useState(0);

    return (
        <AnimatePresence mode="wait">
            {screenIndex === 0 && (
                <motion.div
                    key="screen-0"
                    initial={{ opacity: 0, translateX: '20%' }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: '-20%' }}
                    transition={{ ease: 'linear' }}
                    className="mx-auto">
                    <div className="max-w-8xl mx-auto grid grid-cols-1 gap-4 lg:gap-8 lg:grid-cols-2">
                        <div className="pr-0 lg:pr-8">
                            <h4 className="mb-4 font-heading text-3xl text-purple-fair lg:mb-6">
                                ASSESSMENT RESULTS
                            </h4>
                            <h2 className="font-heading text-4xl font-bold text-white lg:mb-5 lg:text-7xl xl:text-8xl">
                                You likely have sleep apnea.{' '}
                                <span className="text-gray-light">
                                    a sleep study is needed to diagnose and treat
                                </span>
                            </h2>
                        </div>

                        <div className="flex flex-col text-sm pl-0 space-y-4 lg:pl-8 lg:flex-col-reverse">
                            <button
                                className="btn-primary w-full px-5 py-4 text-lg lg:mt-2"
                                onClick={() => {
                                    posthog?.capture('Answered a Question', {
                                        question: 'Assessment Result',
                                        answer: 'continue',
                                    });
                                    ReactGA.event({
                                        category: 'User',
                                        action: 'Assessment Result',
                                        label: 'Continue_Result',
                                    });
                                    setScreenIndex(1);
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth',
                                    });
                                }}>
                                CONTINUE
                            </button>
                            <div className="text-sm space-y-4 lg:text-lg">
                                <p className="text-lg font-bold text-gray-light lg:text-xl">
                                    How your risk was assessed:
                                </p>

                                <p>
                                    Likelihood of sleep apnea is based on common risk factors
                                    including snoring, tiredness, and pauses in breathing.
                                </p>

                                <p className="text-lg font-bold text-gray-light lg:text-xl">
                                    What's next:
                                </p>

                                <p>
                                    A{' '}
                                    <span className="underline italic">sleep study is needed</span>{' '}
                                    to determine a diagnosis and start treatment. GEM provides easy
                                    at home sleep studies. We accept most major insurances.
                                </p>

                                <p className="text-lg font-bold text-gray-light lg:text-xl">
                                    What happens if I have sleep apnea:
                                </p>

                                <p>
                                    Sleep apnea is very common and very treatable. We are here to
                                    support you with treatment options.
                                </p>
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}
            {screenIndex === 1 && (
                <motion.div
                    key="screen-1"
                    initial={{ opacity: 0, translateX: '20%' }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: '-20%' }}
                    transition={{ ease: 'linear' }}
                    className="mx-auto">
                    <div className="journey-status-bar mx-auto max-w-2xl">
                        <img
                            className="user-journey mx-auto hidden w-full md:block"
                            src={userJourney}
                            alt="user journey"
                        />
                        <img
                            className="user-journey mx-auto block w-full md:hidden"
                            src={userJourneyMobile}
                            alt="user journey"
                        />
                    </div>

                    <div className="mx-auto mt-6 xs:mt-8 max-w-5xl overflow-hidden">
                        <div className="max-w-4xl">
                            <h2 className="font-heading text-3xl xs:text-5xl font-bold text-gray-light lg:mb-5 lg:text-6xl">
                                How a <span className="text-white">Home Sleep Study</span> Works.
                            </h2>
                        </div>
                        <div className="mt-4 w-full flex flex-col lg:flex-row-reverse lg:gap-5 ">
                            <div className="w-full text-left text-gray-light lg:w-3/5">
                                <div className="space-y-4">
                                    <p className="xs:text-lg">
                                        Home sleep studies are used to diagnose sleep apnea and
                                        determine treatment options.
                                    </p>
                                    <p>We've made the process easy:</p>
                                    <ul className="list-disc list-outside pl-4 space-y-2 text-xs xs:text-sm">
                                        <li>
                                            Order your home sleep test (insurance coverage may
                                            apply)
                                        </li>
                                        <li>Complete it at home on your own time</li>
                                        <li>
                                            GEM SLEEP clinicians interpret your data (within 1 week)
                                        </li>
                                        <li>
                                            Receive a full report and prescription (if necessary)
                                        </li>
                                        <li>
                                            Order treatment through GEM or take your prescription
                                            wherever you choose
                                        </li>
                                    </ul>
                                </div>
                                <button
                                    className="btn-primary w-full px-4 text-lg mt-4 lg:hidden"
                                    onClick={() => {
                                        ReactGA.event({
                                            category: 'User',
                                            action: 'Assessment Result',
                                            label: 'Continue_NextSteps',
                                        });

                                        posthog?.capture('Answered a Question', {
                                            question: 'How a Sleep Study Works',
                                            answer: 'continue',
                                        });

                                        dispatch(setAssessmentStep('create-account'));

                                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                    }}>
                                    Continue
                                </button>
                            </div>
                            <div className="relative my-8 w-full lg:my-0 lg:mb-0 lg:w-2/5 hidden md:block">
                                <div className="relative w-full">
                                    <img src={sleepingMan} alt="sleeping man" />
                                    <div
                                        className="absolute bottom-0 left-0 right-0"
                                        style={{
                                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                            backdropFilter: 'blur(5px)',
                                        }}>
                                        <p className="pl-2 py-1 text-base font-bold lg:text-lg">
                                            GEM Home Sleep Study
                                        </p>
                                        <p className="py-1 pl-2 font-heading text-lg  font-bold lg:text-2xl">
                                            MANY MAJOR INSURANCES ACCEPTED
                                        </p>
                                    </div>
                                </div>
                                <button
                                    className="btn-primary mt-8 w-full px-4 text-lg hidden lg:block"
                                    onClick={() => {
                                        ReactGA.event({
                                            category: 'User',
                                            action: 'Assessment Result',
                                            label: 'Continue_NextSteps',
                                        });

                                        posthog?.capture('Answered a Question', {
                                            question: 'How a Sleep Study Works',
                                            answer: 'continue',
                                        });

                                        dispatch(setAssessmentStep('create-account'));

                                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                    }}>
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default HasSleepApnea;
