import { DirectUpload } from '@rails/activestorage';
import { cleanFilename } from '../../utils/constants';

export class FileUploader {
    constructor(file, url, progressCallback) {
        const cleanedFile = new File([file], cleanFilename(file.name), { type: file.type });

        // ActiveStorage...?
        this.upload = new DirectUpload(cleanedFile, url, this);
        this.progressCallback = progressCallback;
    }

    uploadFile() {
        return new Promise(async (resolve, reject) => {
            this.upload.create((error, blob) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(blob.id);
                }
            });
        });
    }

    directUploadWillStoreFileWithXHR(request) {
        request.upload.addEventListener('progress', (event) => this.directUploadDidProgress(event));
    }

    directUploadDidProgress(event) {
        if (!this.progressCallback) return;
        const progress = (event.loaded / event.total) * 100;
        this.progressCallback(progress);
    }
}
