const Pagination = ({ page, disabled, totalPages, setPage, ...rest }) => {
    return (
        <nav className="flex items-center justify-center space-x-5 py-2">
            <button
                onClick={() => setPage(0)}
                className="font-heading text-2xl tracking-widest text-gem-green disabled:opacity-25"
                disabled={disabled || page === 0}>
                first
            </button>

            <button
                onClick={() => setPage(page - 1)}
                className="font-heading text-2xl tracking-widest text-gem-green disabled:opacity-25"
                disabled={disabled || page === 0}>
                previous
            </button>

            <p className="font-heading text-3xl tracking-widest text-gem-green opacity-25">
                {page + 1}/{totalPages + 1}
            </p>

            <button
                onClick={() => setPage(page + 1)}
                className="font-heading text-2xl tracking-widest text-gem-green disabled:opacity-25"
                disabled={disabled || page === totalPages}>
                next
            </button>

            <button
                onClick={() => setPage(totalPages)}
                className="font-heading text-2xl tracking-widest text-gem-green disabled:opacity-25"
                disabled={disabled || page === totalPages}>
                last
            </button>
        </nav>
    );
};

export default Pagination;
