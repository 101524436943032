import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const notInsuranceEligible = ['38113', 'CHBRUSH', 'CHCMKWP'];

export const useGetFormattedSupplies = () => {
    const [formattedSupplies, setFormattedSupplies] = useState(null);
    const { catalogItems, currentSupplies } = useSelector((state) => state.catalog);

    useEffect(() => {
        if (catalogItems.length === 0 || !currentSupplies) return;

        const parsedSupplies = currentSupplies
            .filter((s) => s.hasOwnProperty('lifeSpan'))
            .map((supply) => {
                const matchingItem = catalogItems?.find((item) => item.id === supply.item.id);
                const variation = supply.item.itemData.variations.find(
                    (v) => v.id === supply.variantId,
                );
                const quantityOverride = Object.values(variation.customAttributeValues)?.find(
                    (attVal) => attVal.name === 'Supply QTY',
                )?.stringValue;
                const notCoveredByInsurance = notInsuranceEligible.includes(
                    variation.itemVariationData.sku,
                );

                return {
                    needsReorder: supply.needsReorder,
                    date: supply.orderDate,
                    name: supply.item.itemData.name,
                    description: supply.item.itemData.description,
                    size: variation?.itemVariationData?.name,
                    image: matchingItem?.images ? matchingItem?.images[0] : null,
                    price: variation?.itemVariationData?.priceMoney?.amount,
                    id: supply.variantId,
                    sku: variation?.itemVariationData?.sku,
                    quantityOverride: quantityOverride,
                    notCoveredByInsurance: notCoveredByInsurance,
                };
            });

        setFormattedSupplies(parsedSupplies);
    }, [catalogItems, currentSupplies]);

    return { formattedSupplies };
};
