import { useSelector, useDispatch } from 'react-redux';
import { useGetUserTimelineQuery } from '../../app/services/auth';
import { toggle } from '../../features/ui/uiSlice';
import { BsCheckLg } from 'react-icons/bs';
import { formatDate } from '../../utils/constants';

const HomeTimeline = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { data: timeline } = useGetUserTimelineQuery(user?.id, { skip: !user?.id });

    return (
        <>
            <ol className="relative ml-8 mt-12 hidden space-y-2 lg:block">
                {timeline &&
                    timeline.map((ev, idx) => (
                        <li
                            key={`user-history-event-${idx}`}
                            className={`relative -left-2 -top-6 px-6 py-6 pl-12 transition ${
                                ev.complete &&
                                'hover:cursor-pointer hover:bg-white hover:drop-shadow-xl'
                            }`}>
                            {!ev.complete ? (
                                <span className="absolute left-3 flex h-6 w-6 items-center justify-center rounded-full border border-black bg-offwhite text-white"></span>
                            ) : (
                                <span className="absolute left-3 flex h-6 w-6 items-center justify-center rounded-full bg-green-400 text-white">
                                    <BsCheckLg className="h-3 w-3" />
                                </span>
                            )}
                            <h3 className="mb-3 flex items-center text-lg font-bold leading-none">
                                {ev.label}
                            </h3>
                            {ev.date && (
                                <time className="block font-heading text-sm leading-none tracking-widest">
                                    {formatDate(ev.date)}
                                </time>
                            )}
                        </li>
                    ))}
            </ol>
            <button
                className="btn-shell w-full text-sm mt-4 lg:mt-0"
                onClick={() => {
                    dispatch(toggle({ key: 'orderHistoryOpen' }));
                }}>
                View my gem history
            </button>
        </>
    );
};

export default HomeTimeline;
