import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../app/services/auth';

const initialState = {
    appointment: null,
    orderId: null,
    orderItems: [],
    cpapInCart: false,
    hstInCart: false,
    suppliesInCart: false,
    isConsultOrder: false,
    consultRequired: false,
    stateRequiredTelehealth: false,
    subtotal: 0,
    discount: 0,
    orderType: undefined,
    usingInsurance: undefined,
    discountCode: '',
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState: initialState,
    reducers: {
        toggleInsurance: (state, { payload }) => {
            // pass this to create order at checkout
            state.usingInsurance = typeof payload !== 'undefined' ? payload : !state.usingInsurance;
        },
        setConsultRequired: (state, { payload }) => {
            state.consultRequired = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(api.endpoints.getCart.matchFulfilled, (state, { payload }) => {
                if (!payload) {
                    return;
                }
                if (payload.orderItems.length === 0) {
                    state.orderItems = [];
                    state.cpapInCart = false;
                    state.hstInCart = false;
                    state.isConsultOrder = false;
                    state.suppliesInCart = false;
                } else {
                    // determine what types of items are in cart
                    state.suppliesInCart = payload.orderItems.some(
                        (p) => p.itemCategory === 'SUPPLIES',
                    );
                    state.hstInCart = payload.orderItems.some(
                        (p) => p.itemCategory === 'SLEEP_STUDY',
                    );
                    state.cpapInCart = payload.orderItems.some(
                        (p) => p.itemCategory === 'CPAP_SUPPLIES',
                    );
                    //Consult orders are those with a CLINICAL CONSULT that is not a required consult
                    state.isConsultOrder =
                        payload.orderItems.some((p) => p.itemCategory === 'CLINICAL_CONSULTS') &&
                        !payload.orderItems.some((p) => p.sku === 'REQCONSULT');
                    state.orderItems = payload.orderItems;
                }
                // we aren't charging tax directly
                // we will need to subtract it from the subtotal
                // when we break it out.
                state.orderId = payload.id;
                state.discountCode = payload.discountCode;
                state.subtotal = payload.total;
                state.discount = payload.totalDiscount;
                state.appointment = payload.appointments.find(
                    (appointment) => appointment.appointmentStatus === 'SCHEDULED',
                );
                state.orderType = payload.orderType;
            })
            .addMatcher(api.endpoints.getStateServices.matchFulfilled, (state, { payload }) => {
                state.stateRequiredTelehealth = payload?.telehealthForState;
            })
            .addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
                return initialState;
            })
            .addMatcher(api.endpoints.getStateServices.matchFulfilled, (state, { payload }) => {
                state.stateRequiredTelehealth = payload?.telehealthForState;
            });
    },
});

export const { toggleInsurance, setConsultRequired } = cartSlice.actions;

export default cartSlice.reducer;
