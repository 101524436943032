import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isDate } from 'date-fns';

import { ReactComponent as Calendar } from '../../../images/icons/calendar.svg';
import { adminSelectCustomStyles, referralStatuses } from '../../../utils/constants';
import { setStatusFilter } from '../../../features/admin/adminSlice';

const initialFilters = {
    dateSearch: null,
    startDate: null,
    endDate: null,
    referralStatus: null,
    organizationId: null,
};

const dateOptions = [
    { label: 'Sent', value: 'referralSentAt' },
    { label: 'Created', value: 'createdAt' },
    { label: 'Updated', value: 'updatedAt' },
];

const referralOptions = referralStatuses.map((option) => ({
    label: option,
    value: option,
}));

function ReferralsFilters({ partners, setPage }) {
    const dispatch = useDispatch();
    const { activeReferralFilters } = useSelector((state) => state.admin);
    const [filters, setFilters] = useState(activeReferralFilters);

    const dmeReferrers = partners?.results?.filter(
        (partner) => partner.organizationType === 'DME_PROVIDER',
    );

    const dmeReferrerOptions = dmeReferrers?.map((partner) => ({
        label: partner.name,
        value: partner.id,
    }));

    return (
        <div className="space-y-4 mb-4">
            <div className="space-y-2 sm:flex sm:gap-x-2 sm:space-y-0 items-center">
                <div className="flex gap-x-2 items-center">
                    <p className="w-[125px] sm:w-auto">Referral Status:</p>
                    <Select
                        options={referralOptions}
                        className="w-[250px]"
                        onChange={(selectedOption) =>
                            setFilters({ ...filters, referralStatus: selectedOption.value })
                        }
                        placeholder={'Select Status'}
                        styles={adminSelectCustomStyles}
                        value={
                            referralOptions.find(
                                (option) => option.value === filters?.referralStatus,
                            ) ?? null
                        }
                    />
                </div>
                <div className="flex gap-x-2 items-center">
                    <p className="w-[125px] sm:w-auto">Organization:</p>
                    <Select
                        options={dmeReferrerOptions}
                        className="w-[250px]"
                        onChange={(selectedOption) =>
                            setFilters({ ...filters, organizationId: selectedOption.value })
                        }
                        placeholder={'Select Organization'}
                        styles={adminSelectCustomStyles}
                        value={
                            dmeReferrerOptions?.find(
                                (partner) => partner.value === filters.organizationId,
                            ) ?? null
                        }
                    />
                </div>
            </div>
            <div className="space-y-2 sm:flex sm:gap-x-2 sm:space-y-0 items-center mb-2">
                <p className="w-[125px]">Date Search:</p>
                <Select
                    options={dateOptions}
                    className="w-[200px]"
                    onChange={(selectedOption) =>
                        setFilters({ ...filters, dateSearch: selectedOption.value })
                    }
                    placeholder={'Date to Search'}
                    styles={adminSelectCustomStyles}
                    value={
                        dateOptions.find((option) => option.value === filters?.dateSearch) ?? null
                    }
                />
                <DatePicker
                    showIcon={!filters?.startDate}
                    placeholderText="  FROM"
                    toggleCalendarOnIconClick
                    icon={<Calendar className="scale-75" />}
                    isClearable
                    className="appearance-none border border-gray rounded bg-transparent p-2 text-sm text-white w-[140px] mr-2 sm:mr-0"
                    selected={filters?.startDate ? new Date(filters?.startDate) : null}
                    onChange={(date) => {
                        setFilters({ ...filters, startDate: date });
                    }}
                />
                <DatePicker
                    showIcon={!filters?.endDate}
                    placeholderText="  TO"
                    toggleCalendarOnIconClick
                    icon={<Calendar className="scale-75" />}
                    isClearable
                    className="appearance-none border border-gray rounded bg-transparent p-2 text-sm text-white w-[150px]"
                    selected={filters?.endDate ? new Date(filters?.endDate) : null}
                    onChange={(date) => {
                        setFilters({ ...filters, endDate: date });
                    }}
                />
            </div>
            <div className="flex gap-x-2 items-center mb-4">
                <button
                    className="btn-primary-small w-[150px]"
                    onClick={() => {
                        setPage(0);
                        dispatch(
                            setStatusFilter(
                                Object.keys(filters).map((key) => ({
                                    type: 'activeReferralFilters',
                                    key: key,
                                    value: isDate(filters[key])
                                        ? filters[key].toISOString()
                                        : filters[key],
                                })),
                            ),
                        );
                    }}>
                    Search
                </button>
                <button
                    className="btn-secondary-small w-[150px]"
                    onClick={() => {
                        setPage(0);
                        setFilters(initialFilters);
                        dispatch(
                            setStatusFilter(
                                Object.keys(initialFilters).map((key) => ({
                                    type: 'activeReferralFilters',
                                    key: key,
                                    value: initialFilters[key],
                                })),
                            ),
                        );
                    }}>
                    Reset
                </button>
            </div>
        </div>
    );
}

export default ReferralsFilters;
