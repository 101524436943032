import { useSelector, useDispatch } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import AccountContent from './AccountContent';
import AccountEditContent from './AccountEditContent';
import AccountPasswordReset from './AccountPasswordReset';
import AccountOrderHistory from './AccountOrderHistory';
import { toggle } from '../../features/ui/uiSlice';
import { overlayVariants } from '../../utils/constants';

const MyAccount = ({ closeHandler }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { accountOpen, editScreenOpen, passwordResetScreenOpen } = useSelector(
        (state) => state.ui,
    );

    const closeClickHandler = (ev) => {
        dispatch(toggle({ key: 'orderHistoryOpen', override: false }));
        dispatch(toggle({ key: 'editScreenOpen', override: false }));
        dispatch(toggle({ key: 'passwordResetScreenOpen', override: false }));
        closeHandler();
    };

    return (
        <AnimatePresence>
            {accountOpen && (
                <motion.div
                    key="account-overlay"
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={overlayVariants}
                    onClick={closeClickHandler}
                    className="fixed top-0 bottom-0 z-30 w-screen bg-purple-dark/90 backdrop-blur-md"></motion.div>
            )}

            <motion.div
                key="account-content"
                className={`fixed right-0 z-40 h-screen w-screen overflow-y-auto bg-white px-5 py-5 text-gray-dark transition duration-300 lg:w-1/2 xl:w-1/3 lg:px-9 ${
                    accountOpen ? 'translate-x-0' : 'translate-x-full'
                } flex flex-col`}>
                <AccountContent closeHandler={closeClickHandler} />
            </motion.div>

            <motion.div
                key="account-content-edit"
                className={`fixed right-0 z-40 h-screen w-screen overflow-y-auto bg-white px-5 py-5 text-gray-dark transition duration-300 lg:w-1/2 xl:w-1/3 lg:px-9 ${
                    editScreenOpen && accountOpen ? 'translate-x-0' : 'translate-x-full'
                }  flex flex-col overflow-y-auto`}>
                {user && editScreenOpen && <AccountEditContent />}
            </motion.div>

            <motion.div
                key="account-password-reset"
                className={`fixed right-0 z-40 h-screen w-screen overflow-y-auto bg-white px-5 py-16 text-gray-dark transition duration-300 lg:w-1/2 xl:w-1/3 lg:px-9 lg:py-32 ${
                    passwordResetScreenOpen ? 'translate-x-0' : 'translate-x-full'
                }`}>
                <AccountPasswordReset />
            </motion.div>

            <AccountOrderHistory />
        </AnimatePresence>
    );
};

export default MyAccount;
