import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as Tagline } from '../../images/gem-sleep-tagline.svg';
import posthog from 'posthog-js';

const ErrorPage = ({ theme }) => {
    posthog?.capture('Error Page', { url: window.location.pathname });
    return (
        <div
            className={`${
                theme === 'light' ? 'layout-outlet-root' : 'bg-purple-dark text-white'
            } min-h-screen relative`}>
            {theme !== 'light' && (
                <div className="flex flex-col justify-center items-center py-8 lg:py-16 scale-75 lg:scale-100 mb-10">
                    <Logo className="mb-5" />
                    <Tagline />
                </div>
            )}
            <div className="container space-y-5 text-center max-w-xl lg:max-w-4xl">
                <h2 className="text-4xl lg:text-8xl font-heading">Oops, Page not found.</h2>
                <p className="text-xl lg:text-2xl">
                    The page you are visiting does not exist. You might want to return to the
                </p>
                <Link
                    to={theme === 'light' ? '/dashboard' : '/'}
                    className="inline-block border-b-2 border-green-400 font-bold text-xl lg:text-2xl">
                    main page.
                </Link>
            </div>
        </div>
    );
};

export default ErrorPage;
