import { useEffect } from 'react';
import { Availability, ConsultList, AvailabilityMap } from '../../components/Admin/Consults';
import { useNavigate, useParams } from 'react-router-dom';

const Consults = () => {
    const navigate = useNavigate();
    const { consultTab } = useParams();

    useEffect(() => {
        if (!consultTab) {
            navigate('list', { replace: true });
        }
    }, [navigate, consultTab]);

    return (
        <>
            <h2 className="mb-5 pt-6 flex items-center justify-between">
                <span className="font-heading text-5xl">Consults</span>
            </h2>
            <nav className="my-4">
                <button
                    onClick={(ev) => {
                        navigate(`/admin/consults/list`, {
                            replace: true,
                        });
                    }}
                    className={`border-gray border-b py-2 min-w-[130px] ${consultTab === 'list' ? 'text-gem-green border-gem-green! font-bold' : 'hover:text-gem-green hover:font-bold'}`}>
                    Consult List
                </button>
                <button
                    onClick={(ev) => {
                        navigate(`/admin/consults/availability`, {
                            replace: true,
                        });
                    }}
                    className={`border-gray border-b py-2 min-w-[130px] ${consultTab === 'availability' ? 'text-gem-green border-gem-green! font-bold' : 'hover:text-gem-green hover:font-bold'}`}>
                    Availability
                </button>
                <button
                    onClick={(ev) => {
                        navigate(`/admin/consults/map`, {
                            replace: true,
                        });
                    }}
                    className={`border-gray border-b py-2 min-w-[130px] ${consultTab === 'map' ? 'text-gem-green border-gem-green! font-bold' : 'hover:text-gem-green hover:font-bold'}`}>
                    Map
                </button>
            </nav>
            {consultTab === 'list' && <ConsultList />}
            {consultTab === 'availability' && <Availability />}
            {consultTab === 'map' && <AvailabilityMap />}
        </>
    );
};

export default Consults;
