import { api } from './auth';

export const shopAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        syncNikoProducts: builder.query({
            query: () => ({
                url: '/shop/admin/niko/products/sync',
                method: 'GET',
            }),
        }),
        getNikoProducts: builder.query({
            query: () => ({
                url: '/shop/admin/niko/products',
                method: 'GET',
            }),
            providesTags: ['Products'],
        }),
        getAdminProducts: builder.query({
            query: () => ({
                url: '/shop/admin/products',
                method: 'GET',
            }),
            providesTags: ['Products'],
        }),
        getGroups: builder.query({
            query: () => ({
                url: '/shop/admin/products/groups',
                method: 'GET',
            }),
            providesTags: ['Groups'],
        }),
        getCategories: builder.query({
            query: () => ({
                url: '/shop/admin/products/categories',
                method: 'GET',
            }),
            providesTags: ['Categories'],
        }),
        getProduct: builder.query({
            query: (productId) => ({
                url: `/shop/admin/products/${productId}`,
                method: 'GET',
            }),
            providesTags: (result, error, arg) =>
                result ? [{ type: 'Product', id: arg?.productId }] : [],
        }),
        deleteProduct: builder.mutation({
            query: (productId) => ({
                url: `/shop/admin/products/${productId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Products'],
        }),
        updateProduct: builder.mutation({
            query: ({ productId, productData }) => ({
                url: `/shop/admin/products/${productId}`,
                method: 'PUT',
                body: productData,
            }),
            invalidatesTags: ['Products'],
        }),
        createProduct: builder.mutation({
            query: ({ productData }) => ({
                url: `/shop/admin/products`,
                method: 'POST',
                body: productData,
            }),
            invalidatesTags: ['Products'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useCreateProductMutation,
    useDeleteProductMutation,
    useGetAdminProductsQuery,
    useGetCategoriesQuery,
    useGetGroupsQuery,
    useGetNikoProductsQuery,
    useGetProductQuery,
    useSyncNikoProductsQuery,
    useUpdateProductMutation,
} = shopAPI;
