import { motion } from 'framer-motion';
import { overlayVariants } from '../../utils/constants';
import { useCheckoutContext } from '../Checkout/CheckoutContext';
import { useInsuranceContext } from '../Insurance/InsuranceContext';
import { useSelector } from 'react-redux';
import { useUpdatePatientInsuranceMutation } from '../../app/services/insurance';

const CoveredBenefitCheck = () => {
    //Queries
    const [updatePatientCurrentInsurance] = useUpdatePatientInsuranceMutation();

    //Context
    const { setCheckoutStep } = useCheckoutContext();
    const { insurance } = useInsuranceContext();

    //Redux state values
    const { user } = useSelector((state) => state.auth);

    //User who no longer is connected with their insurance alternative
    const setCoveredBenefitNo = async () => {
        // void insurance alternative
        await updatePatientCurrentInsurance({
            userId: user.id,
            insuranceId: insurance?.id,
            data: {
                insuranceStatus: 'Inactive',
            },
        }).then(() => {
            //Direct the user to insurance review where they can determine if they want to add new insurance or not
            setCheckoutStep('check-coverage');
        });
    };

    return (
        <motion.div initial="hidden" animate="visible" exit="hidden" variants={overlayVariants}>
            <h5 className="text-center mb-5 font-heading text-4xl font-bold lg:text-5xl">
                Are you still associated with {insurance?.payer?.name}?
            </h5>
            <ul className="flex items-center space-x-4 lg:flex-col lg:space-x-0 lg:space-y-4">
                <li className="w-full">
                    <button
                        onClick={() => {
                            setCheckoutStep('insurance-alternative');
                        }}
                        className="btn-shell block w-full">
                        Yes
                    </button>
                </li>
                <li className="w-full">
                    <button
                        onClick={() => {
                            setCoveredBenefitNo();
                        }}
                        className="btn-shell block w-full">
                        No
                    </button>
                </li>
            </ul>
        </motion.div>
    );
};

export default CoveredBenefitCheck;
