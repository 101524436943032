import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useApplyDiscountToCartMutation, useGetCartQuery } from '../../app/services/cart';
import { CustomInput } from '../InputComponents';
import { AnimatePresence, motion } from 'framer-motion';

const DiscountForm = () => {
    const [discountState, setDiscountState] = useState(null);
    const [applyDiscount] = useApplyDiscountToCartMutation();
    const { data: cart = { orderItems: [] } } = useGetCartQuery();

    const submitDiscountCodeHandler = async (data, { resetForm }) => {
        applyDiscount(data.code.trim().toUpperCase())
            .unwrap()
            .then((res) => {
                resetForm();
                return setDiscountState({ type: 'valid', message: 'Discount applied!' });
            })
            .catch((error) => {
                setDiscountState({ type: 'invalid', message: error.data.message });
            });
    };

    useEffect(() => {
        if (cart.discountCode) {
            setDiscountState({ type: 'valid', message: 'Discount applied!' });
        } else {
            setDiscountState(null);
        }
    }, [cart.discountCode]);

    const onChangeHandler = (ev) => {
        if (ev.target.value === '') setDiscountState(null);
    };

    return (
        <>
            {!cart.discountCode && (
                <Formik
                    initialValues={{
                        code: '', //cartState.discountCode ||
                    }}
                    onSubmit={submitDiscountCodeHandler}>
                    {() => (
                        <Form
                            className="mb-4 flex items-center space-x-6"
                            onChange={onChangeHandler}>
                            <CustomInput
                                theme="light"
                                name="code"
                                label="Discount code"
                                className="border-b border-b-gray-dark lg:text-lg"
                                rootClass="w-full"
                            />
                            <button className="text-sm font-bold text-green-400" type="submit">
                                Apply
                            </button>
                        </Form>
                    )}
                </Formik>
            )}

            <AnimatePresence>
                {discountState && (
                    <motion.p
                        initial={{ opacity: 0, x: '50%' }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: '50%' }}
                        className={`${
                            discountState.type === 'valid'
                                ? 'bg-gem-green/20 text-green-hover'
                                : 'bg-red/10 text-red'
                        } p-4 py-2 text-center text-sm font-bold`}>
                        {discountState.message}
                    </motion.p>
                )}
            </AnimatePresence>

            {cart.discountCode && (
                <motion.button
                    initial={{ opacity: 0, x: '50%' }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: '50%' }}
                    className="w-full flex justify-center text-sm font-bold text-green-400 py-2"
                    onClick={() => applyDiscount(null)}>
                    Remove Discount
                </motion.button>
            )}
        </>
    );
};

export default DiscountForm;
