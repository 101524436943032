import { IoCloseSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import {
    Link,
    Navigate,
    Outlet,
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { isWeekend } from 'date-fns';

import { Footer } from '../LayoutComponents';
import { useLogoutMutation } from '../../app/services/auth';
import useWebWidget from '../../utils/useWebWidget';
import { setPartner } from '../../features/assessment/assessmentSlice';
import './UnauthenticatedLayout.scss';

//Images
import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as Tagline } from '../../images/gem-sleep-tagline.svg';

const LogoLink = ({ pathname, children }) =>
    pathname === '/' ? (
        <a href="https://mygemsleep.com/" target="_blank" rel="noreferrer">
            {children}
        </a>
    ) : (
        <Link to="/">{children}</Link>
    );

const UnauthenticatedLayout = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [logout] = useLogoutMutation();
    const { user, isMD, isLoggedIn } = useSelector((state) => state.auth);

    const redirectErrorMessage = searchParams.get('error');
    const showHelpFooter = location.pathname === '/study-upload';

    //Capture and set the partner ID based on PARAM or URL
    const { partnerId } = useParams();
    const isSleepSupport = window.location.host.includes('sleepsupport');

    if (isSleepSupport) {
        dispatch(
            setPartner({
                id: +process.env.REACT_APP_SLEEP_SUPPORT_PARTNER_ID,
                type: 'SLEEP_SUPPORT',
            }),
        );
    } else if (partnerId) {
        dispatch(setPartner({ id: +partnerId, type: 'OTHER' }));
    }

    useWebWidget(!isWeekend(new Date()));

    return redirectErrorMessage ? (
        <Navigate to="/sign-in" state={{ withError: redirectErrorMessage }} />
    ) : (
        <div className="UnauthenticatedLayout relative flex min-h-screen flex-col overflow-hidden bg-purple-dark text-white">
            {(!user || !isLoggedIn) && location.pathname === '/' && (
                <div className="sign-in-container">
                    <p className="text-sm text-gray-light">
                        Already have an account?{' '}
                        <Link
                            to="/sign-in"
                            className="btn-sign-in border-b border-gem-green text-sm font-bold text-white">
                            Sign In
                        </Link>
                    </p>
                </div>
            )}
            {(!user || !isLoggedIn) && location.pathname === '/sign-in' && (
                <div className="sign-in-container">
                    <p className="text-sm text-gray-light">
                        New to GEM SLEEP?{' '}
                        <Link
                            to="/"
                            className="btn-sign-in border-b border-gem-green text-sm font-bold text-white">
                            Get Started
                        </Link>
                    </p>
                </div>
            )}
            {user &&
                isLoggedIn &&
                location.pathname === '/' &&
                (isMD ? (
                    <Navigate to="/admin" replace={true} />
                ) : (
                    <Navigate to="/dashboard" replace={true} />
                ))}
            {location.pathname.indexOf('assessment') < 0 && (
                <div className="container relative max-w-5xl py-8 xl:py-0">
                    <LogoLink pathname={location.pathname}>
                        <Logo className="mx-auto w-[50px] lg:w-auto" />
                        <Tagline className="mx-auto mt-2 w-32 lg:mt-4 lg:w-auto" />
                    </LogoLink>
                    {(user || location.pathname === '/study-upload') && isLoggedIn && (
                        <button
                            className="group absolute right-0 top-1/2 flex -translate-y-1/2 items-center font-heading leading-none tracking-widest text-offwhite"
                            onClick={async () => {
                                await logout(null);
                                navigate('/');
                            }}>
                            <span className="translate-y-[1px] opacity-50 transition group-hover:-translate-x-2 group-hover:opacity-100">
                                logout
                            </span>
                            <IoCloseSharp className="text-3xl" />
                        </button>
                    )}
                </div>
            )}

            <div className="flex-1 mb-4">
                <Outlet />
            </div>

            <footer className="bg-purple-super-dark">
                {showHelpFooter && (
                    <div className="bg-black py-20 text-center text-white">
                        <p className="mb-4 text-[13px] font-semibold lg:text-base">
                            Have a question? Call us.
                        </p>
                        <h2 className="mb-1 font-heading text-6xl text-purple-fair">
                            <a href="tel:+18339464436" className="border-none">
                                1-833-946-4GEM
                            </a>
                        </h2>
                        <p className="text-[13px] text-gray-light lg:text-base">(1-833-946-4436)</p>
                    </div>
                )}
                <Footer />
            </footer>
        </div>
    );
};

export default UnauthenticatedLayout;
