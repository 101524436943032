import { motion } from 'framer-motion';
import { useState } from 'react';
import ProductAccordionItem from '../AccordionItem/ProductAccordionItem';
import { AccordionItem } from '..';

const Accordion = ({ items, className, theme, type = 'default' }) => {
	const [expandedIndex, setExpandedIndex] = useState(null);

	return (
		<motion.ul className={`divide-y divide-gray-light border-b border-b-gray-light ${className}`}>
			{items?.map((item, idx) =>
				type === 'product' ? (
					<ProductAccordionItem
						key={`accordion-item-${idx}`}
						title={item.title}
						isExpanded={idx === expandedIndex}
						theme={theme}
						toggle={() => {
							setExpandedIndex((old) => (old === idx ? null : idx));
						}}>
						{item.content}
					</ProductAccordionItem>
				) : (
					<AccordionItem
						key={`accordion-item-${idx}`}
						title={item.title}
						isExpanded={idx === expandedIndex}
						theme={theme}
						toggle={() => {
							setExpandedIndex((old) => (old === idx ? null : idx));
						}}>
						{item.content}
					</AccordionItem>
				)
			)}
		</motion.ul>
	);
};

export default Accordion;
