import { motion } from 'framer-motion';

const UploadSpinner = ({className, progress}) => {
    return (
        <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="102"
        height="102" 
      >
        <circle
            cx="51"
            cy="51"
            r="50"
            stroke="#dcdee2"
            strokeWidth="2"
            fill="transparent"
        />
        <motion.path
            className="stroke-green-400"
            d="M3,51a48,48 0 1,0 96,0a48,48 0 1,0 -96,0"
            strokeWidth="4"
            fill="transparent"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: progress.toFixed(1) / 100 }}
            style={{ rotate: 90, scaleX: -1 }}
            transition={{ duration: 1.5, ease: [0.22, 1, 0.36, 1] }}
        />
    </svg>
    )
}
export default UploadSpinner