import { api } from '../auth';

const documentsAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        changeDocumentStatus: builder.mutation({
            query: (params) => ({
                url: `/files/${params.fileId}/status/?storageToken=${params.storageToken}`,
                method: 'PUT',
                body: { status: params.status },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Files', id: arg?.patientId },
                { type: 'User', id: arg?.patientId },
            ],
        }),
        getPatientFiles: builder.query({
            query: (patientId) => ({
                url: `/files/patient/${patientId}`,
            }),
            providesTags: (result, error, arg) => {
                return [{ type: 'Files', id: arg }];
            },
        }),
        updateDocumentFileType: builder.mutation({
            query: (params) => ({
                url: `/files/${params.fileId}/type/?storageToken=${params.storageToken}`,
                method: 'PUT',
                body: { fileType: params.fileType },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Files', id: arg?.patientId },
                { type: 'User', id: arg?.patientId },
            ],
        }),
        updateDocumentNotes: builder.mutation({
            query: (params) => ({
                url: `/files/${params.fileId}/notes/?storageToken=${params.storageToken}`,
                method: 'PUT',
                body: { notes: params.notes },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Files', id: arg?.patientId },
                { type: 'User', id: arg?.patientId },
            ],
        }),
    }),
    overrideExisting: false,
});

export const {
    useChangeDocumentStatusMutation,
    useGetPatientFilesQuery,
    useUpdateDocumentFileTypeMutation,
    useUpdateDocumentNotesMutation,
} = documentsAPI;
