import { useEnrollInResupplyMutation } from '../../../app/services/orders';
import PatientResupplyListItem from './TabComponents/PatientResupplyListItem';

function PatientResupply({ patient }) {
    const [enroll] = useEnrollInResupplyMutation();

    return (
        <div className="space-y-4">
            <h2 className="font-heading text-3xl lg:text-5xl">Resupply Program</h2>
            {!patient?.resupply.some((resupply) => resupply.enrollmentStatus === 'ENROLLED') && (
                <>
                    <p className="font-bold font-heading tracking-widest text-red">
                        Patient currently not enrolled in resupply program
                    </p>
                    <button
                        className="btn-primary-small"
                        onClick={() => {
                            enroll({ id: patient?.id });
                        }}>
                        Enroll in Resupply
                    </button>
                </>
            )}
            {patient?.resupply.map((resupply) => (
                <PatientResupplyListItem
                    key={'patient-resupply-list-item-' + resupply.id}
                    resupply={resupply}
                />
            ))}
        </div>
    );
}

export default PatientResupply;
