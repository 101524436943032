import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { setAdminSliceValue, setStatusFilter } from '../../../features/admin/adminSlice';
import { adminSelectCustomStyles } from '../../../utils/constants';

const OrderFilter = ({
    id,
    index,
    status,
    setPage,
    previousStatus,
    filters,
    orderFilterComponents,
}) => {
    const dispatch = useDispatch();
    const { availableOrderFilters } = useSelector((state) => state.admin);

    //Reused code, so created as its own function
    const clearSearch = (statusKey) => {
        dispatch(
            setStatusFilter({
                type: 'activeOrderFilters',
                key: statusKey,
                value: null,
            }),
        );
    };

    function handleStatusChange(selectedOption) {
        //Remove status from pool of available statuses to filter for other filters
        const newAvailableFilters = availableOrderFilters.filter((filter) => {
            if (filter !== selectedOption && filters !== previousStatus) {
                return filter;
            } else return null;
        });

        if (previousStatus?.value) {
            //Set previous status to again be available in filter status options
            newAvailableFilters.push({ ...previousStatus, available: true });

            //If a filter exists, set to initial page
            if (filters.length > 0) {
                setPage(0);
            }

            //Clear out the searches that might have existed from the previous status
            clearSearch(previousStatus?.value);
        }

        //Change the selected option to not be available in the filter status options
        newAvailableFilters.push({ ...selectedOption, available: false });
        dispatch(setAdminSliceValue({ key: 'availableOrderFilters', value: newAvailableFilters }));

        //Replace the current filter with one with updated status
        const newFilters = orderFilterComponents.filter(
            (statusFilter) => statusFilter.filterId !== id,
        );
        newFilters.splice(index, 0, {
            filterId: id,
            status: { ...selectedOption, available: false },
            previousStatus: selectedOption,
            filters: [],
        });

        dispatch(setAdminSliceValue({ key: 'orderFilterComponents', value: newFilters }));
    }

    function handleFilterChange(selectedOption) {
        setPage(0);

        let filterValue;
        if (Array.isArray(selectedOption)) {
            filterValue =
                selectedOption?.length === 0
                    ? null
                    : selectedOption?.length === 1
                      ? selectedOption[0].value === null
                          ? 'null'
                          : selectedOption[0].value
                      : { in: selectedOption.flatMap((filter) => filter.value) };
        } else {
            filterValue = selectedOption.value;
        }

        dispatch(
            setStatusFilter({
                type: 'activeOrderFilters',
                key: status.value,
                value: filterValue,
            }),
        );

        //Replace the current filter with one with updated filters
        const newFilters = orderFilterComponents.filter(
            (statusFilter) => statusFilter.filterId !== id,
        );
        newFilters.splice(index, 0, {
            filterId: id,
            status: status,
            previousStatus: status,
            filters: selectedOption,
        });
        dispatch(setAdminSliceValue({ key: 'orderFilterComponents', value: newFilters }));
    }

    return (
        <>
            <Select
                options={availableOrderFilters.filter((filter) => filter.available)}
                className="basis-[94%] md:basis-[47%] md:pr-1"
                onChange={handleStatusChange}
                placeholder={'Select Status'}
                styles={adminSelectCustomStyles}
                value={status.value ? status : null}
            />
            <Select
                isDisabled={!status.value}
                options={status.statusOptions}
                className="basis-[94%] max-w-[94%] md:max-w-[47%] my-2 md:my-0 md:basis-[47%] md:pl-1"
                onChange={handleFilterChange}
                isMulti={status.multiple}
                placeholder={!status.value ? '' : 'Select options'}
                styles={adminSelectCustomStyles}
                value={filters}
            />
        </>
    );
};

export default OrderFilter;
