import { useEffect, useState } from 'react';
import { Form, Formik, Field, FieldArray } from 'formik';
import Modal from '../../../components/Modal/Modal';
import { FaCheck } from 'react-icons/fa';
import { ReactComponent as RemoveIcon } from '../../../images/trash-2.svg';
import './shop.scss';
import { UploadProductImages } from '../../../components';
import {
  useUpdateProductMutation,
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetNikoProductsQuery,
  useGetGroupsQuery,
  useGetCategoriesQuery,
} from '../../../app/services/shop';

const ProductEditForm = ({ variantTypes, product, closeHandler, active, submitHandler }) => {
  const [updateProduct, { isSuccess: isUpdateSuccess }] = useUpdateProductMutation();
  const [createProduct, { isSuccess: isCreateSuccess }] = useCreateProductMutation();
  const [deleteProduct, { isSuccess: deleteSuccess }] = useDeleteProductMutation();

  const [nikoProductsSorted, setNikoProductsSorted] = useState([]);

  const [showSaveFeedback, setShowSaveFeedback] = useState(false);
  const [feedbackTimer, setFeedbackTimer] = useState(null);

  const { data: groups } = useGetGroupsQuery();
  const { data: categories } = useGetCategoriesQuery();

  const { data } = useGetNikoProductsQuery();

  const { products: nikoProducts } = data || {};

  const variantTemplate = {
    id: undefined,
    name: '',
    type: 'Default',
    price: '',
    sku: '',
    featuredImageUrl: '',
    refId: '',
    description: '',
    productId: product?.id,
  }

  useEffect(() => {
    if (deleteSuccess) {
      closeHandler();
    }
  }, [deleteSuccess, closeHandler])

  useEffect(() => {
    if (nikoProducts) {
      const p = [...nikoProducts];
      const sorted = p.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        return 1;
      });

      setNikoProductsSorted(sorted);
    }
  }, [nikoProducts, setNikoProductsSorted]);

  useEffect(() => {
    clearTimeout(feedbackTimer);
    if (isUpdateSuccess || isCreateSuccess) {
      setShowSaveFeedback(true);
      setFeedbackTimer(
        setTimeout(() => {
          setShowSaveFeedback(false);
        }, 2000)
      );
    }
  }, [isUpdateSuccess, isCreateSuccess, feedbackTimer]);

  return (
    <Modal
      showClose={true}
      isOpen={active}
      closeHandler={() => {
        closeHandler();
      }}
      closeOnOutsideClick={false}>
      <div className="product-edit-modal">
        <header className="product-modal-header">
          {!product ? <h1 className="font-heading text-2xl">Create New Product</h1> : <h1 className="font-heading text-2xl">Editing <span className="text-gray">{product?.name}</span></h1>}
          <div>
            {product && <button className='btn btn-secondary text-sm delete-product-btn' onClick={() => {
              deleteProduct(product.id);
            }}>Delete Product <RemoveIcon /></button>}
          </div>
        </header>
        <section>
          <Formik
            initialValues={{
              description: product?.description || '',
              name: product?.name || '',
              miscInfo: product?.miscInfo || '',
              lifeSpan: product?.lifeSpan || 0,
              requiresPrescription: product?.requiresPrescription,
              reorderEnabled: product?.reorderEnabled,
              availability: product?.availability,
              groupId: product?.groupId,
              categoryId: product?.categoryId,
              productGuideLinks: product?.productGuideLinks,
              variants: product?.variants,
              images: product?.images,
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validate={values => {
              const errors = {};
              if (!values.name) {
                errors.name = 'Required';
              }
              if (!values.description) {
                errors.description = 'Required';
              }
              if (!values.variants?.length) {
                errors.variants = 'You must add at least one variant. If this product only references one variant the variant type should be set to default.';
              }

              return errors;

            }}
            onSubmit={async (values) => {

              if (product && product.id) {
                await updateProduct({ productId: product.id, productData: values });
              } else {
                await createProduct({ productData: values });
              }

              return false;
            }}
            enableReinitialize={true}>

            {({ values, touched, errors, handleChange, handleBlur, isValid }) => (
              <Form>
                <div className="product-edit-fields">
                  <div className="border-b border-gray pb-4">

                  </div>
                  <div>
                    <label htmlFor="productName">Product Name</label>
                    <Field
                      className="input-field"
                      id="productName"
                      name="name"
                    />
                  </div>
                  <div>
                    <label htmlFor="gemDescription">Description</label>
                    <Field as="textarea"
                      className="input-field"
                      id="gemDescription"
                      name="description"

                    />
                  </div>
                  <div>
                    <label htmlFor="miscInfo">Misc Info</label>
                    <Field as="textarea"
                      className="input-field"
                      id="miscInfo"
                      name="miscInfo"
                    />
                  </div>
                  <div>
                    <label htmlFor="categoryId">Category</label>
                    <Field as="select"
                      className="input-field"
                      id="categoryId"
                      name="categoryId"
                    >
                      <option value="">Select a Category</option>
                      {categories && categories.map((category, index) => (<option key={`category-${index}`} value={category.nikoId}>{category.name}</option>))}
                    </Field>
                  </div>
                  <div>
                    <label htmlFor="groupId">Group</label>
                    <Field as="select"
                      className="input-field"
                      id="groupId"
                      name="groupId"
                    >
                      <option value="">Select a Group</option>
                      {groups && groups.map((group, index) => (<option key={`group-${index}`} value={group.nikoId}>{group.name}</option>))}
                    </Field>
                  </div>
                  <div className="form-row flex">
                    <div className="w-1/2 pr-2">
                      <label htmlFor="lifeSpan">Life Span</label>
                      <Field type="number"
                        className="input-field"
                        id="lifeSpan"
                        name="lifeSpan" />
                    </div>
                    <div className="w-1/2 pl-2">
                      <label htmlFor="availability">Availability</label>
                      <div className="relative">
                        <Field
                          component="select"
                          name="availability"
                          className="input-field"
                        >
                          <option value="IN_STOCK">In Stock</option>
                          <option value="OUT_OF_STOCK">Out of Stock</option>
                          <option value="BACK_ORDER">Back Order</option>
                          <option value="NOT_AVAILABLE">Not Available</option>
                        </Field>
                      </div>
                    </div>

                  </div>

                  <div className="form-row flex">
                    <div className="w-1/2 pr-2">
                      <label htmlFor="reorderEnabled">Reorder Enabled</label>
                      <div>
                        <div className="relative checkbox-container">
                          <Field
                            type="checkbox"
                            id="reorderEnabled"
                            name="reorderEnabled"
                            className="appearance-none flex-shrink-0 h-6 w-6 border-4 bg-transparent border-green-400 checked:bg-green-600 checked:border-green-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                          />
                          <FaCheck className="transition absolute-center w-2 opacity-0 scale-150 pointer-events-none text-white" />
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2 pl-2">
                      <label htmlFor="requiresPrescription">Requires Prescription</label>
                      <div className="relative checkbox-container">
                        <Field
                          type="checkbox"
                          name="requiresPrescription"
                          className="appearance-none flex-shrink-0 h-6 w-6 border-4 bg-transparent border-green-400 checked:bg-green-600 checked:border-green-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                        />
                        <FaCheck className="transition absolute-center w-2 opacity-0 scale-150 pointer-events-none text-white" />
                      </div>
                    </div>
                  </div>
                  <div className="product-guide-links">
                    <FieldArray name="productGuideLinks">
                      {({ push, remove, replace }) => (
                        <div>
                          <header>
                            <h4 className="font-heading text-xl">Product Guide Links</h4>
                            <button type="button" className="btn btn-primary text-xs p-3"
                              onClick={() => {
                                push('');
                              }}>Add Product Guide Link</button>
                          </header>
                          <ul className="guide-link-list">
                            {values.productGuideLinks?.map((link, index) => {
                              return (<li key={`guide-link-${index}`} className="guide-link">
                                <Field className="input-field guide-link" name={`productGuideLinks[${index}]`} id={`guide-link-${index}`} />
                                <button className="delete-guide-link"
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >X</button>
                              </li>)
                            })}
                          </ul>
                        </div>
                      )}
                    </FieldArray>

                  </div>
                  <div className="product-variants">
                    <FieldArray name="variants">
                      {({ push, remove, replace }) => (
                        <div>
                          <header className={errors.variants ? `variants-error` : ''}>
                            <div>
                              <h4 className="font-heading text-xl">Product Variants</h4>
                              {errors.variants && <p className="text-red text-xs">{errors.variants}</p>}
                            </div>
                            <button type="button" className="btn btn-primary text-xs p-3"
                              onClick={() => {
                                push(variantTemplate);
                              }}>Add Variant</button>
                          </header>


                          <ul className="variants-list">
                            {values.variants?.map((variant, index) => {
                              return (
                                <li key={`variant-${index}`} className="variant">
                                  <Field type="hidden" name={`variants[${index}].id`} />
                                  <div className="remove-variant-btn-container">
                                    <button type="button" className="remove-btn text-xs text-white py-1 px-3"
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    ><RemoveIcon /></button>
                                  </div>
                                  <div>
                                    <label htmlFor={`variant-${index}.refId`}>Referenced Product</label>
                                    <Field
                                      component="select"
                                      id={`variant-${index}.refId`}
                                      name={`variants[${index}].refId`}
                                      className="input-field"
                                      onChange={(e) => {

                                        // get the complete reference product 
                                        const newVariantProduct = nikoProducts?.find(p => p.nikoId === e.target.value)

                                        //populate the variant with the reference product's data
                                        const newVariant = { ...variantTemplate }
                                        newVariant.refId = newVariantProduct.nikoId;
                                        newVariant.sku = newVariantProduct.sku;
                                        newVariant.description = newVariantProduct.description;
                                        newVariant.featuredImageUrl = newVariantProduct.featuredImageUrl;
                                        newVariant.price = newVariantProduct.purchasePrice;

                                        replace(index, newVariant);

                                      }}
                                    >
                                      <option key={`product-null`} value={null}>Select a Product</option>
                                      {nikoProductsSorted && nikoProductsSorted.map((prod, index) => (
                                        <option key={`product-${prod.id}`} value={prod.nikoId}>{prod.name}</option>
                                      ))}
                                    </Field>
                                  </div>
                                  <div>
                                    <label htmlFor={`variant-${index}-type`}>Type</label>
                                    <div className="relative">
                                      <Field
                                        component="select"
                                        id={`variant-${index}-type`}
                                        name={`variants[${index}].type`}
                                        className="input-field"
                                      >
                                        {variantTypes && variantTypes.map((type, index) => (<option value={type} index={`variant-type-${index}`} key={`variant-type-${index}`}>{type}</option>))}
                                      </Field>
                                    </div>

                                  </div>


                                  {variant.featuredImageUrl && <div>
                                    <div className="variant-image-preview">
                                      <label>Variant Image</label>
                                      <img className="preview-img" src={variant.featuredImageUrl} alt="" />

                                      <Field type="hidden" name={`variants[${index}].featuredImageUrl`} />
                                    </div>
                                  </div>}


                                  <div>
                                    <label htmlFor={`variant-${index}-name`}>Name</label>
                                    <Field className="input-field" name={`variants[${index}].name`} id={`variant-${index}-name`} />
                                  </div>
                                  <div>
                                    <label htmlFor={`variant-${index}-description`}>Description</label>
                                    <Field className="input-field" name={`variants[${index}].description`} id={`variant-${index}-description`} />
                                  </div>
                                  <div>
                                    <label htmlFor={`variant-${index}-sku`}>Sku</label>
                                    <Field readOnly disabled className="input-field readonly" value={variant.sku} name={`variants[${index}].sku`} id={`variant-${index}-sku`} />
                                  </div>
                                  <div>
                                    <label htmlFor={`variant-${index}-price`}>Price</label>
                                    <Field readOnly disabled className="input-field readonly" name={`variants[${index}].price`} id={`variant-${index}-price`} />
                                  </div>
                                </li>
                              )
                            })}

                          </ul>
                        </div>)}
                    </FieldArray>

                  </div>
                  <div className="product-images">
                    <header className="flex justify-between">
                      <h4 className="font-heading text-xl">Product Images</h4>
                    </header>
                    <FieldArray name="images">
                      {({ push, remove, replace }) => (
                        <div>
                          <div className="pb-4">
                            <UploadProductImages theme="slim" multipleUploads={true} uploadSuccessCallback={(uploadedImages => {
                              uploadedImages.forEach(imageUrl => {
                                push({
                                  url: imageUrl,
                                  altText: '',
                                })
                              })
                            })} isPublic={true} />
                          </div>

                          <ul className="product-images-list">
                            {values.images?.map((image, index) => {
                              return (<li key={`image-${index}`} className="product-image">
                                <div className="product-image-preview-container">
                                  <img src={`${image.url}`} alt={`${image.altText}`} className="product-image-preview" />
                                </div>
                                <div className="product-image-details">
                                  <label htmlFor={`image-${index}-url`}>URL</label>
                                  <Field readOnly disabled id={`image-${index}-url`} name={`images[${index}].url`} className="input-field readonly" />

                                  <label htmlFor={`image-${index}-altText`}>Alt Text</label>
                                  <Field id={`image-${index}-altText`} name={`images[${index}].altText`} className="input-field" />
                                </div>
                                <div className="product-image-remove">
                                  <button className="remove-btn" type="button"
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  ><RemoveIcon /></button>
                                </div>
                              </li>)
                            })}
                          </ul>
                        </div>
                      )}
                    </FieldArray>
                    {(!values.images || values.images?.length === 0) && (
                      <div className="product-images-empty py-2">
                        <p className="text-sm text-gray">No additional images found for this product</p>
                      </div>
                    )}

                  </div>
                  <div className="product-save-feedback">
                    {showSaveFeedback && <p className="text-gem-green font-bold text-center text-xl2">Product saved successfully</p>}
                  </div>
                  <button className="btn-primary save-product-btn" type="submit">Save product</button>
                </div>
              </Form>


            )}


          </Formik>
        </section>
      </div>
    </Modal>
  )
}

export default ProductEditForm;