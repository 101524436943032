import { motion } from 'framer-motion';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useUpdatePatientInsuranceMutation } from '../../app/services/insurance';
import { overlayVariants } from '../../utils/constants';
import { FileUploader } from '../UploadFile/FileUploader';
import FileSelector from '../UploadFile/FileSelector';
import { useInsuranceContext } from './InsuranceContext';
import { ReactComponent as CheckmarkIcon } from '../../images/icons/checkmark.svg';

// import axios from 'axios';

// async function getCardImage(id, storageToken) {
//     const url = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : '';
//     const response = await axios.get(
//         `${url}/files/${id}/url?storageToken=${storageToken}`,
//     );

//     return response.data.url
// }

function UploadCardComponent({ onSuccess }) {
    const [frontOfCard, setFrontOfCard] = useState(undefined);
    const [backOfCard, setBackOfCard] = useState(undefined);
    const [isUploading, setIsUploading] = useState(false);
    const { user, storageToken } = useSelector((state) => state.auth);
    const { insurance } = useInsuranceContext();

    const [updatePatientInsurance] = useUpdatePatientInsuranceMutation();

    async function handleSubmit() {
        const url = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : '';
        try {
            const cardImageFrontId = frontOfCard
                ? await new FileUploader(
                      frontOfCard.file,
                      `${url}/files/${user.id}/INSURANCE_CARD_FRONT/${storageToken}`,
                  ).uploadFile()
                : insurance?.cardImageFrontId
                  ? insurance?.cardImageFrontId
                  : null;

            const cardImageBackId = backOfCard
                ? await new FileUploader(
                      backOfCard.file,
                      `${url}/files/${user.id}/INSURANCE_CARD_BACK/${storageToken}`,
                  ).uploadFile()
                : insurance?.cardImageBackId
                  ? insurance?.cardImageBackId
                  : null;

            toast.success('Your Card Has Been Uploaded');
            // associate cards with image ids
            updatePatientInsurance({
                userId: user.id,
                insuranceId: insurance.id,
                data: {
                    cardImageFrontId,
                    cardImageBackId,
                },
            });
            onSuccess();
        } catch (err) {
            toast.error('There was an error uploading your card');
        }
    }

    function handleFrontSelect(files) {
        if (!files || files.length === 0) return;
        setFrontOfCard(files[0]);
    }

    function handleBackSelect(files) {
        if (!files || files.length === 0) return;
        setBackOfCard(files[0]);
    }

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={overlayVariants}
            className="border-8 border-purple-medium border-opacity-20 px-8 py-6 lg:p-14 flex flex-wrap">
            <div className="w-full max-w-full overflow-hidden sm:basis-full lg:basis-[calc(50%-20px)] lg:m-2.5">
                <p className="font-heading text-xl p-1">FRONT of Card</p>
                {!insurance?.cardImageFrontId ? (
                    <FileSelector
                        key="frontOfCardSelector"
                        multipleUploads={false}
                        maximumFiles={1}
                        handleFileSelect={handleFrontSelect}
                        id="front"
                    />
                ) : (
                    <div className="relative border border-dashed border-black bg-white flex flex-col justify-center items-center xs:min-h-[164px] lg:h-[360px]">
                        <CheckmarkIcon className="text-[#00AA63] scale-[2] xs:scale-[3] h-[30px]" />
                        <p className="text-sm xs:text-base text-center xs:pt-4">
                            Insurance Card Front Already Uploaded
                        </p>
                    </div>
                )}
            </div>
            <div className="w-full max-w-full overflow-hidden sm:basis-full lg:basis-[calc(50%-20px)] lg:m-2.5">
                <p className="font-heading text-xl p-1">BACK of Card</p>
                {!insurance?.cardImageBackId ? (
                    <FileSelector
                        key="backOfCardSelector"
                        multipleUploads={false}
                        maximumFiles={1}
                        handleFileSelect={handleBackSelect}
                        id="back"
                    />
                ) : (
                    <div className="relative border border-dashed border-black bg-white flex flex-col justify-center items-center xs:min-h-[164px] lg:h-[360px]">
                        <CheckmarkIcon className="text-[#00AA63] scale-[3] h-[30px]" />
                        <p className="text-sm xs:text-base">Insurance Card Back Already Uploaded</p>
                    </div>
                )}
            </div>
            <button
                aria-label="Upload files"
                type="button"
                onClick={() => {
                    setIsUploading(true);
                    handleSubmit();
                }}
                disabled={!(frontOfCard || backOfCard) || isUploading}
                className="btn-primary w-full lg:mx-2 mt-2">
                Upload
            </button>
        </motion.div>
    );
}

export default UploadCardComponent;
