import {
    useAddOrderItemMutation,
    useGetPatientCartQuery,
} from '../../../../app/services/admin/orders';
import {
    CartInformation,
    OrderAppointments,
    OrderItems,
    SelectItemFromCatalog,
} from '../../Orders';
import { useSelector } from 'react-redux';
import { adminItemsSelector } from '../../../../features/catalog/catalogSlice';

function PatientCart({ patientId }) {
    const { data: cart } = useGetPatientCartQuery(patientId);
    const [addOrderItem] = useAddOrderItemMutation();

    const addItemOptions = useSelector(adminItemsSelector);

    return (
        <div>
            <hr className="my-2" />
            <h2 className="ml-2 font-heading text-xl lg:text-3xl">General Information</h2>
            <CartInformation cart={cart} />
            <hr className="my-2" />
            <h2 className="ml-2 font-heading text-xl lg:text-3xl">Order Items</h2>
            <OrderItems
                key={`order-items.component-${cart?.id}`}
                order={cart}
                patientId={patientId}
            />
            <SelectItemFromCatalog
                key={`add-order-items.component-${cart?.id}`}
                includeQuantity={true}
                itemOptions={addItemOptions}
                label={'Add Item'}
                successToast="Item Added to Order"
                onSubmit={(values) =>
                    addOrderItem({
                        orderId: cart.id,
                        userId: patientId,
                        values: values,
                    })
                }
            />
            <hr className="my-2" />
            <h2 className="ml-2 font-heading text-xl lg:text-3xl mb-4">Appointments</h2>
            <OrderAppointments order={cart} />
        </div>
    );
}

export default PatientCart;
