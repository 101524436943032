import { useDispatch } from 'react-redux';
import { toggleModal } from '../../features/ui/uiSlice';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { UnauthenticatedModals } from '../Modals';

const Footer = () => {
    const dispatch = useDispatch();
    return (
        <>
            <div className="container flex space-x-4 text-xs text-white/60 py-6 lg:items-center lg:justify-between ">
                <div className="flex items-center">
                    <div className="relative rounded-md bg-white/10 w-6 h-6">
                        <Logo className="absolute-center max-w-[15px]" />
                    </div>

                    <span className="ml-3 tracking-widest">
                        V{process.env.REACT_APP_CURRENT_VERSION}
                    </span>
                </div>
                <ul className="flex items-center space-x-5 lg:space-x-6">
                    <li>
                        <button className="py-4" onClick={(ev) => dispatch(toggleModal('contact'))}>
                            Contact Us
                        </button>
                    </li>
                    <li>
                        <button className="py-4" onClick={(ev) => dispatch(toggleModal('privacy'))}>
                            Privacy
                        </button>
                    </li>
                    <li>
                        <button className="py-4" onClick={(ev) => dispatch(toggleModal('terms'))}>
                            Terms
                        </button>
                    </li>
                </ul>
            </div>
            <UnauthenticatedModals />
        </>
    );
};

export default Footer;
