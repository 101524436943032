import { formatOptionValue } from '../../utils/constants';
import { ReactComponent as CheckmarkIcon } from '../../images/icons/checkmark.svg';
import { getMaskSystemName } from './MaskOptionBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../features/ui/uiSlice';

function RecommendedOption({
    cpapMachineName,
    getItemOptionDataById,
    maskData,
    recommendedBundleVariation,
    recommendedMaskType,
    selectedBundle,
    selectedTab,
}) {
    const dispatch = useDispatch();
    const { cpapMachinesOptionID } = useSelector((state) => state.catalog);

    return (
        <ul
            className={`${
                selectedTab === 'recommended' ? 'block opacity-100' : 'hidden opacity-0'
            } mb-4 space-y-4 transition duration-300 ease-out-quad`}>
            <li>
                <p className="mb-1 flex items-center space-x-2 text-xs font-bold lg:text-sm">
                    <span className="relative inline-block h-5 w-5 rounded-full bg-gem-green text-white">
                        <CheckmarkIcon className="absolute-center" />
                    </span>
                    <span>Measurements complete</span>
                </p>
                <p className="text-xs lg:text-sm">
                    Based on your answers and the image submitted, we recommend the following:
                </p>
            </li>
            <li className="bg-white p-5">
                <span className="block text-xs font-bold text-gray-six lg:text-sm">
                    CPAP Machine:
                </span>
                <div className="lg:flex lg:items-center lg:justify-between">
                    <h5 className="text-sm font-semibold lg:text-lg">{cpapMachineName}</h5>
                    <button
                        className="border-b border-gem-green text-xs font-bold"
                        onClick={() => {
                            dispatch(toggleModal('cpapDetails'));
                        }}>
                        Details
                    </button>
                </div>
            </li>

            <li className="bg-white p-5">
                <span className="block text-xs font-bold text-gray-six lg:text-sm">
                    {maskData.find((item) => item.type === recommendedMaskType)?.title}:
                </span>
                <div className="lg:flex lg:items-center lg:justify-between">
                    <h5 className="text-sm font-semibold lg:text-lg">
                        {getMaskSystemName(selectedBundle)}
                    </h5>
                    <button
                        className="border-b border-gem-green text-xs font-bold"
                        onClick={() => {
                            dispatch(toggleModal('maskDetail'));
                        }}>
                        Details
                    </button>
                </div>
                <p className="flex flex-wrap mt-2">
                    {recommendedBundleVariation?.itemVariationData.itemOptionValues
                        .filter((opt) => opt.itemOptionId !== cpapMachinesOptionID)
                        ?.map((opt) => (
                            <span
                                key={opt.itemOptionId}
                                className="text-xs text-gray-six lg:text-sm pr-2">
                                <b>{getItemOptionDataById(opt.itemOptionId)?.name}: </b>
                                {formatOptionValue(
                                    getItemOptionDataById(opt.itemOptionId)?.values?.find(
                                        (o) => o.id === opt.itemOptionValueId,
                                    )?.itemOptionValueData?.name,
                                )}
                            </span>
                        ))}
                </p>
            </li>

            <li className="bg-white p-5">
                <h5 className="mb-3 flex items-center justify-between text-sm font-semibold lg:mb-4 lg:text-lg">
                    <span>90-Day Supply Replacement Parts</span>

                    <button
                        className="border-b border-gem-green text-xs font-bold"
                        type="button"
                        onClick={() => {
                            dispatch(toggleModal('90day'));
                        }}>
                        Details
                    </button>
                </h5>
                <p>Order includes the necessary replacement parts for 90-days.</p>
            </li>
        </ul>
    );
}

export default RecommendedOption;
