import PatientFilter from './PatientFilter';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { setAdminSliceValue, setStatusFilter } from '../../../features/admin/adminSlice';
import {
    useGetPartnerOrganizationsQuery,
    useGetPayersQuery,
} from '../../../app/services/insurance';
import { useGetCoachesQuery } from '../../../app/services/admin/careteam';
import { patientStatusesWithOptions } from '../../../utils/constants';
import SaveQuery from './SaveQuery';

const PatientFilters = ({ setPage, dataLoading }) => {
    const dispatch = useDispatch();
    const {
        patientFilterComponents,
        availablePatientFilters,
        activePatientFilters,
        careTeamOptions,
        payerOptions,
        partnersOptions,
    } = useSelector((state) => state.admin);
    //Iterate ID of each filter to have unique keys for removal
    const [filterId, setFilterId] = useState(1);

    //Get list of partners, payers, and careteam users
    const { isLoading: partnersLoading } = useGetPartnerOrganizationsQuery({ paginated: false });
    const { isLoading: payersLoading } = useGetPayersQuery();
    const { isLoading: careTeamLoading } = useGetCoachesQuery();

    //List of filters which are used for patients
    const filterOptions = {
        filterTypes: [
            ...patientStatusesWithOptions.map((option) => option.value),
            'careTeam',
            'insurance',
            'referFromOrganization',
        ],
        availableFilterKey: 'availablePatientFilters',
        adminFilterKey: 'activePatientFilters',
        statusFilterKey: 'patientFilterComponents',
    };

    function loadAdditionalFilters() {
        dispatch(
            setAdminSliceValue({
                key: filterOptions.availableFilterKey,
                value: [
                    ...patientStatusesWithOptions,
                    {
                        available: true,
                        value: 'insurance',
                        label: 'Insurance',
                        statusOptions: payerOptions,
                    },
                    {
                        available: true,
                        value: 'careTeam',
                        label: 'Care Team',
                        statusOptions: careTeamOptions,
                    },
                    {
                        available: true,
                        value: 'referFromOrganization',
                        label: 'Refer From Organization',
                        statusOptions: partnersOptions,
                    },
                ],
            }),
        );
    }

    // Once isLoading is resolved, this will add partners to the available filters
    useEffect(() => {
        if (!partnersLoading && !payersLoading && !careTeamLoading) loadAdditionalFilters();
        // eslint-disable-next-line
    }, [partnersLoading, payersLoading, careTeamLoading]);

    function removeFilter(id) {
        //Find the filter to deleted
        const filterToDelete = patientFilterComponents.filter(
            (item, index) => item.filterId === id,
        );

        //If it exists, proceed with deleting it
        if (filterToDelete[0].status.value) {
            setPage(0);
            const newAvailableFilters = availablePatientFilters.filter((filter) => {
                if (filter !== filterToDelete[0].status) {
                    return filter;
                } else return null;
            });
            newAvailableFilters.push({ ...filterToDelete[0].status, available: true });

            dispatch(
                setStatusFilter({
                    type: filterOptions.adminFilterKey,
                    key: filterToDelete[0].status.value,
                    value: null,
                }),
            );

            //Set available filters to include the filter which was deleted
            dispatch(
                setAdminSliceValue({
                    key: filterOptions.availableFilterKey,
                    value: newAvailableFilters,
                }),
            );
        }

        //Create an array without the filter needing removal and set state to this new array
        const newStatusFilters = patientFilterComponents.filter(
            (item, index) => item.filterId !== id,
        );
        dispatch(
            setAdminSliceValue({ key: filterOptions.statusFilterKey, value: newStatusFilters }),
        );
    }

    //Add the filter with an associated id from the state value, increment the state value so each has a unique id
    function addFilter() {
        const newFilters = [
            ...patientFilterComponents,
            {
                filterId: filterId,
                status: {},
                previousStatus: undefined,
                filters: [],
            },
        ];

        dispatch(setAdminSliceValue({ key: filterOptions.statusFilterKey, value: newFilters }));
        setFilterId(filterId + 1);
    }

    function clearFilters() {
        //Remove any admin filters that were set from the filters
        dispatch(
            setStatusFilter(
                filterOptions.filterTypes.map((filterType) => ({
                    type: filterOptions.adminFilterKey,
                    key: filterType,
                    value: null,
                })),
            ),
        );
        //Set all status filters to be available
        loadAdditionalFilters();

        //Remove all status filter components
        dispatch(setAdminSliceValue({ key: filterOptions.statusFilterKey, value: [] }));
        setPage(0);
    }

    return (
        <>
            <div className="col-start-1 col-span-6">
                <p className="mb-3 font-semibold pt-2 w-full">Filter by:</p>

                <div className="w-full flex flex-wrap items-center">
                    {patientFilterComponents.map((obj, index) => (
                        <div
                            className="basis-full lg:basis-1/2 flex flex-wrap items-center md:mb-2"
                            key={obj.filterId + 'div'}>
                            <PatientFilter
                                setPage={setPage}
                                key={obj.filterId + 'filter'}
                                id={obj.filterId}
                                index={index}
                                status={obj.status}
                                previousStatus={obj.previousStatus}
                                filters={obj.filters}
                            />
                            <button
                                className="ml-2 md:ml-0 md:basis-[6%] text-red font-bold"
                                key={obj.filterId + 'remove button'}
                                onClick={() => {
                                    removeFilter(obj.filterId);
                                }}>
                                X
                            </button>
                        </div>
                    ))}
                </div>
                <div className="flex flex-wrap items-center space-x-4">
                    {
                        //Do not show more filters than can be set
                        patientFilterComponents.length < availablePatientFilters.length ? (
                            <button
                                //Disabled when querySearch is in use
                                disabled={activePatientFilters.querySearch}
                                onClick={addFilter}
                                className="btn-primary-small basis-[30%] mt-2 h-[34px] md:h-[42px]">
                                Add filter
                            </button>
                        ) : null
                    }
                    {
                        //Only show when a filter has been added
                        patientFilterComponents.length > 0 && !activePatientFilters.querySearch ? (
                            <>
                                <button
                                    disabled={dataLoading}
                                    onClick={() => clearFilters()}
                                    className="btn-secondary-small basis-[30%] mt-2 h-[34px] md:h-[42px]">
                                    Clear Filters
                                </button>
                                <SaveQuery
                                    activeFilters={activePatientFilters}
                                    queryType="PATIENT"
                                />
                            </>
                        ) : null
                    }
                </div>
            </div>
        </>
    );
};

export default PatientFilters;
