import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useGetPaginatedResupplyQuery } from '../../../app/services/admin/orders';
import Pagination from '../Pagination';
import { formatDate } from '../../../utils/constants';
import { BsArrowDown } from 'react-icons/bs';
import { setStatusFilter } from '../../../features/admin/adminSlice';

const columnLabels = [
    'Created',
    'User',
    'Status',
    'Next Shipment',
    'Enrolled With Niko',
    'Updated',
];

function ResupplyList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const { user } = useSelector((state) => state.auth);
    const { orderByKey, orderDesc } = useSelector((state) => state.admin.activeResupplyFilters);

    const { data: resupplyList, isFetching } = useGetPaginatedResupplyQuery(
        {
            userId: user?.id,
            skip: page * 10,
            orderByKey: orderByKey,
            orderDesc: orderDesc,
        },
        {
            skip: !user,
        },
    );

    const sortableColumns = ['Created', 'Status', 'Next Shipment', 'Enrolled With Niko', 'Updated'];

    return (
        <>
            <div
                className={`grid grid-cols-${columnLabels.length} text-left max-w-6xl`}
                key={`orders-table-labels`}>
                {columnLabels.map((label) => (
                    <p
                        key={`label-${label}`}
                        className={`text-gray flex items-center ${sortableColumns.includes(label) && 'hover:cursor-pointer'}`}
                        onClick={() => {
                            if (sortableColumns.includes(label)) {
                                dispatch(
                                    setStatusFilter(
                                        //If the orderByKey is already set to this, adjust the direction
                                        orderByKey === label
                                            ? {
                                                  type: 'activeResupplyFilters',
                                                  key: 'orderDesc',
                                                  value: !orderDesc,
                                              }
                                            : //Otherwise change the orderByKey
                                              {
                                                  type: 'activeResupplyFilters',
                                                  key: 'orderByKey',
                                                  value: label,
                                              },
                                    ),
                                );
                                setPage(0);
                            }
                        }}>
                        <span
                            className={`font-semibold ${
                                orderByKey === label
                                    ? 'text-gem-green'
                                    : sortableColumns.includes(label)
                                      ? 'hover:text-gem-green'
                                      : null
                            }`}>
                            {label}
                        </span>
                        {label === orderByKey && (
                            <BsArrowDown
                                className={`transition duration-200 ease-out-quad left-10 scale-110 text-white ${
                                    orderDesc ? 'rotate-180' : 'rotate-0'
                                } `}
                            />
                        )}
                    </p>
                ))}

                {resupplyList?.results.length === 0 ? (
                    <p className="col-span-6 p-4">There are no resupplies</p>
                ) : (
                    resupplyList?.results.map((result) => (
                        <div
                            onClick={() => {
                                navigate(`/admin/patients/${result.userId}/resupply`, {});
                            }}
                            className={`col-span-6 grid grid-cols-${columnLabels.length} text-left py-2 hover:cursor-pointer hover:bg-gray-darker`}
                            key={'resupply' + result.id}>
                            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                                {formatDate(result.createdAt)}
                            </p>
                            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                                <span className="text-gray">{result.userId}</span>
                                {' ' + result.user?.firstName + ' ' + result.user?.lastName}
                            </p>
                            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                                {result.enrollmentStatus}
                            </p>
                            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                                {result.nextShipmentDate && formatDate(result.nextShipmentDate)}
                            </p>
                            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                                {result.enrolledWithNiko ? (
                                    <span className=" text-gem-green">YES</span>
                                ) : (
                                    <span className=" text-red">NO</span>
                                )}
                            </p>
                            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                                {result.updatedAt && formatDate(result.updatedAt)}
                            </p>
                        </div>
                    ))
                )}
            </div>
            {resupplyList?.metadata.total > 10 && (
                <Pagination
                    page={page}
                    disabled={isFetching}
                    setPage={setPage}
                    totalPages={Math.floor(resupplyList.metadata.total / 10)}
                />
            )}
        </>
    );
}

export default ResupplyList;
