import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetBlogCategoriesQuery } from '../../app/services/webflow';
import { BlogFilter } from '../../components';
import { setSupportFilters } from '../../features/ui/uiSlice';
import { ReactComponent as FilteringIcon } from '../../images/icons/filtering.svg';

const SupportFilters = () => {
    const dispatch = useDispatch();
    const { data: blogCategories } = useGetBlogCategoriesQuery();

    const [activeMenu, setActiveMenu] = useState(null);

    return (
        <nav className="mb-3 flex items-center space-x-4">
            <FilteringIcon />

            {blogCategories?.items && (
                <BlogFilter
                    filterKey="categories"
                    title="Categories"
                    isOpen={activeMenu && activeMenu === 'categories' ? true : false}
                    items={blogCategories.items}
                    onOpen={() => {
                        setActiveMenu('categories');
                    }}
                    onClose={() => {
                        setActiveMenu(null);
                    }}
                />
            )}

            <BlogFilter
                filterKey="type"
                title="Type"
                items={[
                    { _id: 'video', name: 'Video' },
                    { _id: 'article', name: 'Article' },
                ]}
                isOpen={activeMenu && activeMenu === 'type' ? true : false}
                onOpen={() => {
                    setActiveMenu('type');
                }}
                onClose={() => {
                    setActiveMenu(null);
                }}
            />

            <button
                className="!ml-auto font-heading tracking-wider text-green-400 transition hover:text-green-hover"
                onClick={() => {
                    dispatch(setSupportFilters({ key: 'categories', value: [] }));
                    dispatch(setSupportFilters({ key: 'type', value: [] }));
                    setActiveMenu(null);
                }}>
                Clear
            </button>
        </nav>
    );
};

export default SupportFilters;
