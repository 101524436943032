import { api } from '../auth';

const adminAppointmentsAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getAvailablePractitionersAdmin: builder.query({
            query: (body) => ({
                url: '/appointments/practitionersByStateAndPayer',
                method: 'POST',
                body: body,
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetAvailablePractitionersAdminQuery } = adminAppointmentsAPI;
