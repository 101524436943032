import { Link } from 'react-router-dom';
import { PageHeader } from '../../components/LayoutComponents';

const DentistConfirmation = () => {
    return (
        <>
            <PageHeader>Thanks for creating your GEM Account!</PageHeader>
            <p className="mb-6 max-w-4xl text-sm font-bold lg:text-lg">
                Show your dentist this confirmation screen. They will get you a home sleep test
                device to take home.
            </p>
            <p className="mb-6 max-w-4xl text-sm italic lg:text-lg">
                Meanwhile, we are working to get your test setup and will send you an email once it
                is ready to go. A GEM SLEEP clinician will review the information you submitted in
                5-7 business days. Once we have reviewed your information, you will receive an email
                with the PIN number needed to start the test.
            </p>
            <p className="mb-6 max-w-4xl text-sm lg:text-lg">
                Once you receive the email, follow these instructions to complete your test:
            </p>
            <ul className="mb-6 list-disc space-y-3 pl-4">
                <li>
                    Download WatchPat&trade; ONE app on your phone and follow the steps to connect
                    your device{' '}
                    <i>
                        (Note: disregard steps for applying heart monitor, the GEM device does not
                        include the heart monitor)
                    </i>
                </li>
                <li>When prompted for pin number enter the pin provided via email</li>
                <li>When you are ready to go to sleep, press 'Start' in the app</li>
                <li>
                    Get Your ZZZ's! Pro Tip: For best results, sleep on your back and get six or
                    more hours of sleep.
                </li>
                <li>
                    When you wake up, end the recording. Your results will automatically be sent to
                    GEM
                </li>
                <li>
                    We will notify you once your results are in and will provide a personalized
                    treatment plan. Recommendations may include CPAP or a referral to a dental sleep
                    medicine specialists for oral appliance therapy.
                </li>
            </ul>
            <Link className="btn-primary" to="../treatment-options">
                Explore GEM TREATMENT OPTIONS
            </Link>
        </>
    );
};

export default DentistConfirmation;
