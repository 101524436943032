import { toast } from 'react-toastify';
import { FiArrowUpRight } from 'react-icons/fi';
import { formatPhoneNumber } from '../../../utils/constants';

const gridHeaders = [
    ['Referral Link', 'Website', 'Scheduling Link', 'Email'],
    ['Phone', 'Address', 'DME Referring Payers'],
];

const getReferralLink = (partner) => {
    if (!partner.isReferrer) {
        return null;
    }

    const orgType = partner.organizationType === 'DENTAL' ? 'dental' : 'partner';
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        return `https://portal-dev.gem.health/${orgType}/${partner.id}`;
    } else if (process.env.REACT_APP_ENVIRONMENT === 'local') {
        return `https://localhost:3001/${orgType}/${partner.id}`;
    } else {
        return `https://portal.gem.health/${orgType}/${partner.id}`;
    }
};

function OrganizationInfo({ partner }) {
    const referralLink = partner.isReferrer && getReferralLink(partner);

    return (
        <>
            <div
                className="grid grid-cols-4 my-4 gap-y-4 gap-x-2"
                key={'partner-info-' + partner.id}>
                {gridHeaders[0].map((header) => (
                    <p className="text-gray">{header}</p>
                ))}

                <p className="text-sm break-all flex">
                    {referralLink && (
                        <>
                            <span
                                className="hover:cursor-pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toast.success('Copied to Clipboard');
                                    navigator.clipboard.writeText(referralLink);
                                }}>
                                {referralLink}
                            </span>
                            <a
                                className="!border-b-0 mt-0.5 ml-1"
                                href={referralLink}
                                target="_blank"
                                rel="noreferrer">
                                <FiArrowUpRight className="scale-150 text-gem-green" />
                            </a>
                        </>
                    )}
                </p>

                <p className="text-sm break-all flex">
                    {partner.websiteUrl ? (
                        <>
                            <span
                                className="hover:cursor-pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toast.success('Copied to Clipboard');
                                    navigator.clipboard.writeText(partner.websiteUrl);
                                }}>
                                {partner.websiteUrl}
                            </span>
                            <a
                                className="!border-b-0 mt-0.5 ml-1"
                                href={partner.websiteUrl}
                                target="_blank"
                                rel="noreferrer">
                                <FiArrowUpRight className="scale-150 text-gem-green" />
                            </a>
                        </>
                    ) : (
                        ' No Website provided'
                    )}
                </p>

                <p className="text-sm break-all flex">
                    {partner.schedulingLink && (
                        <>
                            <span
                                className="hover:cursor-pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toast.success('Copied to Clipboard');
                                    navigator.clipboard.writeText(partner.schedulingLink);
                                }}>
                                {partner.schedulingLink}
                            </span>
                            <a
                                className="!border-b-0 mt-0.5 ml-1"
                                href={partner.schedulingLink}
                                target="_blank"
                                rel="noreferrer">
                                <FiArrowUpRight className="scale-150 text-gem-green" />
                            </a>
                        </>
                    )}
                </p>

                <p className="text-sm break-all flex">
                    {partner.schedulingLink && (
                        <>
                            <span
                                className="hover:cursor-pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toast.success('Copied to Clipboard');
                                    navigator.clipboard.writeText(partner.email);
                                }}>
                                {partner.email}
                            </span>
                            <a
                                className="!border-b-0 mt-0.5 ml-1"
                                href={`mailto:${partner.email}`}
                                target="_blank"
                                rel="noreferrer">
                                <FiArrowUpRight className="scale-150 text-gem-green" />
                            </a>
                        </>
                    )}
                </p>

                {gridHeaders[1].map((header) => (
                    <p className="text-gray">{header}</p>
                ))}

                <p className="text-sm break-all flex col-start-1">
                    {partner.websiteUrl ? (
                        <>
                            <span
                                className="hover:cursor-pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toast.success('Copied to Clipboard');
                                    navigator.clipboard.writeText(partner.phone);
                                }}>
                                {formatPhoneNumber(partner.phone)}
                            </span>
                            <a
                                className="!border-b-0 mt-0.5 ml-1"
                                href={`tel:+1${partner.phone.replace(/[- )(]/g, '')}`}
                                target="_blank"
                                rel="noreferrer">
                                <FiArrowUpRight className="scale-150 text-gem-green" />
                            </a>
                        </>
                    ) : (
                        ' No Website provided'
                    )}
                </p>

                <p
                    className="text-sm hover:cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        toast.success('Copied to Clipboard');
                        navigator.clipboard.writeText(
                            partner.address +
                                ', ' +
                                partner.city +
                                ', ' +
                                partner.state +
                                ' ' +
                                partner.zip,
                        );
                    }}>
                    {partner.address}, {partner.city}, {partner.state} {partner.zip}
                </p>

                {partner.organizationType === 'DME_PROVIDER' && (
                    <p className="text-sm">
                        {partner.dmeReferringPayers.map((payer) => payer.name).join(', ')}
                    </p>
                )}
            </div>
        </>
    );
}

export default OrganizationInfo;
