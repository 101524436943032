import Select from 'react-select';
import { useGetPartnerOrganizationTypesQuery } from '../../../app/services/admin/organizations';
import { adminSelectCustomStyles } from '../../../utils/constants';

function OrganizationListFilters({ filters, initialFilters, setFilters }) {
    const { data: organizationTypes } = useGetPartnerOrganizationTypesQuery();

    const organizationOptions = organizationTypes?.map((type) => ({ label: type, value: type }));

    return (
        <div className="my-2 flex space-x-4 mb-6">
            <div className="flex items-center space-x-2">
                <p>Name: </p>{' '}
                <input
                    name="name"
                    type="text"
                    value={filters?.name}
                    onChange={(e) => setFilters({ ...filters, name: e.currentTarget.value })}
                    placeholder="Enter a name"
                    className="appearance-none border border-gray bg-transparent p-2 text-sm text-white"
                />
            </div>

            <div className="flex items-center space-x-2">
                <p>Type: </p>{' '}
                <Select
                    key="org-type-select"
                    options={organizationOptions}
                    className="min-w-[250px]"
                    onChange={(newValue) => {
                        setFilters({
                            ...filters,
                            organizationType: newValue?.value ? newValue?.value : null,
                        });
                    }}
                    placeholder={'Select Type'}
                    styles={adminSelectCustomStyles}
                    value={
                        organizationOptions?.find(
                            (option) => option.value === filters.organizationType,
                        ) ?? ''
                    }
                    cacheOptions
                    isClearable
                />
            </div>
            <button
                className="btn-secondary-small h-[38px] mt-[2px]"
                disabled={filters === initialFilters}
                onClick={() => setFilters(initialFilters)}>
                Clear
            </button>
        </div>
    );
}

export default OrganizationListFilters;
