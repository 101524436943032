import { motion } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from '..';
import { toggleModal } from '../../features/ui/uiSlice';
import { productOptionPanelVariants, formatOptionValue } from '../../utils/constants';
import {
    selectCatalogCPAPBundles,
    setSelectedProduct,
    setSelectedVariation,
    setSelectedOptions,
} from '../../features/catalog/catalogSlice';
import { ReactComponent as DownChevron } from '../../images/down-chevron.svg';

export const getMaskSystemName = (bundle) => {
    const maskDescription = Object.values(bundle.customAttributeValues).find((attr) =>
        /Mask System Description/gi.test(attr.name),
    );

    if (maskDescription.stringValue.indexOf('|') !== -1) {
        const parts = maskDescription.stringValue.split('|');
        return parts[0].trim();
    }

    return maskDescription.stringValue;
};

export const getMaskSystemDescription = (bundle) => {
    const maskDescription = Object.values(bundle.customAttributeValues).find((attr) =>
        /Mask System Description/gi.test(attr.name),
    );

    if (maskDescription.stringValue.indexOf('|') !== -1) {
        const parts = maskDescription.stringValue.split('|');
        return parts.length > 0 ? parts[1].trim() : maskDescription.stringValue;
    }

    return maskDescription.stringValue;
};

const MaskOptionBuilder = ({ maskCategoryAttributeValue, brand }) => {
    const dispatch = useDispatch();
    const { modalsOpen } = useSelector((state) => state.ui);
    const {
        catalogOptions,
        cpapMachinesOptionID,
        recommendedCPAPBundles,
        selectedProduct,
        selectedVariation,
    } = useSelector((state) => state.catalog);

    const cpapBundles = useSelector(selectCatalogCPAPBundles);

    const recommendedSkus = recommendedCPAPBundles?.map(
        (rec) => rec.recommendedVariation.itemVariationData.sku,
    );

    //This appears to return any bundles which are of the brand of CPAP, not of the selected CPAP machine
    const bundleData = cpapBundles?.filter((bundle) => {
        const { skuMatcher } = brand;

        return (
            Object.values(bundle.customAttributeValues).some(
                (customAtt) =>
                    customAtt.stringValue === maskCategoryAttributeValue &&
                    customAtt.name === process.env.REACT_APP_MASK_CATEGORY_CUSTOM_ATTRIBUTE_NAME,
            ) &&
            bundle.itemData.variations?.every((variant) =>
                skuMatcher.test(variant.itemVariationData.sku),
            )
        );
    });

    const mainSelectHandler = (ev) => {
        dispatch(setSelectedProduct(ev.target.value));
        dispatch(setSelectedVariation(null));
        dispatch(setSelectedOptions([]));
    };

    const getItemOptionDataById = (itemOptionId) => {
        return catalogOptions.find((o) => o.id === itemOptionId)?.itemOptionData;
    };

    return (
        <motion.div
            className="space-y-5"
            initial="collapsed"
            animate="expanded"
            exit="collapsed"
            variants={productOptionPanelVariants}
            transition={{ ease: [0.5, 1, 0.89, 1], duration: 0.3 }}>
            <div className="lg:flex lg:items-center">
                {bundleData && (
                    <div className="relative lg:mr-9 lg:flex-1">
                        <select
                            name="mask"
                            className="block w-full appearance-none border-b border-gray-darker bg-transparent pb-2 pr-6 lg:text-lg"
                            value={selectedProduct?.id}
                            onChange={mainSelectHandler}>
                            <option value="" className="text-black/20">
                                Select a mask
                            </option>
                            {bundleData.map((bundle) => (
                                <option key={bundle.id} value={bundle.id}>
                                    {getMaskSystemName(bundle)}
                                    {bundle.itemData.variations?.some((v) =>
                                        recommendedSkus?.some(
                                            (rec) => (rec = v.itemVariationData.sku === rec),
                                        ),
                                    )
                                        ? ' (Recommended)'
                                        : ''}
                                </option>
                            ))}
                        </select>

                        <div className="text-gray-700 pointer-events-none absolute right-0 top-1/2 -translate-y-1/2 pl-2 pb-2">
                            <DownChevron className="h-4 w-4 fill-current" />
                        </div>
                    </div>
                )}
                <button
                    className={`border-b border-gem-green text-xs font-bold transition ${
                        selectedProduct ? '' : 'pointer-events-none opacity-25'
                    }`}
                    type="button"
                    onClick={() => {
                        dispatch(toggleModal('maskDetail'));
                    }}>
                    Details
                </button>
            </div>

            {selectedProduct && (
                <>
                    <p className="font-semibold">Select an option: </p>
                    <div className="grid grid-cols-2 gap-4">
                        {selectedProduct.itemData.variations.map((v) => (
                            <button
                                key={v.id}
                                className={`block border p-2 ring-1 ring-inset transition ease-out-quad hover:border-gem-green ${
                                    v.id === selectedVariation?.id
                                        ? 'border-gem-green bg-gem-green/20 ring-gem-green'
                                        : 'border-gray-darker/50 ring-transparent'
                                }`}
                                onClick={() => {
                                    dispatch(setSelectedVariation(v));
                                }}>
                                {v.itemVariationData.itemOptionValues
                                    .filter((opt) => opt.itemOptionId !== cpapMachinesOptionID)
                                    ?.map((opt) => (
                                        <p
                                            key={opt.itemOptionId}
                                            className="text-left text-xs lg:text-sm">
                                            <b>{getItemOptionDataById(opt.itemOptionId)?.name}: </b>
                                            {formatOptionValue(
                                                getItemOptionDataById(opt.itemOptionId).values.find(
                                                    (o) => o.id === opt.itemOptionValueId,
                                                )?.itemOptionValueData?.name,
                                            )}
                                        </p>
                                    ))}
                            </button>
                        ))}
                    </div>
                </>
            )}

            {selectedProduct && (
                <Modal
                    showClose={true}
                    isOpen={modalsOpen.indexOf('maskDetail') > -1}
                    closeHandler={() => {
                        dispatch(toggleModal('maskDetail'));
                    }}>
                    <div className="space-y-4 text-gray-darker">
                        <h3 className="mb-4 font-heading text-4xl lg:text-7xl">
                            {getMaskSystemName(selectedProduct)}
                        </h3>
                        <p>{getMaskSystemDescription(selectedProduct)}</p>
                    </div>
                </Modal>
            )}
        </motion.div>
    );
};

export default MaskOptionBuilder;
