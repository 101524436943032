import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../features/ui/uiSlice';
import sevenDaysSrc from '../../images/7-days.png';
import fifteenDaysSrc from '../../images/15-days.png';
import thirtyDaysSrc from '../../images/30-days.png';

const ProgressBadges = () => {
    const dispatch = useDispatch();
    const { wore7days, wore15days, wore30days } = useSelector((state) => state.ui);

    return (
        <>
            {wore30days && (
                <button
                    className="w-24 translate-y-3"
                    onClick={() => {
                        dispatch(toggleModal('30days'));
                    }}>
                    <img src={thirtyDaysSrc} alt="" />
                </button>
            )}
            {wore15days && (
                <button
                    className="w-24 translate-y-3"
                    onClick={() => {
                        dispatch(toggleModal('15days'));
                    }}>
                    <img src={fifteenDaysSrc} alt="" />
                </button>
            )}
            {wore7days && (
                <button
                    className="w-24 translate-y-3"
                    onClick={() => {
                        dispatch(toggleModal('7days'));
                    }}>
                    <img src={sevenDaysSrc} alt="" />
                </button>
            )}
        </>
    );
};

export default ProgressBadges;
