function useWebWidget(isEnabled) {
    if (isEnabled) {
        const widget = document.getElementById('ze-snippet');
        if (!widget) {
            const script = document.createElement('script');
            script.id = 'ze-snippet';
            script.async = true;
            script.src = `https://static.zdassets.com/ekr/snippet.js?key=e1836aad-03b2-4ca1-ad67-35a8ed9ee96e`;
            document.body.appendChild(script);

            script.onload = () => {
                //Log out on load makes sure it starts clean, without previous messages
                window.zE('messenger', 'logoutUser');
            };
        }
    } else {
        const widget = document.getElementById('ze-snippet');
        if (widget && window.zE) {
            window.zE('messenger', 'logoutUser');
            window.zE('messenger', 'hide');
        }
    }
}

export default useWebWidget;

// WebWidget is able to identify or prefill the form, but we are using messenger with limited API functionality
// window.zE('webWidget', 'identify', {name: user.firstName + ' ' + user.lastName, email: user.email });
// WebWidget also has a reset function
// window.zE('webWidget', 'reset')
// More syntax at https://developer.zendesk.com/documentation/classic-web-widget-sdks/web-widget/getting-started/chat_api_migration/
