import { useDispatch } from 'react-redux';
import Lottie from 'lottie-react';
import { motion } from 'framer-motion';
import { setAssessmentStep } from '../../../features/assessment/assessmentSlice';
import assessmentCalculatingAnimation from '../../../utils/assessment-calculating.json';

function CalculatingAssessment() {
    const dispatch = useDispatch();

    setTimeout(() => {
        dispatch(setAssessmentStep('result'));
    }, 3000);

    return (
        <motion.div
            initial={{ opacity: 0, translateX: '20%' }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: '-20%' }}
            transition={{ ease: 'linear' }}
            className="mx-auto max-w-2xl text-center">
            <Lottie
                animationData={assessmentCalculatingAnimation}
                loop={true}
                className="mx-auto max-w-md"
            />
            <h2 className="font-heading text-3xl text-white lg:text-8xl">Computing...</h2>
            <p className="text-gray-light lg:text-2xl">(Beep, boop, boop&hellip;)</p>
        </motion.div>
    );
}

export default CalculatingAssessment;
