import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../../features/ui/uiSlice';
import { insuranceCreateSchema } from '../../../utils/schemas';
import InsuranceForm, { FormType } from '../InsuranceForm';
import { motion } from 'framer-motion';
import { overlayVariants } from '../../../utils/constants';
import { useCheckoutContext } from '../../Checkout/CheckoutContext';
import { useInsuranceContext } from '../InsuranceContext';

const AddInsuranceInfo = ({ addPatientInsurance }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [errorMessage, setErrorMessage] = useState(null);
    const { skipInsurance } = useCheckoutContext();
    const { insurance } = useInsuranceContext();

    const insuranceSubmitHandler = async (data) => {
        const addInsuranceResult = await addPatientInsurance(data);
        if (addInsuranceResult?.status === 'error') {
            setErrorMessage(addInsuranceResult.data?.message);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <motion.div initial="hidden" animate="visible" exit="hidden" variants={overlayVariants}>
            <div className="space-y-5">
                <h5 className="mb-5 font-heading text-4xl font-bold lg:text-5xl">
                    {insurance?.payer?.name
                        ? //If the above exists, the user is adding new information, so we default to this format
                          'Add your primary insurance information here'
                        : 'Please provide your primary insurance details.'}
                </h5>
                <div className="mb-5">
                    {insurance?.payer?.name ? (
                        <p className="mb-5">
                            This will become your current primary insurance on record with GEM.
                        </p>
                    ) : (
                        <>
                            <p className="mb-5">
                                Let's see if your insurance works with this order by entering the
                                details below.
                            </p>
                            <button
                                className="link"
                                onClick={() => {
                                    dispatch(toggleModal('insuranceDetailHelp'));
                                }}>
                                Need help?
                            </button>
                        </>
                    )}
                </div>

                {errorMessage && <p className="text-lg font-bold text-red">{errorMessage}</p>}

                <InsuranceForm
                    formType={FormType.ADD}
                    user={user}
                    buttonCopy={`Submit`}
                    validationSchema={insuranceCreateSchema}
                    onSubmit={insuranceSubmitHandler}
                />

                <div className="text-center">
                    <button
                        className="btn-shell"
                        onClick={() => {
                            skipInsurance({ showAlert: true });
                        }}>
                        Continue without insurance
                    </button>
                </div>
            </div>
        </motion.div>
    );
};

export default AddInsuranceInfo;
