function getPriorStudyModalContent({ isCoachingPath }) {
    const linkText = isCoachingPath
        ? 'Learn more about what is needed'
        : 'Where can I get a copy of my sleep study';

    const titleText = isCoachingPath
        ? 'Do you have a Sleep Study Report and Clinical Visit Summary you can upload?'
        : 'Do you have a sleep study report you can upload?';

    const modalContent = isCoachingPath ? (
        <div className="space-y-2">
            <p>
                To qualify for the GEM coaching program and/or to get a new CPAP machine using
                insurance, your insurance provider requires we submit proper documentation to show
                medical necessity for these items.{' '}
            </p>
            <h5 className="font-bold">Where can I find these documents?</h5>
            <p>
                If you have access to an online portal through your prior sleep clinic (such as
                MyChart), you should be able to download a copy of these documents. If not, try
                reaching out to your clinic to have them send you a digital copy.
            </p>
            <h5 className="font-bold">What is a clinical visit summary?</h5>
            <p>
                Prior to completing your home sleep test you would have completed a doctor's visit,
                your clinician would have completed a 'chart note' or visit summary indicating they
                were recommending you for a home sleep test. A copy of this summary needs to be
                uploaded.
            </p>
            <p>
                If you do not have these documents, your insurance company will require that you
                start the Home Sleep Test process over with GEM.
            </p>
        </div>
    ) : (
        <p>
            'If you have access to an online portal through your prior sleep clinic (such as
            MyChart), you should be able to download a copy. If not, try reaching out to your clinic
            to have them send you a digital copy.'
        </p>
    );

    return { linkText, modalContent, titleText };
}

export default getPriorStudyModalContent;
