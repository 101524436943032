import { motion } from 'framer-motion';
import Shipment from './Shipment';

const gridLayout = 'grid grid-cols-[100px_200px_100px_150px_150px_150px_1fr] w-full space-x-3 pl-2';

function ShippingDetails({ order, patientId }) {
    return (
        <motion.div
            key={`order-shipment-details-${order?.id}`}
            className="w-[98%] m-auto"
            initial="hide"
            animate="show"
            exit="hide"
            transition={{ ease: 'circOut' }}>
            {order?.shipments?.length > 0 ? (
                <>
                    <div
                        key={`order-details-labels-${order?.id}`}
                        className={`${gridLayout} mt-4 text-sm text-gray`}>
                        <span className="ml-2">ID</span>
                        <span>Tracking Number</span>
                        <span>Carrier</span>
                        <span>Shipment Date</span>
                        <span>Delivery Date</span>
                        <span>Status</span>
                        <span className="justify-self-end pr-2">Del.</span>
                    </div>
                    {order?.shipments?.map((shipment) => {
                        return (
                            <Shipment
                                key={`shipment-component-${shipment.id}`}
                                order={order}
                                shipment={shipment}
                                gridLayout={gridLayout}
                                patientId={patientId}
                            />
                        );
                    })}
                </>
            ) : (
                <p className="text-xl text-salmon py-4">There are no shipments for this order</p>
            )}
        </motion.div>
    );
}

export default ShippingDetails;
