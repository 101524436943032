import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../features/ui/uiSlice';
import { useAssignSleepStudyAsPrescriptionMutation, useGetMeQuery } from '../../app/services/auth';
import { UploadFile } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useGetUserTimelineQuery } from '../../app/services/auth';
import { toast } from 'react-toastify';
import { determineUploadStep } from '../../utils/constants';
import { setCoachingPathWithoutPrescription } from '../../features/files/filesSlice';

function ClinicalChartsUpload() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //Queries and Mutations
    const { refetch } = useGetMeQuery();

    //To be run after prescription upload
    const chartSuccessCallback = async () => {
        await refetch();
        //delay for user experience
        setTimeout(() => {
            navigate('../study-in-review', { replace: true });
        }, 1000);
    };

    return (
        <>
            <UploadFile
                key="charts-upload"
                isModal={false}
                fileType={'CLINICAL_CHARTS'}
                uploadSuccessCallback={chartSuccessCallback}
            />

            <button
                className="font-heading text-green-400 tracking-wider hover:text-green-hover"
                onClick={() => {
                    dispatch(toggleModal('problemsUploading'));
                }}>
                Having problems uploading?
            </button>
        </>
    );
}

function PrescriptionUpload({ requiresCharts }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { uploadedSleepStudy } = useSelector((state) => state.files);
    const { isCoachingOnlyPath } = useSelector((state) => state.ui);

    //Queries and Mutations
    const [assignSleepStudyAsPrescription] = useAssignSleepStudyAsPrescriptionMutation();
    const { refetch } = useGetMeQuery();

    //To be run after prescription upload
    const prescriptionSuccessCallback = async () => {
        await refetch();
        if (!requiresCharts) {
            //delay for user experience
            setTimeout(() => {
                navigate('../study-in-review', { replace: true });
            }, 1000);
        }
    };

    return (
        <>
            <UploadFile
                key="prescription-upload"
                isModal={false}
                fileType={'UPLOADED_PRESCRIPTION'}
                uploadSuccessCallback={prescriptionSuccessCallback}
            />

            <button
                className="font-heading text-green-400 tracking-wider hover:text-green-hover"
                onClick={() => {
                    dispatch(toggleModal('problemsUploading'));
                }}>
                Having problems uploading?
            </button>
            <p className="font-heading tracking-wider">
                If your sleep study also contained your prescription
            </p>
            <button
                className="btn-shell-small w-[250px]"
                onClick={async () => {
                    const response = await assignSleepStudyAsPrescription({
                        id: uploadedSleepStudy?.id,
                    });
                    if (response?.data) {
                        prescriptionSuccessCallback();
                    } else {
                        toast.error('Something went wrong, please try again.');
                    }
                }}>
                Click Here
            </button>
            {isCoachingOnlyPath && (
                <>
                    <p className="font-heading tracking-wider">If you need a new prescription</p>
                    <button
                        className="btn-shell-small w-[250px]"
                        onClick={async () => {
                            dispatch(setCoachingPathWithoutPrescription(true));
                        }}>
                        Click Here
                    </button>
                </>
            )}
        </>
    );
}

const SleepStudyUpload = () => {
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    //Queries and Mutations
    const { refetch } = useGetMeQuery();
    const { refetch: refetchTimeline } = useGetUserTimelineQuery(user.id);

    //To be run after study upload
    const studySuccessCallback = async () => {
        refetchTimeline();
        //delay for user experience
        setTimeout(async () => {
            await refetch();
        }, 1000);
    };

    return (
        <>
            <UploadFile
                key="study-upload"
                isModal={false}
                fileType={'UPLOADED_SLEEP_STUDY'}
                uploadSuccessCallback={studySuccessCallback}
            />
            <button
                className="font-heading text-green-400 tracking-wider hover:text-green-hover"
                onClick={() => {
                    dispatch(toggleModal('problemsUploading'));
                }}>
                Having problems uploading?
            </button>
        </>
    );
};

function PatientRecordsUploads() {
    const {
        coachingPathWithoutPrescription,
        requiresCharts,
        uploadedCharts,
        uploadedPrescription,
        uploadedSleepStudy,
    } = useSelector((state) => state.files);

    const uploadStep = determineUploadStep({
        coachingPathWithoutPrescription,
        requiresCharts,
        uploadedCharts,
        uploadedPrescription,
        uploadedSleepStudy,
    });

    return (
        <div className="layout-outlet-root max-w-[1400px]">
            <div className="p-5 lg:p-0 max-w-5xl lg:mb-12 space-y-4">
                {uploadStep && (
                    <h3 className="text-[44px] lg:text-6xl font-heading mb-4 leading-10">
                        Upload your <span>{uploadStep}</span>
                    </h3>
                )}
                {uploadStep === 'sleep study report' ? (
                    <p className="text-sm lg:text-lg leading-normal mb-6">
                        No need to get a new test! Just upload the{' '}
                        <span className="font-bold">sleep study report</span>
                        {requiresCharts && (
                            <>
                                {', '}
                                <span className="font-bold">clinical charts</span>
                            </>
                        )}{' '}
                        and <span className="font-bold">prescription</span> from your prior study. A
                        GEM SLEEP provider will review and give you treatment options.
                    </p>
                ) : uploadStep === 'prescription' ? (
                    <p className="text-sm lg:text-lg leading-normal mb-6">
                        We've got your sleep study.{' '}
                        <span className="font-bold">Now upload your prescription.</span>{' '}
                        {requiresCharts
                            ? 'Once you finish uploading your prescription there will be only one more file to upload.'
                            : 'After uploading your prescription we will review your documents and contact you when your results are ready.'}
                    </p>
                ) : uploadStep === 'clinical visit summary' ? (
                    <p className="text-sm lg:text-lg leading-normal mb-6">
                        Your insurance also requires you upload a{' '}
                        <span className="font-bold">Clinical Visit Summary</span> (sometimes
                        referred to as: After Visit Summary or Chart Note). You can typically
                        download this from your online medical record. The visit summary should be
                        related to your sleep study.
                    </p>
                ) : null}

                <div className="space-y-4 lg:text-xl text-center">
                    {uploadStep === 'sleep study report' ? (
                        <SleepStudyUpload />
                    ) : uploadStep === 'prescription' ? (
                        <PrescriptionUpload requiresCharts={requiresCharts} />
                    ) : requiresCharts && uploadStep === 'clinical visit summary' ? (
                        <ClinicalChartsUpload />
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default PatientRecordsUploads;
