import { motion } from 'framer-motion';

const normalizeAHI = (ahi) => +(ahi / 60).toFixed(2);

const AHICircle = ({className, pathStrokeColor, ahiNumber}) => {
    return (
        <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="250"
    height="250"
    >
    <circle
    cx="125"
    cy="125"
    r="119"
    stroke="#dcdee2"
    strokeWidth="5"
    fill="transparent"
    />
    <motion.path
        className={`stroke-[${pathStrokeColor}]`}
        d="M10,125a115,115 0 1,0 230,0a115,115 0 1,0 -230,0"
        strokeWidth="12"
        fill="transparent"
        initial={{ pathLength: 0 }}
        animate={{
            pathLength: normalizeAHI(ahiNumber) + 0.01,
                stroke: pathStrokeColor,
        }}
        style={{ rotate: 90, scaleX: -1 }}
        transition={{ duration: 1.5, ease: [0.22, 1, 0.36, 1] }}
    />
</svg>

    )
}
export default AHICircle