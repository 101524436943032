import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

const ConsultationConfirmation = ({ onBookingComplete }) => {
    const { appointmentBooking } = useSelector((state) => state.ui);

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="space-y-4 max-w-2xl">
                <div className="mb-4">
                    <h5 className="mb-4 font-heading text-4xl font-bold lg:text-5xl">Success!</h5>
                    <p className="text-sm lg:text-base">
                        We have you booked for a consultation at the following time:
                    </p>
                    <p className="text-lg py-4">
                        <strong>
                            {format(
                                new Date(appointmentBooking?.bookedAppointment?.startAt),
                                'EEEE M/d h:mm a',
                            )}
                        </strong>
                    </p>
                </div>
                <div className="pt-2">
                    <p className="text-sm">
                        Details of your virtual visit will be emailed to you upon completing
                        checkout.
                    </p>
                    <button className="btn-primary mt-4 w-full" onClick={onBookingComplete}>
                        CONTINUE
                    </button>
                </div>
            </div>
        </motion.div>
    );
};

export default ConsultationConfirmation;
