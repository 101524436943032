import { useDispatch } from 'react-redux';
import watchpatImageSrc from '../../images/sleep-study-product/watchpat-one.png';
import { toggleModal } from '../../features/ui/uiSlice';
import { SleepStudyDownloadLink } from '../Diagnosis';

const RetestNeeded = () => {
    const dispatch = useDispatch();

    return (
        <div className="relative flex flex-col justify-start w-full bg-white sm:flex-row items-center gap-4 p-8 sm:gap-16 mb-4">
            <img
                src={watchpatImageSrc}
                alt="Sleep study"
                className="relative w-9/10 m-0-auto h-auto sm:w-[240px]"
            />
            <div className="w-full sm:w-3/4 max-w-xl">
                <h5 className="mb-3 font-heading text-4xl lg:text-5xl">
                    Home Sleep Study Retest Needed
                </h5>
                <p className="mb-4 text-[13px] lg:text-base max-w-xl">
                    We were not able to collect enough data from your Home Sleep Study test to
                    diagnose you with sleep apnea. You will need to complete a new test. To discuss
                    your options, please call or email us.
                </p>
                <div className="space-y-4">
                    <button
                        onClick={(ev) => dispatch(toggleModal('contact'))}
                        className="btn-primary w-full text-left max-w-xl">
                        Contact Us
                    </button>
                    <SleepStudyDownloadLink styling="btn-shell w-full text-left max-w-xl" />
                </div>
            </div>
        </div>
    );
};

export default RetestNeeded;
