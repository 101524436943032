import { motion } from 'framer-motion';
import { overlayVariants } from '../../../utils/constants';
import { useCheckoutContext } from '../../Checkout/CheckoutContext';

const UploadCardQuestion = () => {
    const { setCheckoutStep } = useCheckoutContext();

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={overlayVariants}
            className="space-y-5">
            <h5 className="font-heading text-4xl font-bold lg:text-5xl">
                Upload Insurance Card Now?
            </h5>
            <p className="mb-2">
                We have your insurance details. To better process your claim, please upload pictures
                of your insurance card.
            </p>

            <div className="flex">
                <button
                    className="btn-primary w-[calc(50%-8px)] mr-4"
                    aria-label="Yes"
                    onClick={() => setCheckoutStep('insurance-card')}>
                    Upload
                </button>

                <button
                    className="btn-primary w-[calc(50%-8px)] !bg-gray"
                    aria-label="Continue"
                    onClick={() => setCheckoutStep('is-eligible')}>
                    Continue Without Uploading
                </button>
            </div>
        </motion.div>
    );
};

export default UploadCardQuestion;
