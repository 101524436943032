import { useState } from 'react';
import { Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const StarterKitSlider = ({ slides }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
            <Swiper
                className="mb-3 lg:mb-4"
                modules={[Thumbs]}
                thumbs={{
                    swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
                    multipleActiveThumbs: false,
                    slideThumbActiveClass: 'swiper-react-active-thumb',
                }}>
                {slides.map((image) => (
                    <SwiperSlide key={`swiper-slide-${image}`}>
                        <div className="bg-white aspect-1 relative overflow-hidden">
                            <img
                                src={image}
                                alt=""
                                className="absolute-center object-center object-cover"
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <Swiper
                modules={[Thumbs]}
                spaceBetween={5}
                slidesPerView={5}
                watchSlidesProgress
                onSwiper={setThumbsSwiper}>
                {slides.map((image, idx) => (
                    <SwiperSlide key={`swiper-slide-${idx}`}>
                        <div className="bg-white aspect-1 relative overflow-hidden">
                            <img
                                src={image}
                                alt=""
                                className="absolute-center object-center object-contain"
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
};

export default StarterKitSlider;
