import topRightArrow from '../../images/top-right-arrow.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CareTeamCTA = () => {
    const { user } = useSelector((state) => state.auth);

    if (user?.careTeam?.length > 0) {
        return (
            <div className="flex w-100 my-5 py-5 flex-col md:flex-row">
                <div className="bg-black flex-1 flex items-center px-4 py-10">
                    <p className="text-white text-xl text-left md:text-left">
                        <span className="text-purple-lightest font-bold">GEM Coaching</span> is
                        available with all treatment options.
                    </p>
                </div>
                <div>
                    <Link
                        to="/dashboard/care-team"
                        className="bg-gem-purple hover:bg-purple-medium text-white font-bold inline-flex items-center w-full lg:w-[250px] py-10 justify-center"
                        style={{ minWidth: '250px', height: '100%' }}>
                        Meet your Care Team
                        <img src={topRightArrow} alt="" className="ml-3 scale-150" />
                    </Link>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default CareTeamCTA;
