import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//Components
import { OrdersList } from '../../components/Admin/Orders';
import { ResupplyList } from '../../components/Admin/Resupply';

const Orders = () => {
    const navigate = useNavigate();
    const { ordersTab } = useParams();

    useEffect(() => {
        if (!ordersTab) {
            navigate('list', { replace: true });
        }
    }, [navigate, ordersTab]);

    return (
        <>
            <h2 className="mb-5 pt-6 flex items-center justify-between">
                <span className="font-heading text-5xl">Orders</span>
            </h2>
            <nav className="my-4">
                <button
                    onClick={(ev) => {
                        navigate(`/admin/orders/list`, {
                            replace: true,
                        });
                    }}
                    className={`border-gray border-b py-2 min-w-[130px] ${ordersTab === 'list' ? 'text-gem-green border-gem-green! font-bold' : 'hover:text-gem-green hover:font-bold'}`}>
                    Order List
                </button>
                <button
                    onClick={(ev) => {
                        navigate(`/admin/orders/resupply`, {
                            replace: true,
                        });
                    }}
                    className={`border-gray border-b py-2 min-w-[220px] ${ordersTab === 'resupply' ? 'text-gem-green border-gem-green! font-bold' : 'hover:text-gem-green hover:font-bold'}`}>
                    Resupply Enrollments
                </button>
            </nav>
            {ordersTab === 'list' && <OrdersList />}
            {ordersTab === 'resupply' && <ResupplyList />}
        </>
    );
};

export default Orders;
