import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../../app/services/auth';
import { toggleModal } from '../../features/ui/uiSlice';

const LogoutWarning = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [logout] = useLogoutMutation();
    const { lastRefresh } = useSelector((state) => state.ui);
    const alertTimeout = useRef(null);
    const logoutTimeout = useRef(null);

    useEffect(() => {
        if (!lastRefresh) return;

        alertTimeout.current = window.setTimeout(
            () => {
                dispatch(toggleModal('logoutImminent'));
            },
            50 * 60 * 1000,
        );

        logoutTimeout.current = window.setTimeout(
            async () => {
                await logout(null);
                navigate('/sign-in');
            },
            60 * 60 * 1000,
        );

        return () => {
            window.clearTimeout(alertTimeout.current);
            window.clearTimeout(logoutTimeout.current);
        };
    }, [lastRefresh, dispatch, navigate, logout]);

    return <></>;
};

export default LogoutWarning;
