import { useNavigate } from 'react-router-dom/dist';
import { ReactComponent as CheckmarkIcon } from '../../images/icons/checkmark.svg';

function InsuranceCardDisplay({ frontId, backId }) {
    const navigate = useNavigate();

    if (frontId && backId)
        return (
            <span className="flex items-center">
                <CheckmarkIcon className="inline text-[#00AA63] align-[2px] mr-[0.3rem] scale-150" />
                <p>Insurance Card Uploaded!</p>
            </span>
        );
    else if (!frontId && !backId)
        return (
            <p className="text-sm xs:text-base">
                You haven't uploaded a picture of your insurance card. Would you like to
                <button
                    className="link ml-1"
                    onClick={() => navigate('/dashboard/insurance/upload')}>
                    upload it?
                </button>
            </p>
        );
    else
        return (
            <span className="flex items-center">
                <CheckmarkIcon className="inline text-[#00AA63] align-[2px] scale-150 w-[30px]" />
                <p className="text-sm sm:text-base">Insurance Card Uploaded!</p>
            </span>
        );
}

export default InsuranceCardDisplay;
