import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { RichTextEditor } from '../../../InputComponents';
import {
    useCreateUserNoteMutation,
    useGetUserNotesQuery,
} from '../../../../app/services/admin/admin';
import { formatDate } from '../../../../utils/constants';
import { toast } from 'react-toastify';

const notesItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1, transition: { duration: 0.5 } },
};

const NOTES_PER_PAGE = 10;

const PatientNotes = ({ patientId, itamarReports }) => {
    const [currentNote, setCurrentNote] = useState('');
    const [take, setTake] = useState(NOTES_PER_PAGE);
    const { data: userNotes, isFetching } = useGetUserNotesQuery({
        userId: patientId,
        skip: 0,
        take: take,
    });
    const [createUserNote] = useCreateUserNoteMutation();
    function isReactQuillEmpty(data) {
        return data.replace(/<(.|\n)*?>/g, '').trim().length === 0;
    }

    const noteSubmitHandler = async (ev) => {
        ev.preventDefault();

        try {
            await createUserNote({ userId: +patientId, text: currentNote });
            setCurrentNote('');
        } catch (error) {
            toast.error(error);
        }
    };
    return (
        <>
            <RichTextEditor value={currentNote} onChangeHandler={setCurrentNote} className="mb-4" />
            <button
                className="btn-primary-small"
                onClick={noteSubmitHandler}
                disabled={isReactQuillEmpty(currentNote)}>
                Save a note
            </button>

            {userNotes?.results?.length > 0 && (
                <ul className="mt-10 space-y-6">
                    <AnimatePresence>
                        {itamarReports.map((report, idx) => (
                            <motion.li
                                key={`md-note-itamar-${idx}`}
                                variants={notesItem}
                                initial="hidden"
                                animate="show"
                                exit="hidden"
                                className="card rounded-none shadow-xl">
                                <span className="mb-4 block text-xs">
                                    Added on {formatDate(report.createdAt)}
                                </span>
                                <span className="mb-4 block text-xs">
                                    By {report.interpretingPhysician}
                                </span>
                                <p
                                    className="note-content text-sm"
                                    dangerouslySetInnerHTML={{ __html: report.conclusion }}></p>
                            </motion.li>
                        ))}
                        {userNotes.results.map((note, idx) => (
                            <motion.li
                                key={`md-note-${idx}`}
                                variants={notesItem}
                                initial="hidden"
                                animate="show"
                                exit="hidden"
                                className="card rounded-none shadow-xl">
                                <span className="mb-1 block text-xs">
                                    Added on {formatDate(note.createdAt)}
                                </span>
                                <span className="mb-4 block text-xs">
                                    Electronically Signed By{' '}
                                    {`${note.userCreatedBy.firstName} ${
                                        note.userCreatedBy?.lastName
                                    } ${
                                        note.userCreatedBy?.credentials?.length > 0
                                            ? `, ${note.userCreatedBy?.credentials}`
                                            : ''
                                    }`}
                                </span>
                                <p
                                    className="note-content text-sm"
                                    dangerouslySetInnerHTML={{ __html: note.text }}></p>
                            </motion.li>
                        ))}
                    </AnimatePresence>
                </ul>
            )}
            {userNotes?.metadata && userNotes.metadata.total > NOTES_PER_PAGE && (
                <nav className="flex items-center justify-center space-x-20 py-10">
                    <button
                        onClick={() => setTake((p) => p + NOTES_PER_PAGE)}
                        className="font-heading tracking-widest text-gem-green disabled:opacity-25"
                        disabled={isFetching || take > userNotes.metadata.total}>
                        more...
                    </button>
                </nav>
            )}
        </>
    );
};

export default PatientNotes;
