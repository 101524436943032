import { api } from './auth';

const appointmentsAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        cancelAppointment: builder.mutation({
            query: ({ healthieId, isRescheduling }) => ({
                url: `/appointments/appointment/${healthieId}/cancel?isRescheduling=${isRescheduling}`,
                method: 'PUT',
            }),
            invalidatesTags: (result) => ['User'],
        }),
        getAvailablePractitioners: builder.query({
            query: (body) => ({
                url: '/appointments/practitioners',
                method: 'POST',
                body: body,
            }),
        }),
        scheduleAppointment: builder.mutation({
            query: (payload) => ({
                url: '/appointments/appointment',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result) => ['Cart', 'User'],
        }),
        getAppointmentTypes: builder.query({
            query: (body) => ({
                url: '/appointments/appointmentTypes',
                method: 'GET',
                body: body,
            }),
        }),
        getAvailibility: builder.query({
            query: () => ({
                url: '/appointments/availability',
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useCancelAppointmentMutation,
    useGetAvailablePractitionersQuery,
    useScheduleAppointmentMutation,
    useGetAppointmentTypesQuery,
    useGetAvailibilityQuery,
} = appointmentsAPI;
