import { useField } from 'formik';
import { useState } from 'react';
import { ReactComponent as HiddenIcon } from '../../images/password-hide.svg';
import { ReactComponent as ShownIcon } from '../../images/password-show.svg';

const PasswordRevealButton = ({ customType, toggle }) => {
    return (
        <div className="absolute right-0 top-5 -translate-y-1/2" onClick={toggle}>
            {customType === 'password' ? <ShownIcon /> : <HiddenIcon />}
        </div>
    );
};

const CustomInput = ({
    label,
    type = 'text',
    theme = 'dark',
    className,
    rootClass,
    showLabel,
    ...props
}) => {
    const [field, meta] = useField(props);
    const [customType, setCustomType] = useState(type);

    return (
        <div className={rootClass + (type === 'hidden' ? ' hidden' : '')}>
            <div
                className={`relative border-b transition duration-200 ease-out-quad ${
                    theme === 'light' ? 'border-gray-darker' : 'border-white'
                } ${field.value ? 'border-opacity-100' : 'border-opacity-25'} ${
                    !props.disabled && 'hover:border-opacity-100'
                } ${className}`}>
                {showLabel && (
                    <label htmlFor={props.id} className="text-xs text-gray-six lg:text-sm">
                        {label}
                    </label>
                )}
                <input
                    className={`block w-full appearance-none bg-transparent pb-4 font-semibold placeholder:text-xs placeholder:font-normal focus:outline-none lg:text-lg lg:placeholder:text-base ${
                        theme === 'dark'
                            ? 'text-offwhite placeholder:text-placeholder'
                            : 'text-black placeholder:text-black/30'
                    }  ${
                        meta.touched && meta.error
                            ? 'border-red border-opacity-100 focus-within:border-red'
                            : 'focus-within:border-blue'
                    }`}
                    placeholder={showLabel ? '' : label}
                    type={customType}
                    {...field}
                    {...props}
                />
                {type === 'password' && (
                    <PasswordRevealButton
                        customType={customType}
                        toggle={() =>
                            setCustomType((t) => (t === 'password' ? 'text' : 'password'))
                        }
                    />
                )}
            </div>
            {meta.touched && meta.error && (
                <p className="mt-2 text-xs font-bold text-red">{meta.error}</p>
            )}
        </div>
    );
};

export default CustomInput;
