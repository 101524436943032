import { useSelector } from 'react-redux';
import { useGetPayersQuery } from '../../app/services/insurance';
import { useState } from 'react';
import { insuranceCreateSchema } from '../../utils/schemas';
import { useNavigate } from 'react-router-dom';
import InsuranceForm, { FormType } from '../../components/Insurance/InsuranceForm';
import { useInsuranceContext } from '../../components/Insurance/InsuranceContext';
import { PageHeaderWithBack } from '../../components/LayoutComponents';
import { dateToISOString, formatDate } from '../../utils/constants';

const UpdateInsurance = () => {
    const { insurance, updatePatientCurrentInsurance } = useInsuranceContext();
    const { user } = useSelector((state) => state.auth);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();

    const { data: payers } = useGetPayersQuery();

    const initialValues = {
        payer: insurance.payerId ?? null,
        memberId: insurance?.memberId || '',
        groupId: insurance?.groupId || '',
        patientFirstName: user?.firstName ?? '',
        patientLastName: user?.lastName ?? '',
        patientDob: user ? formatDate(user.birthdate) : '',
        policyHolderFirstName: user?.firstName ?? '',
        policyHolderLastName: user?.lastName ?? '',
        policyHolderDob: user ? formatDate(user.birthdate) : '',
        policyHolderRelationship: insurance?.policyHolderRelationship || 'Self',
    };

    const insuranceSubmitHandler = async (values) => {
        const changedValues = {};

        Object.keys(values)
            .filter((key, i) => values[key] !== initialValues[key])
            .forEach((k) => {
                if (k === 'policyHolderDob' || k === 'patientDob') {
                    changedValues[k] = dateToISOString(values[k]);
                } else if (k === 'payer') {
                    changedValues[k] = values[k];
                } else {
                    changedValues[k] = values[k];
                }
            });
        // if they try again without modification, we need to set the insurance status back to review
        const updateInsuranceResult = await updatePatientCurrentInsurance(
            user.id,
            insurance?.id,
            changedValues,
        );

        if (!updateInsuranceResult?.status === 'success') {
            setErrorMessage(updateInsuranceResult.data?.message);
        } else {
            navigate('/dashboard/insurance');
        }
    };

    if (!payers || !user || !insurance) {
        return (
            <p className="h-screen font-heading text-[44px] font-bold text-gray-six lg:text-7xl">
                Loading...
            </p>
        );
    } else
        return (
            <>
                <PageHeaderWithBack
                    onBack={() => navigate('/dashboard/insurance')}
                    title="Update Insurance"
                />

                {errorMessage && <p className="text-lg font-bold text-red">{errorMessage}</p>}

                <InsuranceForm
                    targetInsurance={insurance}
                    formType={FormType.UPDATE}
                    user={user}
                    buttonCopy={`Update`}
                    validationSchema={insuranceCreateSchema}
                    onSubmit={insuranceSubmitHandler}
                />
            </>
        );
};

export default UpdateInsurance;
