import { createContext, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import { useNavigate } from 'react-router-dom';
import { setConsultRequired, toggleInsurance } from '../../features/cart/cartSlice';
import { useInsuranceContext } from '../Insurance/InsuranceContext';
import posthog from 'posthog-js';

export const CheckoutContext = createContext(null);

export function CheckoutContextProvider({ children }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { insurance } = useInsuranceContext();

    //redux state selectors
    const { isConsultOrder, hstInCart, stateRequiredTelehealth } = useSelector(
        (state) => state.cart,
    );

    // Checkout step logic
    const steps = {
        //These steps are for insurance review before payment
        'checking-eligibility': { backButtonActive: false },
        'insurance-review': { backButtonActive: true },
        'insurance-details': { backButtonActive: true },
        'insurance-card-question': { backButtonActive: true },
        'insurance-card': { backButtonActive: true },
        'not-found': { backButtonActive: true },
        'not-eligible': { backButtonActive: false },
        'is-eligible': { backButtonActive: false },
        //The following steps occur after insurance review
        'insurance-alternative': { backButtonActive: false },
        'insurance-alternative-check': { backButtonActive: false },
        'payment-information': { backButtonActive: false },
        'required-consult-booking': { backButtonActive: false },
        'consult-booking': { backButtonActive: false },
        'address-page': { backButtonActive: false },
        'submitting-payment': { backButtonActive: false },
        'checkout-success': { backButtonActive: false },
    };
    const [checkoutStep, setCheckoutStep] = useState();

    // Other local state context
    const [selfpayAlertOpen, setSelfpayAlertOpen] = useState(false);

    function finishInsuranceCheck({ routeToRequiredBooking }) {
        if (routeToRequiredBooking) {
            setCheckoutStep('required-consult-booking');
        } else if (isConsultOrder) {
            setCheckoutStep('consult-booking');
        } else {
            setCheckoutStep('address-page');
        }
    }

    function skipInsurance({ showAlert }) {
        dispatch(setConsultRequired(stateRequiredTelehealth && hstInCart));
        if (hstInCart && showAlert) {
            setSelfpayAlertOpen(true);
        } else {
            dispatch(toggleInsurance(false));
            finishInsuranceCheck({ routeToRequiredBooking: stateRequiredTelehealth && hstInCart });
        }
        posthog?.capture('Continue without Insurance');
    }

    const contextValue = {
        steps,
        checkoutStep,
        finishInsuranceCheck,
        skipInsurance,
        setCheckoutStep,
    };

    //Set consult required for HST if closing back out
    function closeHandler() {
        setSelfpayAlertOpen(false);
        dispatch(
            setConsultRequired(insurance?.payer?.consultRequiredForHST || stateRequiredTelehealth),
        );
    }

    return (
        <CheckoutContext.Provider value={contextValue}>
            <>
                {children}
                <Modal
                    showClose={true}
                    isOpen={selfpayAlertOpen}
                    closeHandler={() => closeHandler()}>
                    <div className="space-y-4 text-gray-darker">
                        <h2 className="px-8 font-heading text-3xl xs:text-5xl lg:text-7xl text-center">
                            Alert!
                        </h2>
                        <p>
                            You are selecting to not use your insurance and continue with self pay.
                            If you continue with this selection, you may also not be able to use
                            insurance for sleep apnea treatments in the future.
                        </p>
                        <button
                            className="btn-primary w-full"
                            onClick={() => {
                                dispatch(toggleInsurance(false));
                                //Navigate to checkout if not already on checkout
                                navigate('/dashboard/checkout');
                                setSelfpayAlertOpen(false);
                                finishInsuranceCheck({
                                    routeToRequiredBooking: stateRequiredTelehealth && hstInCart,
                                });
                            }}>
                            Continue Without Insurance
                        </button>
                        <button className="btn-shell w-full" onClick={() => closeHandler()}>
                            Go Back
                        </button>
                    </div>
                </Modal>
            </>
        </CheckoutContext.Provider>
    );
}

export function useCheckoutContext() {
    const context = useContext(CheckoutContext);
    if (!context) {
        throw new Error('Checkout Context must be used within CheckoutContextProvider');
    }
    return context;
}
