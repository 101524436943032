function SupplyStoreCallout() {
    return (
        <div className="mb-7 lg:flex">
            <div className="relative flex-1 bg-black p-9 text-center lg:p-4 lg:text-left">
                <span className="font-bold text-white lg:absolute lg:top-1/2 lg:left-4 lg:-translate-y-1/2 lg:text-lg">
                    Looking for something not listed in your resupply list?
                </span>
            </div>
            <a
                href="https://gem-sleep.square.site/"
                target="_blank"
                rel="noreferrer"
                className="btn-primary w-full lg:w-[250px]">
                Visit our Supply Store
            </a>
        </div>
    );
}
export default SupplyStoreCallout;
