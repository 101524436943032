import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsArrowDown } from 'react-icons/bs';

//Queries and Mutations
import { useGetPaginatedReferralsQuery } from '../../../app/services/admin/referrals';
import { useGetPartnerOrganizationsQuery } from '../../../app/services/insurance';

//Components
import Pagination from '../Pagination';
import ReferralsFilters from './ReferralsFilters';
import ReferralListItem from './ReferralsListItem';

import { setStatusFilter } from '../../../features/admin/adminSlice';

const columnLabels = ['User', 'Created', 'Updated', 'Sent', 'Status', 'Organization'];
const sortableColumns = ['Created', 'Updated', 'Sent'];

const gridStyling =
    'grid grid-rows grid-cols-[150px_100px_100px_130px_1fr_1fr] xl:grid-cols-[200px_100px_100px_130px_160px_160px_1fr_1fr_] gap-x-2 items-center';

const ReferralsList = () => {
    const dispatch = useDispatch();

    //Local State
    const [page, setPage] = useState(0);

    //Redux state values
    const { activeReferralFilters } = useSelector((state) => state.admin);

    //Queries and Mutations
    const { data: partners, isLoading: partnersLoading } = useGetPartnerOrganizationsQuery({
        paginated: false,
    });
    const { data: referrals, isLoading: referralsLoading } = useGetPaginatedReferralsQuery({
        skip: page * 10,
        ...activeReferralFilters,
    });

    const dmeReferrers = partners?.results?.filter(
        (partner) => partner.organizationType === 'DME_PROVIDER',
    );

    return partnersLoading || referralsLoading ? (
        <p>Loading...</p>
    ) : (
        <>
            <ReferralsFilters partners={partners} setPage={setPage} />
            <div className={`${gridStyling} border-t border-gray pt-4 max-w-[1400px]`}>
                {columnLabels.map((label) => (
                    <p
                        key={`referrals-label-${label}`}
                        className={`flex items-center text-sm gap-x-1 
                            ${activeReferralFilters.orderByKey === label ? 'text-gem-green font-bold' : 'text-gray'} 
                            ${
                                sortableColumns.includes(label) &&
                                'hover:text-gem-green hover:cursor-pointer'
                            }`}
                        onClick={() => {
                            if (label === activeReferralFilters.orderByKey) {
                                dispatch(
                                    setStatusFilter({
                                        type: 'activeReferralFilters',
                                        key: 'orderDesc',
                                        value: !activeReferralFilters.orderDesc,
                                    }),
                                );
                            } else {
                                sortableColumns.includes(label) &&
                                    dispatch(
                                        setStatusFilter({
                                            type: 'activeReferralFilters',
                                            key: 'orderByKey',
                                            value: label,
                                        }),
                                    );
                            }
                        }}>
                        {label}
                        <BsArrowDown
                            className={` text-white 
                                ${activeReferralFilters.orderByKey === label ? 'block' : 'hidden'}
                                ${
                                    activeReferralFilters.orderDesc &&
                                    activeReferralFilters.orderByKey
                                        ? 'rotate-180'
                                        : 'rotate-0'
                                }`}
                        />
                    </p>
                ))}
                {referrals?.results?.length > 0 ? (
                    referrals.results.map((referral) => (
                        <ReferralListItem
                            columns={columnLabels.length}
                            gridStyling={gridStyling}
                            referral={referral}
                            partners={dmeReferrers}
                        />
                    ))
                ) : (
                    <p className="font-heading tracking-widest text-gray my-2">No referrals</p>
                )}
            </div>
            {referrals?.metadata.total > 10 && (
                <Pagination
                    page={page}
                    disabled={referralsLoading}
                    setPage={setPage}
                    totalPages={Math.floor(referrals.metadata.total / 10)}
                />
            )}
        </>
    );
};

export default ReferralsList;
