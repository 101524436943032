import { motion } from 'framer-motion';

const Bundles = () => {
  return (
    <motion.div className="space-y-10" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <h1>Bundles</h1>
    </motion.div >
  )
};

export default Bundles;