import { determineItemCategoryTags, formatDate, formatSquarePrice } from '../../../utils/constants';

function CartInformation({ cart }) {
    const itemTypes = determineItemCategoryTags(cart);

    return (
        <div className="grid grid-cols-[180px_1fr_180px_1fr] gap-4 py-4 pl-2">
            <p>Order Id:</p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">{cart?.id}</p>

            <p>Last Updated:</p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                {formatDate(cart?.updatedAt)}
            </p>

            <p>Items in Order:</p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                {cart?.orderItems?.length}
            </p>

            <p>Order Type:</p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">{cart?.orderType}</p>

            <p>Item Types:</p>
            <p>{itemTypes}</p>

            <p>Order Status:</p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">{cart?.orderStatus}</p>

            <p>Discount Amount:</p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                {formatSquarePrice(cart?.totalDiscount)}
            </p>

            <p>Total Price:</p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                {formatSquarePrice(cart?.total)}
            </p>

            <p className="col-start-1">Discount Code:</p>
            <p className="whitespace-nowrap overflow-hidden text-ellipsis">{cart?.discountCode}</p>
        </div>
    );
}

export default CartInformation;
