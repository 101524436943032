import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import { FaCheck } from 'react-icons/fa';
import {
    setMaskType,
    setSelectedProduct,
    setSelectedVariation,
} from '../../features/catalog/catalogSlice';
import { toggleModal } from '../../features/ui/uiSlice';
import MaskOptionBuilder from './MaskOptionBuilder';
import Modal from '../Modal/Modal';

function CustomOption({
    brand,
    cpapMachineName,
    maskData,
    maskType,
    onReset,
    recommendedMaskType,
    selectedTab,
}) {
    const dispatch = useDispatch();
    const { recommendedSku, selectedVariation } = useSelector((state) => state.catalog);
    const { modalsOpen } = useSelector((state) => state.ui);

    const maskTypeChangeHandler = (ev) => {
        dispatch(setSelectedProduct(null));
        dispatch(setSelectedVariation(null));
        dispatch(setMaskType(ev.target.value));
    };

    return (
        <>
            <div
                className={`${
                    selectedTab === 'custom' ? 'block opacity-100' : 'hidden opacity-0'
                } transition duration-300 ease-out-quad`}>
                <p className="mb-5 bg-purple-lightest/50 p-5 text-xs lg:mb-8 lg:text-sm">
                    <b>Are you sure?</b> GEM recommendations are the best way to get the most
                    accurate fit and styles and ensure the best CPAP experience.
                </p>
                <div className="mb-4 bg-white p-5">
                    <h5 className="mb-3 text-sm font-semibold lg:mb-4 lg:text-lg">CPAP machine</h5>
                    <div className="lg:flex lg:items-center">
                        <p className="relative lg:mr-9 lg:flex-1">{cpapMachineName}</p>
                        <button
                            className="border-b border-gem-green text-xs font-bold"
                            onClick={() => {
                                dispatch(toggleModal('cpapDetails'));
                            }}>
                            Details
                        </button>
                    </div>
                </div>

                <div className="mb-4 bg-white">
                    <h5 className="mb-3 px-5 pt-5 text-sm lg:mb-4 lg:text-base">
                        Select a mask style:
                    </h5>
                    <ul className="divide-y divide-gray-light">
                        {maskData.map((item, idx) => (
                            <li
                                key={`mask-type-${item.type}`}
                                className={`p-5 ${
                                    idx === 0 ? 'pt-0' : ''
                                } space-y-3 overflow-hidden lg:space-y-4`}>
                                <div className="relative flex items-center">
                                    <input
                                        type="radio"
                                        name="maskType"
                                        value={item.type}
                                        checked={maskType === item.type}
                                        onChange={maskTypeChangeHandler}
                                        className="float-left mr-2 h-6 w-6 flex-shrink-0 cursor-pointer appearance-none border-4 border-green-400 bg-transparent bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-green-600 checked:bg-green-600 focus:outline-none"
                                    />
                                    <FaCheck className="pointer-events-none absolute left-3 w-2 -translate-x-1/2 scale-150 text-white opacity-0 transition" />
                                    <span className="text-sm font-bold lg:text-lg">
                                        {item.title}
                                    </span>
                                    {recommendedMaskType === item.type && (
                                        <span className="ml-4 inline-block bg-purple-lightest px-1 font-heading text-sm tracking-widest">
                                            recommended for you
                                        </span>
                                    )}
                                </div>

                                <p className="text-xs lg:text-sm">{item.blurb}</p>

                                <AnimatePresence initial={false}>
                                    {maskType === item.type && (
                                        <MaskOptionBuilder
                                            maskCategoryAttributeValue={
                                                item.maskCategoryAttributeValue
                                            }
                                            brand={brand}
                                        />
                                    )}
                                </AnimatePresence>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="text- mb-4 bg-white p-5">
                    <h5 className="mb-3 flex items-center justify-between text-sm font-semibold lg:mb-4 lg:text-lg">
                        <span>90-Day Supply Replacement Parts</span>

                        <button
                            className="border-b border-gem-green text-xs font-bold"
                            type="button"
                            onClick={() => {
                                dispatch(toggleModal('90day'));
                            }}>
                            Details
                        </button>
                    </h5>
                    <p>Order includes the necessary replacement parts for 90-days.</p>
                </div>
            </div>

            <button
                className={`block mb-1 border-b border-gem-green text-xs font-bold transition ease-out-quad lg:text-sm ${
                    selectedVariation?.itemVariationData.sku === recommendedSku
                        ? 'pointer-events-none opacity-0'
                        : ''
                }`}
                type="button"
                onClick={() => {
                    dispatch(toggleModal('resetRecommendations'));
                }}>
                Reset selections
            </button>

            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('resetRecommendations') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('resetRecommendations'));
                }}>
                <div className="mb-6 space-y-4 text-gray-darker">
                    <h3 className="font-heading text-4xl lg:text-7xl">
                        Go back to original recommendations?
                    </h3>
                    <p>The modifications you've made to the selections will be lost.</p>
                </div>
                <nav className="flex items-center space-x-5">
                    <button
                        className="btn-primary flex-1"
                        onClick={() => {
                            onReset();
                            dispatch(toggleModal('resetRecommendations'));
                        }}>
                        Yes, i'm sure
                    </button>
                    <button
                        className="btn-shell flex-1"
                        onClick={() => {
                            dispatch(toggleModal('resetRecommendations'));
                        }}>
                        Just kidding, cancel
                    </button>
                </nav>
            </Modal>
        </>
    );
}

export default CustomOption;
