import { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate, referralStatuses } from '../../../utils/constants';
import { useUpdateReferralMutation } from '../../../app/services/admin/referrals';

function ReferralListItem({ columns, gridStyling, partners, patientView, referral }) {
    const navigate = useNavigate();

    const initialValues = {
        organizationId: referral.organizationId,
        referralSentAt: referral.referralSentAt,
        referralStatus: referral.referralStatus,
        referralType: 'DME',
    };

    //Mutations
    const [updateReferral] = useUpdateReferralMutation();

    //Local State
    const [values, setValues] = useState(initialValues);

    //Calculated Values
    //Track changed values for submission and ability to update/reset
    const changedValues = {};

    if (values) {
        Object.keys(values)
            .filter((key, i) => values[key] !== referral[key])
            .forEach((k) => {
                changedValues[k] = values[k];
            });
    }

    const canUpdate = Object.keys(changedValues).length > 0;

    return (
        <form
            key={'referral-' + referral.id}
            className={`col-span-${columns} xl:col-span-${columns + 2} ${gridStyling} py-2 max-w-[1600px]`}
            method="post"
            onReset={() => setValues(initialValues)}
            onSubmit={async (e) => {
                e.preventDefault();
                const update = await updateReferral({
                    id: referral.id,
                    data: { ...changedValues },
                });
                if (update) {
                    toast.success('Referral Updated');
                } else {
                    toast.error('There was a problem updating the referral');
                }
            }}>
            {!patientView && (
                <p
                    className="hover:cursor-pointer max-w-[200px] whitespace-nowrap overflow-hidden text-ellipsis"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(`/admin/patients/${referral.userId}/treatment`);
                    }}>
                    {referral.user.firstName + ' ' + referral.user.lastName}
                </p>
            )}
            <p>{formatDate(referral.createdAt)}</p>
            <p>{formatDate(referral.updatedAt)}</p>
            <div>
                <DatePicker
                    key={'referralSentAt-' + referral.id}
                    isClearable
                    className="appearance-none border border-gray rounded bg-transparent p-2 text-sm text-white w-[130px]"
                    selected={values.referralSentAt ? new Date(values.referralSentAt) : null}
                    onChange={(date) => {
                        setValues({ ...values, referralSentAt: date });
                    }}
                />
            </div>
            <select
                key={'referral-' + referral.id + '-status'}
                className="w-full rounded-md text-black h-full"
                multiple={false}
                value={values.referralStatus}
                name="referralStatus"
                onChange={(e) => setValues({ ...values, referralStatus: e.target.value })}>
                {referralStatuses.map((status, idx) => (
                    <option key={`status-${idx}`} value={status}>
                        {status}
                    </option>
                ))}
            </select>
            <select
                key={'referral-' + referral.id + '-org-id'}
                className="w-full rounded-md text-black h-full"
                multiple={false}
                value={values.organizationId}
                name="organizationId"
                onChange={(e) =>
                    setValues({
                        ...values,
                        organizationId: e.target.value.length > 0 ? +e.target.value : null,
                    })
                }>
                <>
                    <option value="">None</option>
                    {partners?.map((partner, idx) => (
                        <option key={`organization-${idx}`} value={partner.id}>
                            {partner.name}
                        </option>
                    ))}
                </>
            </select>
            {canUpdate && (
                <>
                    <button type="submit" className="btn-primary-small !py-1 w-[150px]">
                        Update
                    </button>
                    <button
                        type="reset"
                        onClick={() => setValues({ ...referral })}
                        className="btn-secondary-small !py-1 w-[150px]">
                        Reset
                    </button>
                </>
            )}
        </form>
    );
}

export default ReferralListItem;
