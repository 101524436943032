import { motion } from 'framer-motion';
import OrderItem from './OrderItem';

const gridLayout = 'grid-cols-[minmax(500px,1fr)_100px_200px_100px_100px_50px_50px_50px]';

function OrderItems({ patientId, order }) {
    return (
        <motion.div
            key={`order-item-list-${order?.id}`}
            className="w-[98%] m-auto"
            initial="hide"
            animate="show"
            exit="hide"
            transition={{ ease: 'circOut' }}>
            {order?.orderItems.length > 0 ? (
                <>
                    <div className={`grid ${gridLayout} w-full py-3`}>
                        <span className="text-sm text-gray">Name</span>
                        <span className="text-sm text-gray">Supply Qty</span>
                        <span className="text-sm text-gray">SKU</span>
                        <span className="pl-2 text-sm text-gray">Quantity</span>
                        <span className="pl-2 text-sm text-gray">Price</span>
                        <span className="text-sm text-gray">Save</span>
                        <span className="text-sm text-gray">Edit</span>
                        <span className="text-sm text-gray">Del.</span>
                    </div>
                    {order?.orderItems.map((item) => (
                        <OrderItem
                            gridLayout={gridLayout}
                            key={item.id}
                            orderId={order?.id}
                            isCart={order.orderStatus === 'CART'}
                            item={item}
                            patientId={patientId}
                        />
                    ))}
                </>
            ) : (
                <p className="text-xl text-salmon py-4">There are no items in this order</p>
            )}
        </motion.div>
    );
}

export default OrderItems;
