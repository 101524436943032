import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//Icons
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FaEdit } from 'react-icons/fa';

//Components
import { HorizontalLine } from '../../LayoutComponents';
import OrganizationInfo from './OrganizationInfo';
import OrganizationListFilters from './OrganizationListFilters';

//Other
import { convertEnumValueToDisplayString } from '../../../utils/constants';
import { useGetPartnerOrganizationsQuery } from '../../../app/services/insurance';

const getPartnerTypeColorClass = (partner) => {
    switch (partner.organizationType) {
        case 'DENTAL':
            return 'text-gem-green';
        case 'PAYER':
            return 'text-gem-purple';
        case 'INSURANCE_ALTERNATIVE':
            return 'text-gem-blue';
        case 'DME_PROVIDER':
            return 'text-orange';
        default:
            return 'text-white';
    }
};

const initialFilters = { name: '', organizationType: null };

const OrganizationList = () => {
    //Queries and Mutations
    const { data: partners, isLoading } = useGetPartnerOrganizationsQuery({ paginated: false });

    //Local State
    const [expandedPartner, setExpandedPartner] = useState(null);
    const [filters, setFilters] = useState(initialFilters);
    const [sortedPartners, setSortedPartners] = useState(
        partners?.results?.length > 0 ? [...partners?.results] : [],
    );

    useEffect(() => {
        if (!filters.name.length > 0 && !filters.organizationType) {
            setSortedPartners(partners?.results?.length > 0 ? [...partners?.results] : []);
        } else {
            const partnersToSort = partners?.results?.length > 0 ? [...partners?.results] : [];

            setSortedPartners(
                partnersToSort
                    .filter((partner) =>
                        partner.name.toLowerCase().includes(filters.name.toLowerCase()),
                    )
                    .filter(
                        (partner) =>
                            !filters.organizationType ||
                            partner.organizationType === filters.organizationType,
                    ),
            );
        }
    }, [filters, partners?.results, setSortedPartners]);

    return isLoading ? (
        <p className="py-6 font-heading text-5xl">Loading...</p>
    ) : (
        <div className="items-center">
            <OrganizationListFilters
                filters={filters}
                initialFilters={initialFilters}
                setFilters={setFilters}
            />
            {sortedPartners.map((partner, idx) => (
                <>
                    <div className="flex space-x-4" key={'partner-' + partner.id}>
                        <button
                            onClick={() =>
                                setExpandedPartner(
                                    expandedPartner !== partner.id ? partner.id : null,
                                )
                            }>
                            {expandedPartner !== partner.id ? <FaChevronDown /> : <FaChevronUp />}
                        </button>

                        <div className="flex space-x-3">
                            <p className="w-[20px] text-gray">{partner.id}</p>
                            <p className="w-[250px] whitespace-nowrap overflow-hidden text-ellipsis ">
                                {partner.name}
                            </p>
                            <p className={`w-[200px] ${getPartnerTypeColorClass(partner)}`}>
                                {convertEnumValueToDisplayString(partner.organizationType)}
                            </p>
                            <Link to={`/admin/organizations/update/${partner.id}`}>
                                <FaEdit
                                    className="text-gray text-lg hover:text-white hover:cursor-pointer mr-2"
                                    onClick={() => {}}
                                />
                            </Link>
                        </div>
                    </div>
                    {expandedPartner === partner.id && <OrganizationInfo partner={partner} />}
                    <HorizontalLine styling="my-2 text-gray-dark" />
                </>
            ))}
        </div>
    );
};

export default OrganizationList;
