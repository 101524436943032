import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../features/ui/uiSlice';

//Images
import { FaCheck } from 'react-icons/fa';
import { ReactComponent as PhoneIcon } from '../../images/icons/phone.svg';

//Components
import ProductImage from '../ProductImage/ProductImage';

function CPAPOrdered() {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { recommendedSku } = useSelector((state) => state.catalog);

    const cpapOrderedButNotDelivered = [
        'SUPPLIES_ORDERED',
        'SHIPPED',
        'FULFILLMENT_ORDER',
    ].includes(user?.patientProfile?.cpapOrderStatus);

    if (cpapOrderedButNotDelivered) {
        return (
            <div className="relative flex flex-col justify-start w-full bg-white sm:flex-row items-center gap-4 p-8 sm:gap-16 mb-4 border border-gem-green">
                <div className="pointer-events-none absolute top-0 right-0 z-10 inline-block w-11 overflow-hidden transition">
                    <div className="relative h-16 origin-top-left -rotate-45 transform bg-gem-green">
                        <FaCheck className="absolute left-3 top-5 origin-top-left rotate-45 text-white" />
                    </div>
                </div>

                <ProductImage
                    sku={recommendedSku}
                    alt="A CPAP bundle mask"
                    className="relative w-9/10 m-0-auto h-auto sm:w-[240px]"
                />

                <div className="w-full sm:w-3/4 max-w-xl">
                    <span className="mb-3 inline-block px-1 font-heading text-sm tracking-widest bg-green-200">
                        Ordered
                    </span>
                    <h5 className="mb-3 font-heading text-4xl lg:text-5xl">
                        GEM SLEEP CPAP STARTER BUNDLE
                    </h5>
                    <p className="mb-4 text-[13px] leading-normal lg:text-base">
                        All the items you need to get started on CPAP, including a 90-day supply of
                        replacement parts, delivered directly to your door!
                    </p>
                    <div className="items-center space-y-4 lg:flex lg:space-y-0 lg:space-x-4">
                        <button
                            className="btn-shell flex w-full items-center justify-between space-x-4 xl:w-[280px] max-h-[68px]"
                            onClick={(ev) => {
                                dispatch(toggleModal('contact'));
                            }}>
                            <span>Talk to someone</span>
                            <PhoneIcon />
                        </button>
                    </div>
                </div>
            </div>
        );
    } else
        return (
            <div className="relative flex flex-col justify-start w-full bg-white sm:flex-row items-center gap-4 p-8 sm:gap-16 mb-4">
                <ProductImage
                    sku={recommendedSku}
                    alt="A CPAP bundle mask"
                    className="relative w-9/10 m-0-auto h-auto sm:w-[240px]"
                />
                <div className="w-full sm:w-3/4 max-w-xl">
                    <span className="mb-3 inline-block px-1 font-heading text-sm tracking-widest bg-purple-lightest">
                        Ordered
                    </span>
                    <h5 className="mb-3 font-heading text-4xl lg:text-5xl">
                        GEM SLEEP CPAP STARTER BUNDLE
                    </h5>
                    <p className="mb-4 text-[13px] leading-normal lg:text-base">
                        All the items you need to get started on CPAP, including a 90-day supply of
                        replacement parts, delivered directly to your door!
                    </p>
                    <div className="items-center space-y-4 lg:flex lg:space-y-0 lg:space-x-4">
                        <button
                            className="btn-primary flex items-center w-full xl:w-[280px] max-h-[68px] "
                            onClick={() => {
                                dispatch(toggleModal('maskFitLinkPending'));
                            }}>
                            Pending size review
                        </button>

                        <button
                            className="btn-shell flex w-full items-center justify-between space-x-4 xl:w-[280px] max-h-[68px]"
                            onClick={(ev) => {
                                dispatch(toggleModal('contact'));
                            }}>
                            <span>Talk to someone</span>
                            <PhoneIcon />
                        </button>
                    </div>
                </div>
            </div>
        );
}

export default CPAPOrdered;
