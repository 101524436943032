import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useGetAdminUsersQuery } from '../../app/services/admin/admin';
// Show disabled users with opacity 0.3
const Administrators = () => {
    const adminUserQuery = [
        { userRoles: { some: { role: 'MD' } } },
        { userRoles: { some: { role: 'COACH' } } },
    ];
    const { data, isLoading, isFetching } = useGetAdminUsersQuery(adminUserQuery);

    const rowVariants = {
        hide: { opacity: 0, x: '2%' },
        show: (userActive) => ({ opacity: userActive ? 1 : 0.3, x: '0%' }),
    };

    return (
        <div className="py-6">
            <h2 className="mb-5 flex items-center justify-between">
                <span className="font-heading text-5xl">Administrators</span>
                <Link to="create-user" className="border-b-2 border-gem-green font-bold">
                    Add Member
                </Link>
            </h2>
            <ul
                className={`${
                    isFetching || isLoading ? 'opacity-0' : 'opacity-100'
                } min-w-[1000px]  gap-4 pb-10 text-sm transition`}>
                <li className="flex items-end">
                    <p className="flex-1 border-b border-purple-light/30 py-4 px-4 text-sm text-gray">
                        Name
                    </p>
                    <p className="flex-1 border-b border-purple-light/30 py-4 px-4 text-sm text-gray">
                        Email
                    </p>
                    <p className="flex-1 border-b border-purple-light/30 py-4 px-4 text-sm text-gray">
                        Roles
                    </p>
                </li>
                {data?.results.length > 0 ? (
                    data?.results.map((user, idx) => (
                        <motion.li
                            key={`user-${user.id}`}
                            className="relative flex items-end hover:cursor-pointer"
                            custom={user.isActive}
                            variants={rowVariants}
                            initial="hide"
                            animate="show"
                            exit="hide"
                            transition={{ ease: 'circOut', delay: 0.05 * idx }}>
                            <Link
                                to={`${user.id}`}
                                className="absolute-center block h-full w-full"></Link>
                            <p className="flex-1 border-b border-purple-light/30 py-4 px-4">
                                <span className="border-b-2 border-green-400 font-bold">{`${user.firstName} ${user.lastName}`}</span>
                            </p>
                            <p className="flex-1 border-b border-purple-light/30 py-4 px-4">
                                {user.email}
                            </p>
                            <p className="flex-1 border-b border-purple-light/30 py-4 px-4">
                                {user.userRoles.map((role) => role.role).join(', ')}
                            </p>
                        </motion.li>
                    ))
                ) : (
                    <li className="font-heading text-3xl py-4 px-4">No administrators found.</li>
                )}
            </ul>
        </div>
    );
};

export default Administrators;
