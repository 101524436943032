import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

//Components
import CoachingResult from './CoachingResult';
import HasSleepApnea from './HasSleepApnea';
import HighRisk from './HighRisk';
import NoSleepApnea from './NoApnea';

const AssessmentResult = () => {
    const posthog = usePostHog();

    const { answers, hasHighRisk, hasSleepApnea } = useSelector((state) => state.assessment);

    const isCoachingPath = answers.howBroughtToGem === 'STRUGGLE_WITH_CPAP';

    useEffect(() => {
        if (
            Array.isArray(answers.highRiskSelections) &&
            answers.highRiskSelections.length > 0 &&
            answers.highRiskSelections.indexOf('NONE') < 0 &&
            hasSleepApnea
        ) {
            posthog?.capture('Received Assessment Result', { result: 'Apnea - high risk' });
            ReactGA.event({
                category: 'User',
                action: 'Got assessment result',
                label: 'Apnea - high risk',
            });
        } else if (hasSleepApnea) {
            posthog?.capture('Received Assessment Result', { result: 'Apnea' });
            ReactGA.event({
                category: 'User',
                action: 'Got assessment result',
                label: 'Apnea',
            });
        } else {
            posthog?.capture('Received Assessment Result', { result: 'No Apnea' });
            ReactGA.event({
                category: 'User',
                action: 'Got assessment result',
                label: 'No Apnea',
            });
        }
        // eslint-disable-next-line
    }, []);

    // Show one of four outcomes from assessment
    if (hasHighRisk) {
        return <HighRisk />;
    } else if (isCoachingPath) {
        return <CoachingResult />;
    } else if (hasSleepApnea) {
        return <HasSleepApnea />;
    } else if (!hasSleepApnea) {
        return <NoSleepApnea />;
    }
};

export default AssessmentResult;
