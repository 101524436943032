import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useGetBlogPostsQuery } from '../../app/services/webflow';
import { setSupportFilters } from '../../features/ui/uiSlice';
import { ReactComponent as FilterButtonIcon } from '../../images/icons/filter-button.svg';

const BlogFilter = ({ filterKey, title, items, isOpen = false, onOpen, onClose }) => {
    const dispatch = useDispatch();
    const { supportFilters } = useSelector((state) => state.ui);
    const { data: blogPosts } = useGetBlogPostsQuery();
    const [currentFilters, setCurrentFilters] = useState([]);

    const activeStyle = {
        backgroundColor: 'rgb(16, 126, 64)',
        borderColor: 'rgb(16, 126, 64)',
    };

    useEffect(() => {
        if (isOpen) {
            // reset the filters in local scope to match what's in the store. Filters only get applied when the user clicks "Apply"
            setCurrentFilters([...supportFilters[filterKey]]);
        }
    }, [filterKey, onOpen, supportFilters, isOpen]);

    return (
        <div className="relative">
            <button
                className="flex items-center space-x-2 p-3 font-heading tracking-wider transition hover:bg-gray-light"
                onClick={() => {
                    if (isOpen) {
                        onClose();
                    } else {
                        onOpen();
                    }
                }}>
                <span>{title}</span> <FilterButtonIcon />
            </button>

            {isOpen && (
                <motion.div
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 30, opacity: 0 }}
                    className="absolute top-full z-40 w-80">
                    <ul className="space-y-4 bg-white p-4">
                        <li
                            onClick={() => {
                                setCurrentFilters([]);
                            }}
                            key="all">
                            <div className="relative flex items-center">
                                <input
                                    type="checkbox"
                                    style={currentFilters?.length === 0 ? activeStyle : {}}
                                    className={`float-left mr-2 h-6 w-6 flex-shrink-0 cursor-pointer appearance-none border-4 border-green-400 bg-transparent bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none`}
                                />
                                <FaCheck
                                    className={`opacity-100' } pointer-events-none absolute left-3 w-2 -translate-x-1/2 scale-150 text-white
										transition`}
                                />
                                <span className="text-xs lg:text-sm">All categories</span>
                            </div>
                        </li>
                        {items
                            .filter((cat) => {
                                switch (filterKey) {
                                    case 'categories':
                                        return (
                                            blogPosts?.filter((p) =>
                                                //This is never going to include anything since p.categories doesn't exist
                                                p.categories?.includes(cat._id),
                                            )?.length > 0
                                        );

                                    case 'type':
                                        return blogPosts?.filter((p) => {
                                            const hasVideo = 'video' in p;
                                            return cat._id === 'video' ? hasVideo : !hasVideo;
                                        });

                                    default:
                                        return true;
                                }
                            })
                            ?.map((cat) => (
                                <li
                                    onClick={() => {
                                        let newCats = [...currentFilters];

                                        newCats.includes(cat._id)
                                            ? newCats.splice(newCats.indexOf(cat._id), 1)
                                            : newCats.push(cat._id);

                                        setCurrentFilters(newCats);
                                    }}
                                    key={cat._id}>
                                    <div className="relative flex items-center">
                                        <input
                                            type="checkbox"
                                            style={
                                                currentFilters?.includes(cat._id) ? activeStyle : {}
                                            }
                                            className={`float-left mr-2 h-6 w-6 flex-shrink-0 cursor-pointer appearance-none border-4 border-green-400 bg-transparent bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none`}
                                        />
                                        <FaCheck
                                            className={`pointer-events-none absolute left-3 w-2 -translate-x-1/2 scale-150 text-white opacity-0 opacity-100 transition`}
                                        />
                                        <span className="text-xs lg:text-sm">{`${cat.name}`}</span>
                                    </div>
                                </li>
                            ))}
                    </ul>
                    <button
                        className="btn-primary w-full"
                        onClick={() => {
                            dispatch(setSupportFilters({ key: filterKey, value: currentFilters }));
                            onClose();
                        }}>
                        Apply
                    </button>
                </motion.div>
            )}
        </div>
    );
};

export default BlogFilter;
