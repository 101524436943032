import { FaEdit } from 'react-icons/fa';
import { orderItemCategories, truncate } from '../../../utils/constants';

const gridHeaders = [
    ['Claim Code', 'Payer Type', 'Referral', 'Service States'],
    ['Enabled', 'Requires Group ID', 'Billing Provider', 'Service Location'],
    ['Consults Required', 'Covered Services', '', ''],
];
function PayerInfo({
    dmeReferralDestinations,
    payer,
    payerTypes,
    serviceLocations,
    billingProviders,
    setEditingPayer,
    setModalOpen,
}) {
    return (
        <>
            <div className="grid grid-cols-4 my-4 gap-y-4 gap-x-2" key={'payer-info-' + payer.id}>
                <p className="flex gap-x-1 font-heading tracking-widest text-3xl col-span-4 text-gray">
                    Basic Settings
                    <FaEdit
                        className="text-gray text-lg hover:text-white hover:cursor-pointer"
                        onClick={() => {
                            setEditingPayer(payer);
                            setModalOpen('edit-payer');
                        }}
                    />
                </p>
                {gridHeaders[0].map((header) => (
                    <p className="text-gray">{header}</p>
                ))}
                <p>{payer.claimCode}</p>
                <p>{payerTypes.find((p) => p.value === payer.payerType).label}</p>
                <p>
                    {dmeReferralDestinations.find((p) => p.id === payer.dmeReferralOrganizationId)
                        ?.name ?? <span>None</span>}
                </p>
                <div
                    className="daisy-tooltip daisy-tooltip-bottom text-left"
                    data-tip={payer.serviceStates?.join(', ')}>
                    <p>
                        {payer.serviceStates?.length > 0 && (
                            <span className="text-gray pr-2">({payer.serviceStates?.length})</span>
                        )}
                        {truncate(payer.serviceStates?.join(', '), 30)}
                    </p>
                </div>
                {gridHeaders[1].map((header) => (
                    <p className="text-gray">{header}</p>
                ))}
                <div>
                    {payer.enabled ? (
                        <span className=" text-gem-green">YES</span>
                    ) : (
                        <span className=" text-red">NO</span>
                    )}
                </div>
                <div>
                    {payer.requiresGroupId ? (
                        <span className=" text-gem-green">YES</span>
                    ) : (
                        <span className=" text-red">NO</span>
                    )}
                </div>
                <p>
                    {billingProviders.find((p) => p.Id === payer.billingProviderId)?.Name ?? 'None'}
                </p>
                <p>
                    {serviceLocations.find((p) => p.Id === payer.serviceLocationId)?.Name ?? 'None'}
                </p>
                {gridHeaders[2].map((header) => (
                    <p className="text-gray">{header}</p>
                ))}
                <div>
                    {!payer.consultRequiredForCPAP && !payer.consultRequiredForHST && 'None'}
                    {payer.consultRequiredForHST && <span>HST, </span>}
                    {payer.consultRequiredForCPAP && <span>CPAP</span>}
                </div>
                <p className="text-wrap">
                    {payer.HSTCovered && <span>HST, </span>}
                    {payer.CPAPCovered && <span>CPAP, </span>}
                    {payer.CoachingCovered && <span>Coaching, </span>}
                    {payer.SuppliesCovered && <span>Supplies, </span>}
                    {payer.VirtualVisitCovered && <span>Virtual Visits, </span>}
                    {payer.DentalCovered && <span>Dental</span>}
                </p>
                <p className="flex gap-x-1 font-heading tracking-widest text-3xl col-span-4 text-gray">
                    Prior Auth Requirements
                    <FaEdit
                        className="text-gray text-lg hover:text-white hover:cursor-pointer"
                        onClick={() => {
                            setEditingPayer(payer);
                            setModalOpen('prior-auth-requirements');
                        }}
                    />
                </p>
                <p className="text-wrap  col-span-4">
                    {payer.priorAuthRequirements?.length === 0
                        ? 'No requirements set'
                        : payer.priorAuthRequirements.map((requirement) => (
                              <div className="flex p-1 gap-x-2" key={requirement.id}>
                                  <p className="w-[150px]">
                                      {
                                          orderItemCategories.find(
                                              (category) =>
                                                  category.value === requirement.itemCategory,
                                          ).label
                                      }
                                  </p>
                                  <p className="text-gray">{requirement.priorAuthStatus}</p>
                              </div>
                          ))}
                </p>
                <p className="flex gap-x-1 font-heading tracking-widest text-3xl col-span-4 text-gray">
                    Providers
                    <FaEdit
                        className="text-gray text-lg hover:text-white hover:cursor-pointer"
                        onClick={() => {
                            setEditingPayer(payer);
                            setModalOpen('providers');
                        }}
                    />
                </p>
                <p className="text-wrap  col-span-4">
                    {payer.providerCredentials
                        .map(
                            (credential) =>
                                `${credential.user.firstName} ${credential.user.lastName}`,
                        )
                        .join(', ')}
                </p>
            </div>
        </>
    );
}

export default PayerInfo;
