import { useState } from 'react';
import { useSelector } from 'react-redux';
import { usePostAdminQueryMutation } from '../../../app/services/admin/search';
import Modal from '../../Modal/Modal';

function SaveQuery({ activeFilters, queryTabs, queryType }) {
    //Local state
    const [isPublic, setIsPublic] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [queryName, setQueryName] = useState('');

    //Redux state
    const { user } = useSelector((state) => state.auth);

    //Queries and Mutations
    const [postAdminQuery] = usePostAdminQueryMutation(activeFilters);

    //Handle query submission then close modal
    const querySubmitHandler = async (ev) => {
        ev.preventDefault();
        await postAdminQuery({
            ...activeFilters,
            userId: user?.id,
            queryName: queryName,
            queryTabs: queryTabs,
            queryType: queryType,
            publicQuery: isPublic,
        });
        setModalOpen(false);
    };

    return (
        <>
            <button
                onClick={() => setModalOpen(true)}
                className="btn-small bg-gray basis-[30%] mt-2 h-[34px] md:h-[42px]">
                Save Query
            </button>
            <Modal
                showClose={true}
                isOpen={modalOpen}
                closeHandler={() => {
                    setQueryName('');
                    setModalOpen(false);
                }}>
                <span className="font-heading font-bold text-3xl block">
                    SAVE YOUR CURRENT QUERY
                </span>
                <div className="flex p-2">
                    <input
                        type="checkbox"
                        onChange={() => setIsPublic(!isPublic)}
                        checked={isPublic}
                    />
                    <span className="font-heading text-xl block p-2">Make this query Public</span>
                </div>
                <form
                    onSubmit={querySubmitHandler}
                    className="relative w-full flex flex-wrap items-center mb-4">
                    <input
                        name="name"
                        type="text"
                        value={queryName}
                        onChange={(e) => setQueryName(e.currentTarget.value)}
                        placeholder="Enter a name for your query"
                        className="block basis-full sm:basis-[calc(50%-8px)] appearance-none border border-gray bg-transparent p-2 text-sm text-black mb-4 sm:mb-0 sm:mr-2"
                    />
                    <button
                        type="submit"
                        disabled={queryName?.length < 1}
                        className="btn-primary-small block basis-full sm:basis-[calc(50%-8px)] text-base lg:text-lg sm:ml-2">
                        Save Query
                    </button>
                </form>
            </Modal>
        </>
    );
}

export default SaveQuery;
