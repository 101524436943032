import { useSelector } from 'react-redux';

function Coaching() {
    //Redux state values
    const { user } = useSelector((state) => state.auth);

    const approvedForCoaching = user?.patientProfile?.coachingStatus === 'APPROVED';
    const needsNewCPAP = user?.patientProfile?.referredForCpap;
    const hasCoachAssigned = user?.careTeam.length > 0;

    if (approvedForCoaching) {
        return (
            <div className="max-w-5xl mb-4">
                <h5 className="mb-2 font-heading text-xl lg:text-3xl">
                    GREAT NEWS,{' '}
                    <span className="ph-no-capture">{user.firstName + ' ' + user.lastName}!</span>{' '}
                    You have been approved for GEM SLEEP’s COACHING PROGRAM!
                </h5>
                <p className="mb-2 lg:mb-4 font-bold">What happens next?</p>
                {needsNewCPAP ? (
                    <ol className="space-y-2">
                        <li>
                            1. Get your new CPAP machine. Click Get Started to begin the mask
                            fitting process, then checkout the CPAP Starter Bundle recommended for
                            you. You will also have an option to enroll in GEM's resupply program.
                        </li>

                        <li>
                            2. Once you receive your CPAP machine, your GEM SLEEP Coach
                            {hasCoachAssigned &&
                                ', ' + user.careTeam[0].careTeamUser.firstName + ','}{' '}
                            will reach out to find a time to meet.
                        </li>
                    </ol>
                ) : (
                    <ol className="space-y-2">
                        <li>
                            1. Prior to using your CPAP again, do you need to order more supplies?
                            You may do so on GEM's supply store. Want to use insurance for your
                            order, enter INSURANCE as the promo code at checkout.
                        </li>
                        <li>
                            2. Your GEM SLEEP Coach{' '}
                            {hasCoachAssigned &&
                                ', ' + user.careTeam[0].careTeamUser.firstName + ','}{' '}
                            will be reaching out shortly to find a time to kick-off your therapy.
                        </li>
                    </ol>
                )}
                {/* <AppointmentSchedulingButton
                sku="COMPLIANCE"
                styling="btn-primary w-full lg:w-auto flex items-center space-x-2"
                label="Schedule an appointment"
            /> */}
            </div>
        );
    } else {
        return (
            <div className="max-w-5xl mb-4 space-y-2">
                <p>
                    After careful review, we don't feel the GEM SLEEP COACHING Program is the right
                    fit for you. Based on the information provided we recommend visiting an
                    In-Person Sleep Clinic as a next step. Oftentimes CPAP therapy can be difficult
                    to adjust to if you may have another underlying sleep condition or if there is
                    uncertainty regarding the diagnosis.
                </p>
                <p>
                    If you would like more information on our review process, please contact our GEM
                    PATIENT SUPPORT TEAM.
                </p>
                <p>
                    <a href="tel:+18339464436" className="border-b-2 border-b-green-400 font-bold">
                        1-833-946-4GEM
                    </a>
                </p>
                <p>
                    <a
                        href="mailto:info@gem.health"
                        className="border-b-2 border-b-green-400 font-bold">
                        info@gem.health
                    </a>
                </p>
            </div>
        );
    }
}
export default Coaching;
