import { api } from '../auth';

const treatmentAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        createTreatment: builder.mutation({
            query: ({ userId, treatmentFields }) => ({
                url: `/users/${userId}/treatments`,
                method: 'POST',
                body: treatmentFields,
            }),
            invalidatesTags: ['Treatment', 'OrderHistory', 'PatientOrderHistory'],
        }),
        deleteTreatment: builder.mutation({
            query: ({ userId, treatmentId }) => ({
                url: `/users/${userId}/treatments/${treatmentId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Treatment'],
        }),
        getTreatments: builder.query({
            query: (userId) => ({
                url: `/users/${userId}/treatments`,
                method: 'GET',
            }),
            transformResponse: (response) => {
                const treatments = response.sort(function (a, b) {
                    return a.startDate < b.startDate ? -1 : a.date > b.date ? 1 : 0;
                });
                return treatments;
            },

            providesTags: (arg) => [{ type: 'Treatment', id: arg }],
        }),
        getUserSovasagePartRecommendations: builder.query({
            query: (userId) => `/sova-sage/recommended-parts/${userId}`,
            method: 'GET',
            providesTags: ['SovaSagePartRecommendations'],
        }),
        selectMask: builder.mutation({
            query: ({ userId, id }) => ({
                url: `/sova-sage/${userId}/select-mask/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: ['SovaSagePartRecommendations', 'RecommendedCPAPBundles'],
        }),
        sendHSTOrderSignOff: builder.mutation({
            query: ({ patientProfileId, userId }) => ({
                url: `/patient-profile/${patientProfileId}/hst-lab-order-sign-off`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.userId }],
        }),
        updateTreatment: builder.mutation({
            query: ({ userId, treatmentId, treatmentFields }) => ({
                url: `/users/${userId}/treatments/${treatmentId}`,
                method: 'PUT',
                body: treatmentFields,
            }),
            invalidatesTags: ['Treatment', 'OrderHistory', 'PatientOrderHistory'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetTreatmentsQuery,
    useCreateTreatmentMutation,
    useUpdateTreatmentMutation,
    useDeleteTreatmentMutation,
    useSendHSTOrderSignOffMutation,
    useGetUserSovasagePartRecommendationsQuery,
    useSelectMaskMutation,
} = treatmentAPI;
