import { Field, Form, Formik } from 'formik';
import { FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Modal } from '../../..';
import {
    useAddPayerMutation,
    useDeletePayerMutation,
    useGetDmeReferralDestinationsQuery,
    useGetItamarInterpretationPoolsQuery,
    useUpdatePayerMutation,
} from '../../../../app/services/admin/insurance';
import { useGetPayersQuery } from '../../../../app/services/insurance';
import { states } from '../../../../utils/constants';

const customClassNames =
    'w-full rounded border py-2 px-3 leading-tight shadow border-[1px] border-[#abc9f0] focus:border-[#8097b5] active:border-[#8097b5] focus:outline-none active:outline-none';

function EditPayerInformation({
    editingPayer,
    payerTypes,
    setEditingPayer,
    serviceLocations,
    billingProviders,
}) {
    //Queries and mutations
    const { data: itamarInterpretationPools } = useGetItamarInterpretationPoolsQuery();
    const { data: dmeReferralDestinations } = useGetDmeReferralDestinationsQuery();
    const { refetch: payersRefetch } = useGetPayersQuery();
    const [addPayer] = useAddPayerMutation();
    const [deletePayer] = useDeletePayerMutation();
    const [updatePayer] = useUpdatePayerMutation();

    const savePayer = async (payerId, payerValues) => {
        payerValues.dmeReferralOrganizationId = parseInt(payerValues.dmeReferralOrganizationId);

        if (!payerId) {
            addPayer(payerValues)
                .then(() => {
                    toast.success('Payer added!', { theme: 'dark' });
                    setEditingPayer(null);
                    payersRefetch();
                })
                .catch((error) => {
                    toast.error('Unable to add Payer', { theme: 'dark' });
                    setEditingPayer(null);
                });
        } else {
            updatePayer({ payerId, data: payerValues })
                .then(() => {
                    toast.success('Payer updated!', { theme: 'dark' });
                    setEditingPayer(null);
                })
                .catch((error) => {
                    toast.error('Unable to update Payer', { theme: 'dark' });
                    setEditingPayer(null);
                });
        }
    };

    const removePayer = (id) => {
        deletePayer(id)
            .unwrap()
            .then((payload) => {
                toast.success('Payer removed!', { theme: 'dark' });
                payersRefetch();
                setEditingPayer(null);
            })
            .catch((error) => {
                toast.error('Unable to remove, In use payers cannot be remove', { theme: 'dark' });
                setEditingPayer(null);
            });
    };

    function CheckboxComponent({ label, id }) {
        return (
            <div
                className="flex justify-between rounded border-[1px] border-[#d3deed] p-3"
                key={id}>
                <label htmlFor={id}>{label}</label>
                <div className="w-[25px] h-[25px] relative">
                    <Field
                        id={id}
                        type="checkbox"
                        name={id}
                        className="float-left h-6 w-6 flex-shrink-0 cursor-pointer appearance-none border-4 border-green-400 bg-transparent bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-green-600 checked:bg-green-600 focus:outline-none"
                    />
                    <FaCheck className="absolute-center pointer-events-none w-2 scale-150 text-white opacity-0 transition" />
                </div>
            </div>
        );
    }

    const payerValues = [
        { label: 'Enabled', id: 'enabled' },
        {
            label: 'Supports Electronic Eligibility Check',
            id: 'supportsEligibilityCheck',
        },
        {
            label: 'Consult Required for HST',
            id: 'consultRequiredForHST',
        },
        {
            label: 'Consult Required for CPAP',
            id: 'consultRequiredForCPAP',
        },
        {
            label: 'Requires Group ID',
            id: 'requiresGroupId',
        },
    ];

    const coveredServices = [
        { label: 'HST', id: 'HSTCovered' },
        {
            label: 'CPAP',
            id: 'CPAPCovered',
        },
        {
            label: 'Coaching',
            id: 'CoachingCovered',
        },
        {
            label: 'Supplies',
            id: 'SuppliesCovered',
        },
        {
            label: 'Virtual Visits',
            id: 'VirtualVisitCovered',
        },
        {
            label: 'Dental',
            id: 'DentalCovered',
        },
    ];

    return (
        <Modal
            showClose={true}
            isOpen={editingPayer !== null}
            closeHandler={() => {
                setEditingPayer(null);
            }}>
            <div className="payer-edit-form">
                <section>
                    <header>
                        {editingPayer?.id ? (
                            <h2 className="font-heading text-3xl">Edit Payer</h2>
                        ) : (
                            <h2 className="font-heading text-3xl">Add Payer</h2>
                        )}
                    </header>
                    <Formik
                        initialValues={{
                            name: editingPayer?.name ?? '',
                            payerType: editingPayer?.payerType ?? 'INSURANCE',
                            serviceStates: editingPayer?.serviceStates ?? [],
                            consultRequiredForHST: editingPayer?.consultRequiredForHST ?? false,
                            consultRequiredForCPAP: editingPayer?.consultRequiredForCPAP ?? false,
                            enabled: editingPayer?.enabled ?? false,
                            supportsEligibilityCheck:
                                editingPayer?.supportsEligibilityCheck ?? false,
                            HSTCovered: editingPayer?.HSTCovered ?? false,
                            CPAPCovered: editingPayer?.CPAPCovered ?? false,
                            CoachingCovered: editingPayer?.CoachingCovered ?? false,
                            SuppliesCovered: editingPayer?.SuppliesCovered ?? false,
                            VirtualVisitCovered: editingPayer?.VirtualVisitCovered ?? false,
                            DentalCovered: editingPayer?.DentalCovered ?? false,
                            dmeReferralOrganizationId: editingPayer?.dmeReferralOrganizationId,
                            requiresGroupId: editingPayer?.requiresGroupId ?? false,
                            itamarPoolId: editingPayer?.itamarPoolId ?? null,
                            billingProviderId: editingPayer?.billingProviderId ?? null,
                            serviceLocationId: editingPayer?.serviceLocationId ?? null,
                        }}
                        onSubmit={(values) => {
                            savePayer(editingPayer.id, values);
                        }}>
                        {() => (
                            <Form className="flex flex-col gap-4">
                                <div>
                                    <label>Payer Name</label>
                                    <Field
                                        className={customClassNames}
                                        defaultValue={editingPayer.name}
                                        name="name"></Field>
                                </div>
                                <div>
                                    <label>Payer Type</label>
                                    <Field
                                        component="select"
                                        multiple={false}
                                        className={customClassNames}
                                        defaultValue={editingPayer.payerType}
                                        name="payerType">
                                        {payerTypes.map((payerType, index) => (
                                            <option
                                                key={`payerType-${index}`}
                                                value={payerType.value}>
                                                {payerType.label}
                                            </option>
                                        ))}
                                    </Field>
                                </div>
                                <div>
                                    <label>Claim Code</label>
                                    <Field
                                        className={customClassNames}
                                        defaultValue={editingPayer.claimCode}
                                        name="claimCode"></Field>
                                </div>
                                <div>
                                    <label>Billing Provider</label>
                                    <Field
                                        component="select"
                                        className={customClassNames}
                                        defaultValue={editingPayer.billingProviderId}
                                        name="billingProviderId">
                                        <option key="billingProviderId-none">None</option>
                                        {billingProviders?.map((option) => (
                                            <option
                                                key={`billingProviderId-${option.Id}`}
                                                value={option.Id}>
                                                {option.Name} {option.Npi && `(${option.Npi})`}
                                            </option>
                                        ))}
                                    </Field>
                                </div>
                                <div>
                                    <label>Service Location</label>
                                    <Field
                                        component="select"
                                        className={customClassNames}
                                        defaultValue={editingPayer.serviceLocationId}
                                        name="serviceLocationId">
                                        <option key="serviceLocationId-none">None</option>
                                        {serviceLocations?.map((option) => (
                                            <option
                                                key={`serviceLocationId-${option.Id}`}
                                                value={option.Id}>
                                                {option.Name} {option.Npi && `(${option.Npi})`}
                                            </option>
                                        ))}
                                    </Field>
                                </div>
                                <div>
                                    <label>Service States (CMD+Click to select multiple)</label>
                                    <Field
                                        component="select"
                                        multiple={true}
                                        className={customClassNames}
                                        size={10}
                                        defaultValue={editingPayer.serviceStates}
                                        name="serviceStates">
                                        {states.map((state, index) => (
                                            <option key={`state-${index}`} value={state.value}>
                                                {state.label}
                                            </option>
                                        ))}
                                    </Field>
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    {payerValues.map((item) => (
                                        <CheckboxComponent label={item.label} id={item.id} />
                                    ))}
                                </div>
                                <section className="py-4">
                                    <h3 className="font-heading text-2xl border-b border-gray-400">
                                        Covered Services
                                    </h3>

                                    <div className="grid grid-cols-2 gap-4 mt-4">
                                        {coveredServices.map((item) => (
                                            <CheckboxComponent label={item.label} id={item.id} />
                                        ))}
                                    </div>
                                </section>
                                <section className="py-4 space-y-4">
                                    <h3 className="font-heading text-2xl border-b border-gray-400">
                                        DME Referral
                                    </h3>
                                    <div>
                                        <label>Referral Destination</label>
                                        <Field
                                            component="select"
                                            multiple={false}
                                            className={customClassNames}
                                            defaultValue={
                                                !editingPayer.dmeReferralOrganizationId
                                                    ? 0
                                                    : editingPayer.dmeReferralOrganizationId
                                            }
                                            name="dmeReferralOrganizationId">
                                            <option value={0}>None</option>
                                            {dmeReferralDestinations?.map((d) => (
                                                <option
                                                    key={`dmeReferralOrganizationId-${d.id}`}
                                                    value={d.id}>
                                                    {d.name}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                </section>
                                <section className="space-y-4">
                                    <h3 className="font-heading text-2xl border-b border-gray-400">
                                        Itamar Interpretation Pool
                                    </h3>
                                    <div>
                                        <label>Pool</label>
                                        <Field
                                            component="select"
                                            multiple={false}
                                            className={customClassNames}
                                            defaultValue={
                                                editingPayer.itamarPoolId
                                                    ? null
                                                    : editingPayer.itamarPoolId
                                            }
                                            name="itamarPoolId">
                                            <option value={0}>None</option>
                                            {itamarInterpretationPools &&
                                                itamarInterpretationPools.map((d) => (
                                                    <option key={`pool-${d.id}`} value={d.id}>
                                                        {d.name}
                                                    </option>
                                                ))}
                                        </Field>
                                    </div>
                                </section>{' '}
                                <button className="btn-primary w-full" type="submit">
                                    Save Payer
                                </button>
                            </Form>
                        )}
                    </Formik>
                    {editingPayer?.id && (
                        <div className="mt-4 text-center">
                            <button
                                className="transparent text-sm font-bold text-red"
                                onClick={() => {
                                    removePayer(editingPayer.id);
                                    return false;
                                }}>
                                DELETE PAYER
                            </button>
                        </div>
                    )}
                </section>
            </div>
        </Modal>
    );
}

export default EditPayerInformation;
