import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import Products from './Products';
import Bundles from './Bundles';
import Discounts from './Discounts';
import { useSyncNikoProductsQuery } from '../../../app/services/shop';

const Shop = () => {
    const [activeTab, setActiveTab] = useState('products');
    const [skipSync, setSkipSync] = useState(true);

    useSyncNikoProductsQuery(null, { skip: skipSync });

    const syncProducts = () => {
        setSkipSync(false);
    };

    return (
        <div className="py-6">
            <header className="w-full flex justify-between">
                <h2 className="mb-5">
                    <span className="font-heading text-5xl">Shop</span>
                </h2>
                <div className="flex flex-col">
                    <button
                        className="btn-primary text-lg"
                        onClick={() => {
                            //sync niko products
                            syncProducts();
                        }}>
                        Sync Niko Products
                    </button>
                    <p className="text-xs text-gray-light mt-4">
                        Sync product data from Niko into the GEM product library
                    </p>
                </div>
            </header>
            <nav className="mb-8 space-x-3 border-b border-gray">
                <button
                    onClick={(ev) => {
                        setActiveTab('products');
                    }}
                    className={`rounded-t-md border-t border-r border-l border-gray py-2 px-4 ${
                        activeTab === 'products' ? 'bg-gray' : ''
                    }`}>
                    Products
                </button>
                <button
                    onClick={(ev) => {
                        setActiveTab('collections');
                    }}
                    className={`rounded-t-md border-t border-r border-l border-gray py-2 px-4 ${
                        activeTab === 'collections' ? 'bg-gray' : ''
                    }`}>
                    Collections
                </button>
                <button
                    onClick={(ev) => {
                        setActiveTab('bundles');
                    }}
                    className={`rounded-t-md border-t border-r border-l border-gray py-2 px-4 ${
                        activeTab === 'bundles' ? 'bg-gray' : ''
                    }`}>
                    Bundles
                </button>
                <button
                    onClick={(ev) => {
                        setActiveTab('discounts');
                    }}
                    className={`rounded-t-md border-t border-r border-l border-gray py-2 px-4 ${
                        activeTab === 'discounts' ? 'bg-gray' : ''
                    }`}>
                    Discounts
                </button>
            </nav>

            <AnimatePresence mode="wait">
                <section>
                    {activeTab === 'products' && <Products />}
                    {activeTab === 'bundles' && <Bundles />}
                    {activeTab === 'discounts' && <Discounts />}
                </section>
            </AnimatePresence>
        </div>
    );
};

export default Shop;
