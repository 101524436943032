import { useDispatch, useSelector } from 'react-redux';

//Components
import { Pagination } from '..';
import { AdminOrderList, OrderFilters } from '.';

import { useGetPaginatedOrderHistoryQuery } from '../../../app/services/admin/orders';
import { setStatusFilter } from '../../../features/admin/adminSlice';

const ORDERS_PER_PAGE = 10;

const OrdersList = () => {
    const dispatch = useDispatch();
    const { activeOrderFilters } = useSelector((state) => state.admin);

    const { data: orders, isFetching } = useGetPaginatedOrderHistoryQuery({
        ...activeOrderFilters,
    });

    function setPage(page) {
        return dispatch(
            setStatusFilter({
                type: 'activeOrderFilters',
                key: 'skip',
                value: page * ORDERS_PER_PAGE,
            }),
        );
    }

    return (
        <>
            <OrderFilters setPage={setPage} />
            {orders ? (
                <AdminOrderList orders={orders.results} setPage={setPage} />
            ) : (
                <p className="py-6 font-heading text-5xl">Loading...</p>
            )}
            {orders?.metadata.total > orders?.metadata.take && (
                <div className="flex justify-center items-center">
                    <Pagination
                        page={Math.floor(activeOrderFilters.skip / 10)}
                        disabled={isFetching}
                        setPage={setPage}
                        totalPages={Math.floor(orders?.metadata.total / orders?.metadata.take)}
                    />
                </div>
            )}
        </>
    );
};

export default OrdersList;
