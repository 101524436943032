import { MaskComparisonGrid } from '../../components';

const MaskComparison = () => {
    return (
        <div className="container">
            <h1 className="mb-10 font-heading text-5xl">Mask comparison</h1>
            <MaskComparisonGrid />
        </div>
    );
};

export default MaskComparison;
