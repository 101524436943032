import { addDays } from 'date-fns';
import { api } from '../auth';

const careTeamAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        addCareTeamMember: builder.mutation({
            query: ({ userId, careTeamMember }) => ({
                url: `/users/${userId}/careteam`,
                method: 'POST',
                body: careTeamMember,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'User' }],
        }),
        addToPool: builder.mutation({
            query: ({ poolId, memberId }) => ({
                url: `/admin/careteam/pools/${poolId}/members`,
                method: 'POST',
                body: { memberId },
            }),
            invalidatesTags: ['CareTeamPool'],
        }),
        getCareTeamPools: builder.query({
            query: () => ({
                url: `/admin/careteam/pools`,
                method: 'GET',
            }),
            providesTags: ['CareTeamPool'],
        }),
        getCoaches: builder.query({
            query: () => ({
                url: '/users',
                method: 'POST',
                body: {
                    where: {
                        OR: [{ userRoles: { some: { role: 'COACH' } } }],
                    },
                    orderBy: { id: 'desc' },
                },
            }),
            providesTags: ['Coaches'],
        }),
        linkToHealthie: builder.mutation({
            query: (userId) => ({
                url: `/users/${userId}/admin-link-to-healthie`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg }],
        }),
        listConsults: builder.query({
            query: ({
                patientId,
                skip = 0,
                take = 25,
                providerId = null,
                appointmentStatus = null,
                appointmentType = null,
                firstName = null,
                lastName = null,
                startDate = null,
                endDate = null,
            }) => {
                function nameSearchValue(firstName, lastName) {
                    if (firstName?.length > 0 && lastName?.length > 0) {
                        return {
                            user: {
                                AND: [
                                    {
                                        firstName: {
                                            contains: firstName,
                                            mode: 'insensitive',
                                        },
                                    },
                                    {
                                        lastName: {
                                            contains: lastName,
                                            mode: 'insensitive',
                                        },
                                    },
                                ],
                            },
                        };
                    } else if (firstName?.length > 0) {
                        return {
                            user: {
                                firstName: {
                                    contains: firstName,
                                    mode: 'insensitive',
                                },
                            },
                        };
                    } else if (lastName?.length > 0) {
                        return {
                            user: {
                                lastName: {
                                    contains: lastName,
                                    mode: 'insensitive',
                                },
                            },
                        };
                    } else return null;
                }

                const where = {
                    userId: patientId,
                    appointmentStatus: appointmentStatus ? appointmentStatus.value : null,
                    appointmentType: appointmentType ? appointmentType.name : null,
                    providerId: providerId ? parseInt(providerId.value) : null,
                    ...nameSearchValue(firstName, lastName),
                    startAt: startDate
                        ? {
                              gte: new Date(startDate), // Day selected
                              lte: endDate
                                  ? addDays(new Date(endDate), 1)
                                  : addDays(new Date(startDate), 1), // Next day selected if no end date specified
                          }
                        : null,
                };
                const whereString = JSON.stringify(
                    Object.fromEntries(Object.entries(where).filter(([_, v]) => v != null)),
                );
                return `/appointments?where=${whereString}&orderBy={"startAt":"desc"}&skip=${skip}&take=${take}`;
            },
        }),
        removeCareTeamMember: builder.mutation({
            query: ({ userId, careTeamMember }) => ({
                url: `/users/${userId}/careteam`,
                method: 'DELETE',
                body: careTeamMember,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'User' }],
        }),
        removeFromPool: builder.mutation({
            query: ({ poolId, memberId }) => ({
                url: `/admin/careteam/pools/${poolId}/members`,
                method: 'DELETE',
                body: { memberId },
            }),
            invalidatesTags: ['CareTeamPool'],
        }),
        resetHealthiePassword: builder.mutation({
            query: (userId) => ({
                url: `/users/${userId}/admin-healthie-reset-pw`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg }],
        }),
    }),
    overrideExisting: false,
});

export const {
    useAddCareTeamMemberMutation,
    useAddToPoolMutation,
    useGetCareTeamPoolsQuery,
    useGetCoachesQuery,
    useLinkToHealthieMutation,
    useListConsultsQuery,
    useRemoveCareTeamMemberMutation,
    useRemoveFromPoolMutation,
    useResetHealthiePasswordMutation,
} = careTeamAPI;
