import { format } from 'date-fns';
import { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { LuClipboardCopy } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConsultFilters } from '.';
import { Pagination } from '..';
import { useListConsultsQuery } from '../../../app/services/admin/careteam';
import { useCreateAppointmentInvoiceMutation } from '../../../app/services/insurance';
import { setStatusFilter } from '../../../features/admin/adminSlice';
import { colorAppointmentStatus, colorInsuranceStatus } from '../../../utils/constants';

const ConsultList = ({ patient, standalonePage = true }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //Redux state values
    const { activeConsultFilters } = useSelector((state) => state.admin);

    //Query the consults which match filters
    const { data, isLoading, isFetching, refetch } = useListConsultsQuery(
        patient
            ? {
                  patientId: patient.id,
              }
            : {
                  ...activeConsultFilters,
              },
    );

    const [createAppointmentInvoice, { isLoading: isCreatingInvoice }] =
        useCreateAppointmentInvoiceMutation();

    const { results: appointments, metadata } = data || {};

    //Refetch the query if anything changes
    useEffect(() => {
        refetch();
    }, [activeConsultFilters, patient, refetch]);

    return isLoading ? (
        <p className="py-6 font-heading text-5xl">Loading...</p>
    ) : (
        <div className={standalonePage ? 'pb-6' : 'pb-4'}>
            {standalonePage && <ConsultFilters />}

            <table className="table-fixed">
                <thead>
                    <tr className="border-b">
                        <th className="min-w-[150px] p-2 text-left">
                            <h4>Type</h4>
                        </th>
                        <th className="min-w-[100px] p-2 text-left">
                            <h4>Date</h4>
                        </th>
                        <th className="min-w-[90px] p-2 text-left">
                            <h4>Time</h4>
                        </th>
                        <th className="min-w-[100px] p-2 text-left">
                            <h4>Status</h4>
                        </th>
                        {/* If a patient record prop was passed in then we are only showing appointments for one single patient so we don't need to show this column */}
                        {!patient && (
                            <th className="min-w-[150px] p-2 text-left">
                                <h4>Patient</h4>
                            </th>
                        )}
                        <th className="min-w-[150px] p-2 text-left">
                            <h4>Provider</h4>
                        </th>
                        <th className="min-w-[140px] p-2 text-left">
                            <h4>Insurer</h4>
                        </th>
                        <th className="min-w-[80px] p-2 text-left"></th>
                        <th className="min-w-[10px] p-2 text-left">Order</th>
                        <th className="min-w-[100px] p-2 text-left">
                            <h4>Updated</h4>
                        </th>
                        <th className="p-2 text-left">
                            <h4>Zoom Link</h4>
                        </th>
                        <th className="p-2 text-left">
                            <h4>Invoice</h4>
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-dark text-sm">
                    {appointments &&
                        appointments.length > 0 &&
                        appointments.map((appointment) => (
                            <tr
                                key={`${appointment.id}`}
                                className={`${!appointment.confirmed && 'opacity-50'}`}
                                title={`${
                                    appointment.confirmed ? '' : 'Not Confirmed - Pending Payment'
                                }`}>
                                <td className="whitespace-nowrap overflow-hidden text-ellipsis p-2">
                                    {appointment.appointmentType}
                                </td>
                                <td className="p-2">
                                    {format(new Date(appointment.startAt), 'MM/dd/yyyy')}
                                </td>
                                <td className="p-2">
                                    {format(new Date(appointment.startAt), 'h:mm a')}
                                </td>
                                <td className="p-2">
                                    {colorAppointmentStatus(appointment.appointmentStatus)}
                                </td>
                                {!patient && (
                                    <td
                                        className={`${standalonePage && 'hover:cursor-pointer'} max-w-[150px] p-2`}
                                        onClick={() => {
                                            if (standalonePage)
                                                navigate(`/admin/patients/${appointment.user.id}`, {
                                                    state: {
                                                        from: '/admin/consults',
                                                        name: 'Consults',
                                                    },
                                                });
                                        }}>
                                        <div
                                            className={`daisy-tooltip daisy-tooltip-right text-left flex items-center max-h-[33.8px]`}
                                            data-tip={
                                                appointment.user.firstName +
                                                ' ' +
                                                appointment.user.lastName
                                            }>
                                            <p className="max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis">
                                                {`${appointment.user.firstName} ${appointment.user.lastName}`}
                                            </p>
                                        </div>
                                    </td>
                                )}
                                <td className="max-w-[150px] p-2">
                                    <div
                                        className={`daisy-tooltip daisy-tooltip-right text-left flex items-center max-h-[33.8px]`}
                                        data-tip={
                                            appointment.provider.firstName +
                                            ' ' +
                                            appointment.provider.lastName
                                        }>
                                        <p className=" max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis">
                                            {`${appointment.provider.firstName} ${appointment.provider.lastName}`}
                                        </p>
                                    </div>
                                </td>
                                <td className="max-w-[150px] p-2">
                                    <div
                                        className={`daisy-tooltip daisy-tooltip-right text-left flex items-center max-h-[33.8px]`}
                                        data-tip={appointment.order?.insurance?.payer.name}>
                                        <p className="max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis">
                                            {appointment.order?.insurance?.payer.name}{' '}
                                        </p>
                                    </div>
                                </td>
                                <td className="p-2">
                                    {colorInsuranceStatus(
                                        appointment.order?.insurance?.insuranceStatus,
                                    )}
                                </td>
                                <td
                                    className={`p-2 ${appointment.confirmed && 'hover:cursor-pointer hover:text-gem-green hover:font-bold'}`}
                                    onClick={(e) => {
                                        if (appointment.orderId && appointment.confirmed) {
                                            e.stopPropagation();
                                            navigate(
                                                `/admin/patients/${appointment.user.id}/orders/${appointment.orderId}`,
                                            );
                                        }
                                    }}>
                                    {appointment.orderId ?? null}
                                </td>
                                <td className="p-2">
                                    {format(new Date(appointment.updatedAt), 'MM/dd/yyyy')}
                                </td>
                                <td className="p-2 flex items-center space-x-6">
                                    {appointment.zoomJoinUrl && (
                                        <>
                                            <a
                                                onClick={(e) => e.stopPropagation()}
                                                href={appointment.zoomJoinUrl}
                                                className="link"
                                                target="_blank"
                                                rel="noreferrer">
                                                Link
                                            </a>
                                            <LuClipboardCopy
                                                className="scale-150"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toast.success('Copied to Clipboard');
                                                    navigator.clipboard.writeText(
                                                        appointment.zoomJoinUrl,
                                                    );
                                                }}
                                            />
                                        </>
                                    )}
                                </td>
                                <td className="p-2">
                                    {appointment.nikoInvoiceId ? (
                                        <a
                                            onClick={(e) => e.stopPropagation()}
                                            href={`${process.env.REACT_APP_NIKO_URL}v2/#/billing/invoices/invoice/${appointment.nikoInvoiceId}/details`}
                                            className="link"
                                            target="_blank"
                                            rel="noreferrer">
                                            View Invoice
                                        </a>
                                    ) : (
                                        <button
                                            className="link disabled:opacity-50"
                                            disabled={
                                                appointment.appointmentStatus !== 'OCCURRED' ||
                                                isCreatingInvoice
                                            }
                                            onClick={() =>
                                                createAppointmentInvoice(appointment.id).then(
                                                    (result) => {
                                                        if (!result.error)
                                                            toast.success('Invoice Created');
                                                        else
                                                            toast.error('Failed to create invoice');
                                                    },
                                                )
                                            }>
                                            Create Invoice
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            {appointments && appointments.length === 0 && (
                <p className="pt-4 font-heading text-3xl">No appointments found</p>
            )}

            {activeConsultFilters.take < metadata?.total && (
                <Pagination
                    page={activeConsultFilters.skip / activeConsultFilters.take}
                    disabled={isFetching}
                    setPage={(page) =>
                        dispatch(
                            setStatusFilter({
                                type: 'activeConsultFilters',
                                key: 'skip',
                                value: activeConsultFilters.take * page,
                            }),
                        )
                    }
                    totalPages={Math.floor(metadata.total / activeConsultFilters.take)}
                />
            )}
        </div>
    );
};

export default ConsultList;
