import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    useDeactivateUserMutation,
    useGetUserQuery,
    useResendVerificationEmailMutation,
    useTwoFactorOverrideMutation,
} from '../../app/services/admin/admin';
import {
    useLinkToHealthieMutation,
    useResetHealthiePasswordMutation,
} from '../../app/services/admin/careteam';

import {
    AdminUserInfo,
    CareteamPools,
    PayerCredentials,
} from '../../components/Admin/AdminUserComponents';

const User = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const { data: adminUser, error, isLoading } = useGetUserQuery(userId);
    const [deactivateUser] = useDeactivateUserMutation();
    const [twoFactorOverride] = useTwoFactorOverrideMutation();
    const [linkToHealthie] = useLinkToHealthieMutation();
    const [resetHealthiePassword] = useResetHealthiePasswordMutation();
    const [resendVerificationEmail] = useResendVerificationEmailMutation();

    return (
        <AnimatePresence mode="wait">
            {error && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <h2 className="mb-10 font-heading text-2xl lg:text-5xl">Error.</h2>
                </motion.div>
            )}
            {isLoading ? (
                <motion.div
                    className="space-y-10 p-12"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    <h2 className="mb-10 font-heading text-2xl lg:text-5xl">Loading...</h2>
                </motion.div>
            ) : (
                adminUser && (
                    <div className="pb-20">
                        <h2 className="sticky top-0 z-20 mb-10 flex items-center justify-between bg-purple-dark pt-12 pb-8 font-heading text-3xl lg:text-5xl xl:text-7xl">
                            <span>
                                {adminUser.firstName} {adminUser.lastName}
                            </span>
                            <button
                                className="font-heading text-lg tracking-widest text-gray transition hover:text-white"
                                onClick={(ev) => {
                                    navigate('/admin/administrators');
                                }}>
                                back to administrators
                            </button>
                        </h2>

                        <AdminUserInfo adminUser={adminUser} />

                        <br />
                        <hr className="border-gray-six" />
                        <br />

                        <PayerCredentials adminUser={adminUser} />

                        <br />

                        <div className="rounded-xl bg-gray-darker p-5">
                            <p className="mb-3 text-xl font-bold">Healthie Integration Info</p>

                            {!adminUser.healthieProviderId && (
                                <button
                                    className="btn-primary-small"
                                    onClick={async (ev) => {
                                        await linkToHealthie(adminUser.id);
                                    }}>
                                    Link to Healthie
                                </button>
                            )}

                            {adminUser.healthieProviderId && (
                                <button
                                    className="btn-primary-small"
                                    onClick={async (ev) => {
                                        await resetHealthiePassword(adminUser.id);
                                    }}>
                                    Reset Healthie Password
                                </button>
                            )}
                        </div>

                        <br />

                        <div className="rounded-xl bg-gray-darker p-5">
                            <p className="mb-3 text-xl font-bold">Miscellaneous:</p>

                            <div className="flex items-center space-x-4">
                                <button
                                    className="btn-primary-small"
                                    onClick={async (ev) => {
                                        const result = await twoFactorOverride(adminUser.id);

                                        result.error
                                            ? toast.error('Error setting override', {
                                                  theme: 'dark',
                                              })
                                            : toast.success('Override code set', { theme: 'dark' });
                                    }}>
                                    2FA CODE: 111111
                                </button>
                                {adminUser.unverifiedEmail && (
                                    <button
                                        className="btn-primary-small"
                                        onClick={async (ev) => {
                                            const result = await resendVerificationEmail({
                                                userId: adminUser.id,
                                                redirectUrl:
                                                    window.location.origin +
                                                    '/reset-password?fromNewAdminVerification=true',
                                            });

                                            result.error
                                                ? toast.error('Error resending invitation', {
                                                      theme: 'dark',
                                                  })
                                                : toast.success('Invitation resent!', {
                                                      theme: 'dark',
                                                  });
                                        }}>
                                        Resend Invite
                                    </button>
                                )}
                                {adminUser.isActive && (
                                    <button
                                        className="btn-secondary-small"
                                        onClick={async (ev) => {
                                            await deactivateUser(adminUser.id);
                                            navigate('/admin/administrators');
                                        }}>
                                        Deactivate user
                                    </button>
                                )}
                            </div>
                        </div>

                        <br />

                        <CareteamPools adminUser={adminUser} />

                        <br />
                    </div>
                )
            )}
        </AnimatePresence>
    );
};

export default User;
