import { Link, useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { CustomInput } from '../../components/InputComponents';
import { passwordResetSchema } from '../../utils/schemas';
import { useResetPasswordMutation } from '../../app/services/auth';

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const resetToken = searchParams.get('resetToken');
    const fromNewAdminVerification = searchParams.get('fromNewAdminVerification');
    const [resetPassword, { isSuccess, error }] = useResetPasswordMutation();

    const submitHandler = async (data) => {
        await resetPassword({ password: data.password, resetToken: resetToken });
    };

    return (
        <div className="container max-w-4xl">
            <h3 className="text-offwhite text-5xl lg:text-8xl font-heading mb-9 lg:mb-12 text-center">
                {fromNewAdminVerification ? 'Create' : 'Reset'}{' '}
                <span className="opacity-70">password</span>
            </h3>

            {!resetToken && !fromNewAdminVerification ? (
                <div className="overflow-hidden border-8 border-purple-medium border-opacity-20 p-8 lg:p-14 bg-white text-black">
                    <p>Reset token missing.</p>
                </div>
            ) : (
                <>
                    {isSuccess && (
                        <div className="overflow-hidden border-8 border-purple-medium border-opacity-20 p-8 lg:p-14 bg-white text-black">
                            <p className="mb-7">
                                Nice! Your password has successfully been{' '}
                                {fromNewAdminVerification ? 'created' : 'reset'}.
                            </p>
                            <Link
                                to="/sign-in"
                                className="font-bold text-lg pb-1 border-b-2 border-green-400">
                                Log in to GEM SLEEP
                            </Link>
                        </div>
                    )}
                    {!isSuccess && (
                        <>
                            {error?.data && (
                                <p className="font-bold text-sm text-red mb-4">
                                    {error.data.message}
                                </p>
                            )}
                            <Formik
                                validationSchema={passwordResetSchema}
                                initialValues={{ password: '', confirmPassword: '' }}
                                onSubmit={submitHandler}>
                                {({ dirty, isValid }) => (
                                    <Form className="ph-no-capture space-y-5 lg:space-y-9 p-8 lg:p-14 border-8 border-purple-medium border-opacity-20">
                                        <CustomInput
                                            type="password"
                                            name="password"
                                            label="Password"
                                            autoComplete="new-password"
                                        />
                                        <CustomInput
                                            type="password"
                                            name="confirmPassword"
                                            label="Confirm password"
                                            autoComplete="new-password"
                                        />

                                        <button
                                            type="submit"
                                            className="btn-primary w-full"
                                            disabled={!dirty || !isValid}>
                                            Submit
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default ResetPassword;
