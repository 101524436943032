import { useLocation, useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import topRightArrow from '../../images/top-right-arrow.svg';
import { useInsuranceContext } from './InsuranceContext';
import CpapCoveragePaymentChoice from './CpapCoveragePaymentChoice';
import { toggleModal } from '../../features/ui/uiSlice';

function CpapCoverageModal() {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (location?.state?.from === '/dashboard/insurance/add') setModalOpen(true);
    }, [location?.state?.from, setModalOpen]);

    const { hasInsuranceAlternative, insurance } = useInsuranceContext();

    const handleContinueClick = () => {
        if (!insurance) {
            navigate('/dashboard/treatment/gem-bundle');
        } else if (hasInsuranceAlternative) {
            dispatch(toggleModal('maskFit'));
        } else if (
            insurance.payer?.CPAPCovered ||
            insurance.payer?.dmeReferralOrganizationId !== null
        ) {
            navigate('/dashboard/treatment/comparison');
        } else {
            navigate('/dashboard/treatment/gem-bundle');
        }
    };

    return (
        <>
            <button
                className="btn-primary flex items-center justify-between space-x-4 w-full xl:w-[280px] max-h-[68px]"
                onClick={() => {
                    setModalOpen(true);
                }}>
                <span>Get started</span>
                <img src={topRightArrow} alt="" className="w-2 lg:w-3" />
            </button>
            {modalOpen && (
                <CpapCoveragePaymentChoice
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    handleContinueClick={handleContinueClick}
                    startOfCPAPFlow={true}
                />
            )}
        </>
    );
}

export default CpapCoverageModal;
