import { gql } from '@apollo/client';

export const AVAILABLE_SLOTS_FOR_RANGE = gql`
    query availableSlotsForRange(
        $provider_id: String
        $start_date: String
        $end_date: String
        $org_level: Boolean
        $contact_type: String
        $timezone: String
        $provider_ids: [String]
        $appt_type_id: String
        $appt_loc_id: String
        $clients_can_join_waitlist: Boolean
        $appointment_to_reschedule_id: ID
    ) {
        availableSlotsForRange(
            provider_id: $provider_id
            start_date: $start_date
            end_date: $end_date
            contact_type: $contact_type
            timezone: $timezone
            org_level: $org_level
            provider_ids: $provider_ids
            appt_type_id: $appt_type_id
            appt_loc_id: $appt_loc_id
            clients_can_join_waitlist: $clients_can_join_waitlist
            appointment_to_reschedule_id: $appointment_to_reschedule_id
        ) {
            user_id
            date
            appointment_id
            is_fully_booked
            has_waitlist_enabled
        }
    }
`;

export const DAYS_AVAILABLE_FOR_RANGE = gql`
    query daysAvailableForRange(
        $provider_id: String
        $date_from_month: String
        $org_level: Boolean
        $contact_type: String
        $timezone: String
        $provider_ids: [String]
        $appt_type_id: String
        $appt_loc_id: String
        $clients_can_join_waitlist: Boolean
        $appointment_to_reschedule_id: ID
    ) {
        daysAvailableForRange(
            provider_id: $provider_id
            date_from_month: $date_from_month
            org_level: $org_level
            contact_type: $contact_type
            timezone: $timezone
            provider_ids: $provider_ids
            appt_type_id: $appt_type_id
            appt_loc_id: $appt_loc_id
            clients_can_join_waitlist: $clients_can_join_waitlist
            appointment_to_reschedule_id: $appointment_to_reschedule_id
        )
    }
`;
