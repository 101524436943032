import { ReactComponent as LeftArrow } from '../../images/left-arrow.svg';

function PageHeaderWithBack({ onBack, title }) {
    return (
        <div className="flex flex-row py-1 lg:py-4 space-x-4">
            <button className="font-heading font-bold" onClick={() => onBack()}>
                <LeftArrow className="text-black" />
            </button>
            <header>
                <h3 className="font-heading text-[44px]">{title}</h3>
            </header>
        </div>
    );
}

export default PageHeaderWithBack;
