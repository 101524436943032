function QuantityComponent({ quantity, setQuantity }) {
    return (
        <>
            <span className="text-black text-center px-4">Quantity</span>
            <div className="border-[#dbdbdb] rounded border-[1px] space-x-4 flex">
                <button
                    type="button"
                    className="bg-[#dbdbdbd3] hover:bg-[#dbdbdb4d] p-2  text-black"
                    onClick={() => setQuantity((prev) => (prev > 1 ? prev - 1 : 1))}>
                    &mdash;
                </button>
                <input
                    className="w-[25px] text-center text-black focus:outline-none"
                    type="text"
                    value={quantity}
                    readOnly
                />
                <button
                    type="button"
                    className="bg-[#dbdbdbd3] hover:bg-[#dbdbdb4d]  p-2 text-black"
                    onClick={() => setQuantity((prev) => prev + 1)}>
                    &#xff0b;
                </button>
            </div>
        </>
    );
}

export default QuantityComponent;
