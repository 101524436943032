import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCancelAppointmentMutation } from '../../app/services/appointments';
import { Modal } from '../../components';
import { toggleModal } from '../../features/ui/uiSlice';
import { AppointmentBoxListItems } from '../../components/Appointments';
import { useCheckoutContext } from '../../components/Checkout/CheckoutContext';
import posthog from 'posthog-js';

const MyAppointment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { modalsOpen, currentAppointment } = useSelector((state) => state.ui);
    const [cancelAppointment, { isLoading }] = useCancelAppointmentMutation();
    const { setCheckoutStep } = useCheckoutContext();

    return (
        <div>
            <h3 className="mb-3 text-center font-heading text-[44px] lg:text-7xl">
                {currentAppointment ? 'Your upcoming appointment' : 'No current appointment.'}
            </h3>
            {currentAppointment && (
                <>
                    <div className="mx-auto max-w-xl bg-white p-4 lg:p-16 text-gray-darker">
                        <div>
                            <p className="mb-5 text-sm font-bold text-gem-purple">
                                Session Details
                            </p>
                            <ul className="mb-8 space-y-4 text-lg">
                                <AppointmentBoxListItems appointment={currentAppointment} />
                            </ul>

                            <nav className="space-between flex items-center space-x-2">
                                <button
                                    className="btn-primary flex-1 h-[66px]"
                                    onClick={() => {
                                        navigate('../reschedule-appointment', {
                                            state: {
                                                from: 'appointmentRescheduling',
                                                appointmentToReschedule: currentAppointment,
                                            },
                                        });
                                    }}>
                                    Reschedule
                                </button>
                                <button
                                    className="btn-shell flex-1 h-[66px]"
                                    onClick={() => {
                                        dispatch(toggleModal('confirmCancellation'));
                                    }}>
                                    Cancel appointment
                                </button>
                            </nav>
                        </div>
                    </div>
                    <Modal
                        showClose={true}
                        isOpen={modalsOpen.indexOf('confirmCancellation') > -1}
                        closeHandler={() => {
                            dispatch(toggleModal('confirmCancellation'));
                        }}>
                        <div className="space-y-5">
                            <h2 className="font-heading text-5xl lg:text-7xl">Are you sure?</h2>
                            {currentAppointment.appointmentType === 'Initial Consultation' && (
                                <p className="text-sm lg:text-lg">
                                    A quick telehealth visit is required to receive a home sleep
                                    study. If you cancel, your home sleep test order will also be
                                    canceled.
                                </p>
                            )}
                            <nav className="space-between flex items-center space-x-2">
                                <button
                                    className="btn-primary"
                                    disabled={isLoading}
                                    onClick={async () => {
                                        dispatch(toggleModal('confirmCancellation'));
                                        dispatch(toggleModal('appointmentCancelledNotice'));

                                        await cancelAppointment({
                                            healthieId: currentAppointment.healthieId
                                                ? currentAppointment.healthieId
                                                : currentAppointment.id,
                                            isRescheduling: false,
                                        });
                                        setCheckoutStep(null);

                                        posthog?.capture('Cancelled appointment', {
                                            appointmentType: currentAppointment?.appointmentType,
                                            providerId: currentAppointment.providerId,
                                            timeframe: currentAppointment.timeframe,
                                            orderId: currentAppointment.order?.id,
                                        });
                                    }}>
                                    yes, cancel order
                                </button>
                                <button
                                    className="btn-shell"
                                    disabled={isLoading}
                                    onClick={() => {
                                        dispatch(toggleModal('confirmCancellation'));
                                    }}>
                                    No
                                </button>
                            </nav>
                        </div>
                    </Modal>
                </>
            )}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('appointmentCancelledNotice') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('appointmentCancelledNotice'));
                }}>
                <div className="space-y-5">
                    <h2 className="font-heading text-5xl lg:text-7xl">Appointment canceled</h2>
                    <p className="text-sm lg:text-lg">
                        We have canceled your appointment. If you paid via credit card you will see
                        a refund on your original payment method within the next 3-5 business days.
                        If you paid using insurance, a claim will not be submitted. If you change
                        your mind, you may place a new order at anytime.
                    </p>
                    <button
                        className="btn-primary"
                        onClick={() => {
                            dispatch(toggleModal('appointmentCancelledNotice'));
                            navigate('/dashboard');
                        }}>
                        Return home
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default MyAppointment;
