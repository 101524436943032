import { motion } from 'framer-motion';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useUpdatePatientInsuranceMutation } from '../../../app/services/insurance';
import { overlayVariants } from '../../../utils/constants';
import FileSelector from '../../UploadFile/FileSelector';
import { FileUploader } from '../../UploadFile/FileUploader';
import { useCheckoutContext } from '../../Checkout/CheckoutContext';
import { useInsuranceContext } from '../InsuranceContext';
import posthog from 'posthog-js';

const UploadInsuranceCard = () => {
    const [frontOfCard, setFrontOfCard] = useState(undefined);
    const [backOfCard, setBackOfCard] = useState(undefined);
    const [isUploading, setIsUploading] = useState(false);
    const { user, storageToken } = useSelector((state) => state.auth);

    const { setCheckoutStep } = useCheckoutContext();
    const { insurance } = useInsuranceContext();
    const [updatePatientInsurance] = useUpdatePatientInsuranceMutation();

    async function handleSubmit() {
        const url = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : '';
        try {
            const cardImageFrontId = frontOfCard
                ? await new FileUploader(
                      frontOfCard.file,
                      `${url}/files/${user.id}/INSURANCE_CARD_FRONT/${storageToken}`,
                  ).uploadFile()
                : null;

            const cardImageBackId = backOfCard
                ? await new FileUploader(
                      backOfCard.file,
                      `${url}/files/${user.id}/INSURANCE_CARD_BACK/${storageToken}`,
                  ).uploadFile()
                : null;

            toast.success('Your Card Has Been Uploaded');
            posthog?.capture('Uploaded insurance card');
            // associate cards with image ids
            updatePatientInsurance({
                userId: user.id,
                insuranceId: insurance.id,
                data: {
                    cardImageFrontId,
                    cardImageBackId,
                },
            });
            setCheckoutStep('is-eligible');
        } catch (err) {
            if (typeof err === 'string') {
                toast.error(err);
            }
            console.error(err);
        }
    }

    function handleFrontSelect(files) {
        if (!files || files.length === 0) return;
        setFrontOfCard(files[0]);
    }

    function handleBackSelect(files) {
        if (!files || files.length === 0) return;
        setBackOfCard(files[0]);
    }

    return (
        <>
            <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={overlayVariants}
                className="border-8 border-purple-medium border-opacity-20 px-8 py-6 lg:p-14 flex flex-wrap">
                <div className="w-full max-w-full overflow-hidden sm:basis-full lg:basis-[calc(50%-20px)] lg:m-2.5">
                    <p className="font-heading text-xl p-1">FRONT of Card</p>
                    <FileSelector
                        key="frontOfCardSelector"
                        multipleUploads={false}
                        maximumFiles={1}
                        handleFileSelect={handleFrontSelect}
                        id="front"
                    />
                </div>
                <div className="w-full max-w-full overflow-hidden sm:basis-full lg:basis-[calc(50%-20px)] lg:m-2.5">
                    <p className="font-heading text-xl p-1">BACK of Card</p>
                    <FileSelector
                        key="backOfCardSelector"
                        multipleUploads={false}
                        maximumFiles={1}
                        handleFileSelect={handleBackSelect}
                        id="back"
                    />
                </div>
                <button
                    aria-label="Upload files"
                    type="button"
                    onClick={() => {
                        setIsUploading(true);
                        handleSubmit();
                    }}
                    disabled={(!frontOfCard && !backOfCard) || isUploading}
                    className="btn-primary w-full lg:mx-2 mt-2">
                    Upload
                </button>
            </motion.div>
            <p className="text-center w-full mx-2 my-2 xs:my-4">- OR -</p>
            <button
                className="btn-primary w-full bg-gray"
                aria-label="Continue without Uploading"
                // Moves user to next insurance step
                onClick={() => setCheckoutStep('is-eligible')}>
                Continue without uploading
            </button>
        </>
    );
};

export default UploadInsuranceCard;
