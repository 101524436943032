import { differenceInDays, format, parse, set } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import { setAppointmentBookingData } from '../../../features/ui/uiSlice';
//CSS for the react-datepicker is in the .scss file loaded below
import { useEffect } from 'react';
import './styles/datepicker.scss';

function DayPicker({ daysAvailableForRange, selectedDay, setSelectedDay }) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (daysAvailableForRange && daysAvailableForRange.length > 0) {
            const nextAvailableDayString = daysAvailableForRange.find((day) => {
                return differenceInDays(day, format(new Date(), 'yyyy-MM-dd')) > 0;
            });
            if (nextAvailableDayString) {
                const nextAvailableDay = parse(nextAvailableDayString, 'yyyy-MM-dd', new Date());
                setSelectedDay(nextAvailableDay);
            }
        }
    }, [daysAvailableForRange, setSelectedDay]);
    return (
        <div className="text-center mr-0 pt-4 overflow-visible">
            <DatePicker
                inline
                useWeekdaysShort={true}
                onMonthChange={(e) => {
                    setSelectedDay(set(e, { date: 1 }));
                    // clear any slot selection
                    dispatch(setAppointmentBookingData({ key: 'selectedSlot', value: '' }));
                }}
                onChange={(e) => {
                    setSelectedDay(e);
                    // clear any slot selection
                    dispatch(setAppointmentBookingData({ key: 'selectedSlot', value: '' }));
                }}
                selected={selectedDay}
                //Parse the days available into format for highlighted dates
                highlightDates={daysAvailableForRange?.map((day) =>
                    parse(day, 'yyyy-MM-dd', new Date()),
                )}
            />
        </div>
    );
}

export default DayPicker;
