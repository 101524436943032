import { toast } from 'react-toastify';
import { useAddPayerMutation, useGetNikoPayersQuery } from '../../../app/services/admin/insurance';
import { useGetPayersQuery } from '../../../app/services/insurance';

function NikoPayers() {
    //Queries
    const [addPayer] = useAddPayerMutation();
    const { data: payers, refetch: payersRefetch } = useGetPayersQuery();
    const { data: nikoPayers, isLoading: nikoPayersLoading } = useGetNikoPayersQuery();

    const addNikoPayer = (nikoPayer) => {
        const payer = {
            name: nikoPayer.Name,
            claimCode: nikoPayer.ClaimCode,
            nikoPayerId: nikoPayer.Id,
        };
        addPayer(payer);
        toast.success('Payer added!', { theme: 'dark' });
        payersRefetch();
    };

    return nikoPayersLoading ? (
        <p className="py-6 font-heading text-5xl">Loading Niko Payers...</p>
    ) : (
        <>
            <h4 className="font-heading text-3xl">Niko Payer List</h4>

            <div className="grid grid-cols-[500px_120px_120px] space-y-2 items-center">
                <div className="col-span-3 grid grid-cols-[500px_120px_120px] text-gray border-b my-4">
                    <h4>Name</h4>
                    <h4>Claim Code</h4>
                    <h4 className="text-center">Actions</h4>
                </div>
                {nikoPayers &&
                    nikoPayers.map((nikoPayer) => {
                        // exclude if nikoPayer.id is in payer.nikoPayerId
                        if (payers.some((payer) => payer.nikoPayerId === nikoPayer.Id)) {
                            return null;
                        } else {
                            return (
                                <>
                                    <p>{nikoPayer.Name}</p>
                                    <p>{nikoPayer.ClaimCode}</p>
                                    <button
                                        className="btn-primary-small"
                                        onClick={(event) => addNikoPayer(nikoPayer)}>
                                        Add
                                    </button>
                                </>
                            );
                        }
                    })}
            </div>
        </>
    );
}

export default NikoPayers;
