import { useSelector } from 'react-redux';
import defaultResmedCpap from '../../images/cpap/default-resmed.png';

const ProductImage = ({ sku, alt, ...props }) => {
    const { catalogItems } = useSelector((state) => state.catalog);
    const targetProduct = catalogItems.find((product) =>
        product.itemData.variations.some((v) => v.itemVariationData?.sku === sku),
    );

    return <img src={targetProduct?.images[0] ?? defaultResmedCpap} alt={alt} {...props} />;
};

export default ProductImage;
