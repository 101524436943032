import { useSelector } from 'react-redux';
import { useGetOrganizationQuery } from '../../app/services/insurance';
import { SleepStudyDownloadLink } from '../../components/Diagnosis';

const DentalAppointment = () => {
    const { user } = useSelector((state) => state.auth);
    const { data: dentalAttribution } = useGetOrganizationQuery(
        user.patientProfile.referToOrganizationId,
        {
            skip: !user?.patientProfile?.referToOrganizationId,
        },
    );

    return (
        <div className="layout-outlet-root">
            <h3 className="mb-2 font-heading text-[44px] leading-none lg:mb-3 lg:text-7xl">
                THERE IS AN ALTERNATIVE!
            </h3>
            <p className="mb-6 text-xl lg:mb-10 lg:text-2xl">
                You are eligible for a referral to Dental Sleep Medicine.
            </p>

            {dentalAttribution ? (
                <>
                    <p className="mb-4 max-w-4xl text-sm lg:mb-6 lg:text-lg">
                        After detailed review of your Home Sleep Study results a GEM SLEEP Provider
                        feels you would be a good candidate for an Oral Appliance to treat your
                        sleep apnea.
                    </p>

                    <p className="mb-4 max-w-4xl text-sm lg:mb-6 lg:text-lg">
                        GEM SLEEP has a partnership with the{' '}
                        <b>{`${dentalAttribution.name} in ${dentalAttribution.city}, ${dentalAttribution.state}, ${dentalAttribution.address}`}</b>
                        .
                    </p>

                    <p className="mb-4 max-w-4xl text-sm lg:mb-6 lg:text-lg">
                        If you are interested in this option please click below to schedule your
                        free initial consultation to learn more. Download a copy of your GEM SLEEP
                        study to bring with you to your appointment.
                    </p>
                </>
            ) : (
                <p className="mb-4 max-w-4xl text-sm lg:mb-6 lg:text-lg">
                    Based on your results, your GEM SLEEP clinician has determined that oral
                    appliance therapy may be a suitable therapy alternative for you. If you are
                    interested in pursuing oral appliance therapy, you can find a dental sleep
                    medicine provider in your area in the directory of the{' '}
                    <a
                        href="https://mms.aadsm.org/members/directory/search_bootstrap.php?org_id=ADSM"
                        target="_blank"
                        rel="noreferrer"
                        className="link">
                        American Academy of Dental Sleep Medicine
                    </a>
                    .
                </p>
            )}

            <div className="mb-10 md:flex items-center md:space-x-4">
                {dentalAttribution ? (
                    <>
                        <a
                            href={dentalAttribution.schedulingLink}
                            rel="noreferrer"
                            target="_blank"
                            className="btn-primary w-full lg:w-[350px]">
                            Schedule a free phone consult
                        </a>
                        <SleepStudyDownloadLink styling="btn-primary group relative mt-2 w-full md:mt-0 md:w-[350px]" />
                    </>
                ) : (
                    <a
                        href="https://mms.aadsm.org/members/directory/search_bootstrap.php?org_id=ADSM"
                        rel="noreferrer"
                        target="_blank"
                        className="btn-primary w-full mt-2 md:mt-0 lg:w-[350px]">
                        Find an Oral Appliance Provider
                    </a>
                )}
                <a
                    href="https://www.mygemsleep.com/dental-sleep-medicine"
                    rel="noreferrer"
                    target="_blank"
                    className="btn-primary w-full lg:w-[350px]">
                    learn more
                </a>
            </div>

            <p className="mb-4 text-sm font-semibold">
                *Dental Sleep Medicine may be covered by your insurance.
            </p>
        </div>
    );
};

export default DentalAppointment;
