import * as yup from 'yup';

const today = new Date();

const regexPhoneNumber =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const createAdminUserSchema = yup
    .object({
        firstName: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('First name is required'),
        lastName: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('Last name is required'),
        phone: yup
            .string()
            .matches(regexPhoneNumber, 'Phone number is not valid')
            .min(10, 'Phone number is too short')
            .max(10, 'Phone number is too long'),
        textNumber: yup
            .string()
            .matches(regexPhoneNumber, 'Phone number is not valid')
            .min(10, 'Phone number is too short')
            .max(10, 'Phone number is too long'),
        email: yup.string().email('Must be a valid email address.').required('Email is required.'),
        organization: yup.string().required('Organization is required'),
        checkboxes: yup.boolean().when(['roleMD', 'roleCoach'], {
            is: (roleMD, roleCoach) => !roleMD && !roleCoach,
            then: (schema) => schema.required('At least one role is required'),
        }),
        nikoRenderingProviderId: yup.string(),
    })
    .required();

export const organizationSchema = yup
    .object({
        name: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('Name is required'),
        address: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('Address is required'),
        city: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('City is required'),
        state: yup.string().required('State is required'),
        zip: yup
            .string()
            .required('Zip is required')
            .matches(/^\d{5}$/, 'Zip must be 5 digits'),
        phone: yup
            .string()
            .required('Phone number is required')
            .matches(/^\d{10}$/, 'Phone number must be 10 digits'),
        schedulingLink: yup
            .string()
            .required('Scheduling link is required')
            .matches(
                /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?$/,
                'Must be a valid url',
            ),
        websiteUrl: yup.string().required('A website is required'),
        email: yup.string().email('Must be a valid email address.').required('Email is required.'),
    })
    .required();

export const checkoutAddressSchema = yup
    .object({
        street1: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('Address is required'),
        city: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('City is required'),
        state: yup.string().required('State is required'),
        zip: yup
            .string()
            .required('Zip is required')
            .matches(/^\d{5}$/, 'Zip must be 5 digits'),
    })
    .required();

export const updatePatientSchema = yup
    .object({
        firstName: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('First name is required'),
        lastName: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('Last name is required'),
        birthdate: yup.string().required('DOB is required'),
        email: yup.string().email('Must be a valid email address.').required('Email is required.'),
        phone: yup
            .string()
            .required('Phone number is required')
            .matches(/^\d{10}$/, 'Phone number must be 10 digits without dashes'),
        street1: yup.string(),
        street2: yup.string(),
        city: yup.string(),
        state: yup.string(),
        zip: yup.string().matches(/^\d{5}$/, 'Zip must be 5 digits'),
    })
    .required();

export const updateProfileSchema = yup
    .object({
        firstName: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('First name is required'),
        lastName: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('Last name is required'),
        phone: yup
            .string()
            .required('Phone number is required')
            .matches(/^\d{10}$/, 'Phone number must be 10 digits without dashes'),
        street1: yup.string(),
        street2: yup.string(),
        city: yup.string(),
        state: yup.string(),
        zip: yup.string().matches(/^\d{5}$/, 'Zip must be 5 digits'),
    })
    .required();

export const bigDateSchema = yup
    .object({
        dd: yup
            .number()
            .min(1, 'Minimum 1')
            .max(31, 'Max of 2 digits, 31 is the maximum value')
            .required('You must enter the day')
            .typeError('This field requires digits'),
        mm: yup
            .number()
            .min(1, 'Minimum 1')
            .max(12, 'Maximum 12')
            .required('You must enter the month')
            .typeError('This field requires digits'),
        yyyy: yup
            .number()
            .min(1900, 'Minimum 1900')
            .max(today.getFullYear() - 18, 'You have to be 18')
            .required('You must enter the year')
            .typeError('This field requires digits'),
    })
    .required();

export const heightSchema = yup
    .object({
        feet: yup
            .number()
            .min(1, 'Minimum 1')
            .max(7, '7 max')
            .required('Required')
            .typeError('This field requires a digit'),
        inches: yup
            .number()
            .min(0, 'Minimum 0')
            .max(11, 'Maximum 11')
            .required('Required')
            .typeError('This field requires a digit'),
    })
    .required();

export const weightSchema = yup
    .object({
        ipt: yup
            .number()
            .min(50, 'Minimum 50')
            .max(500, 'Maximum 500')
            .required('Required')
            .typeError('This field requires a digit'),
    })
    .required();

export const authenticationSchema = yup
    .object({
        email: yup.string().email('Must be a valid email address.').required('Email is required.'),
        password: yup.string().required('Password is required'),
    })
    .required();

export const passwordResetSchema = yup
    .object({
        password: yup
            .string()
            .required('Password is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-])(?=.{8,})/,
                'Must contain 8 characters, one uppercase, one lowercase, one number and one special character (!@#$%^&*-)',
            ),
        confirmPassword: yup
            .string()
            .required('Password confirm is required')
            .equals([yup.ref('password')], 'Passwords must match.'),
    })
    .required();

export const passwordResetRequestSchema = yup
    .object({
        email: yup.string().email('Must be a valid email address.').required('Email is required.'),
    })
    .required();

export const callMeBackSchema = yup
    .object({
        phone: yup
            .string()
            .required('Phone number is required')
            .matches(/^\d{10}$/, 'Phone number must be 10 digits'),
        reasonForCall: yup.string(),
    })
    .required();

export const insuranceCreateSchema = yup
    .object({
        payer: yup.object().nullable().required('Please select an option.'),
        otherPayerName: yup.string().when('payer', {
            is: (payer) => payer?.payerType === 'OTHER',
            then: (schema) =>
                schema
                    .trim('No leading or trailing spaces')
                    .strict(true)
                    .min(1)
                    .required('Insurer name required.'),
        }),
        memberId: yup.string().when('payer', {
            is: (payer) => payer?.payerType !== 'OTHER',
            then: (schema) =>
                schema
                    .trim('No leading or trailing spaces')
                    .strict(true)
                    .min(1)
                    .required('Member ID required.'),
        }),
        groupId: yup.string().when('payer', {
            is: (payer) => payer?.requiresGroupId,
            then: (schema) =>
                schema
                    .trim('No leading or trailing spaces')
                    .strict(true)
                    .min(1)
                    .required('Group ID required.'),
        }),
        multiplan: yup.boolean(),
        agreeToBenefits: yup.boolean().oneOf([true], 'You must agree to the benefits'),
    })
    .required();

export const insuranceUpdateSchema = yup
    .object({
        payer: yup.object().nullable().required('Please select an option.'),
        otherPayerName: yup.string().when('payer', {
            is: (payer) => payer?.payerType === 'OTHER',
            then: (schema) =>
                schema
                    .trim('No leading or trailing spaces')
                    .strict(true)
                    .min(1)
                    .required('Insurer name required.'),
        }),
        memberId: yup.string().when('payer', {
            is: (payer) => payer?.payerType !== 'OTHER',
            then: (schema) =>
                schema
                    .trim('No leading or trailing spaces')
                    .strict(true)
                    .min(1)
                    .required('Member ID required.'),
        }),
        groupId: yup.string().when('payer', {
            is: (payer) => payer?.requiresGroupId,
            then: (schema) =>
                schema
                    .trim('No leading or trailing spaces')
                    .strict(true)
                    .min(1)
                    .required('Group ID required.'),
        }),
        patientDob: yup
            .string()
            .required('Required.')
            .matches(/^\d{1,2}\/\d{1,2}\/\d{4}$/, 'Date must be MM/DD/YYYY'),
        patientFirstName: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('Required.'),
        patientLastName: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('Required.'),
        policyHolderFirstName: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('Required.'),
        policyHolderLastName: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('Required.'),
        policyHolderDob: yup
            .string()
            .required('Required.')
            .matches(/^\d{1,2}\/\d{1,2}\/\d{4}$/, 'Date must be MM/DD/YYYY'),
    })
    .required();

export const emailSchema = yup.string().email('Must be a valid email address.');

export const nameSchema = yup.string().matches(/^[a-z,.'-]+$/i, 'Must be a valid name');

export const numberSchema = yup.string().matches(/^\d+$/, 'ID must be a number.');

export const shipmentSchema = yup
    .object({
        trackingNumber: yup
            .string()
            .trim('No leading or trailing spaces')
            .strict(true)
            .min(1)
            .required('A tracking number is required'),
        carrier: yup.string().required('A carrier is required'),
        status: yup.string().required('A status is required'),
        shipmentDate: yup.string().required('A shipment date is required'),
    })
    .required();
