import { useState } from 'react';
import Modal from '../../../Modal/Modal';
import { toast } from 'react-toastify';
import {
    useAddCredentialPayerMutation,
    useRemoveCredentialPayerMutation,
} from '../../../../app/services/admin/insurance';
import { useGetAdminUsersQuery } from '../../../../app/services/admin/admin';

const getCredentialedAdmins = ({ payer, adminUsers }) => {
    if (!payer?.providerCredentials) {
        return [];
    }
    const providerCredentials = payer.providerCredentials;
    const credAdmins = adminUsers?.results.filter((adminUser) => {
        const isInProviderCredentials = providerCredentials.some(
            (providerCredential) => providerCredential.userId === adminUser.id,
        );
        return isInProviderCredentials;
    });
    return credAdmins;
};

const getOtherAdmins = ({ payer, adminUsers }) => {
    if (!payer?.providerCredentials) {
        return [];
    }
    const providerCredentials = payer.providerCredentials;
    const otherAdmins = adminUsers.results.filter((adminUser) => {
        const isInProviderCredentials = providerCredentials.some(
            (providerCredential) => providerCredential.userId === adminUser.id,
        );
        return !isInProviderCredentials;
    });
    return otherAdmins;
};

const getArrayDiffs = (old, newA) => {
    const toAdd = [];
    const toRemove = [];
    //find all removed admins
    for (let i = 0; i < old.length; i++) {
        if (!newA.includes(old[i])) {
            toRemove.push(old[i]);
        }
    }
    //find all added admins
    for (let i = 0; i < newA.length; i++) {
        if (!old.includes(newA[i])) {
            toAdd.push(newA[i]);
        }
    }
    return [toAdd, toRemove];
};

function EditPayerProviders({ editingPayer, modalOpen, setEditingPayer, setModalOpen }) {
    //Queries and Mutations
    const [addCredentialPayer] = useAddCredentialPayerMutation();
    const [removeCredentialPayer] = useRemoveCredentialPayerMutation();
    const adminUserQuery = [{ userRoles: { some: { role: 'MD' } } }];
    const { data: adminUsers } = useGetAdminUsersQuery(adminUserQuery);

    //Local State
    const [addingCred, setAddingCred] = useState(null);
    const [tempCredProviders, setTempCredProviders] = useState(
        getCredentialedAdmins({ payer: editingPayer, adminUsers: adminUsers }),
    );
    const [tempNonCredProviders, setTempNonCredProviders] = useState(
        getOtherAdmins({ payer: editingPayer, adminUsers: adminUsers }),
    );

    async function handleSubmit() {
        const original = getCredentialedAdmins({ payer: editingPayer, adminUsers: adminUsers }).map(
            (user) => user.id,
        );
        const newCred = tempCredProviders.map((user) => user.id);
        const diffs = getArrayDiffs(original, newCred);

        for (let v = 0; v < diffs[0].length; v++) {
            addCredentialPayer({
                userId: diffs[0][v],
                payerId: editingPayer.id,
            });
        }

        for (let v = 0; v < diffs[1].length; v++) {
            removeCredentialPayer({
                userId: diffs[1][v],
                payerId: editingPayer.id,
            });
        }

        toast.success('Credentialed Providers Updated!');
        setEditingPayer(null);
        setModalOpen(null);
    }

    const editCredentialedPayers = (userID, cred) => {
        const updatedTempCredProviders = [...tempCredProviders];
        const updatedTempNonCredProviders = [...tempNonCredProviders];
        //remove case
        if (cred) {
            const index = updatedTempCredProviders.findIndex((provider) => provider.id === userID);
            if (index !== -1) {
                const [removedProvider] = updatedTempCredProviders.splice(index, 1);
                updatedTempNonCredProviders.push(removedProvider);
            }
            setTempCredProviders(updatedTempCredProviders);
            setTempNonCredProviders(updatedTempNonCredProviders);
        }
        //add case
        else {
            const index = updatedTempNonCredProviders.findIndex(
                (provider) => provider.id === userID,
            );
            if (index !== -1) {
                const [removedProvider] = updatedTempNonCredProviders.splice(index, 1);
                updatedTempCredProviders.push(removedProvider);
            }
            setTempCredProviders(updatedTempCredProviders);
            setTempNonCredProviders(updatedTempNonCredProviders);
        }
    };

    return (
        <Modal
            showClose={true}
            isOpen={editingPayer !== null && modalOpen === 'providers'}
            closeHandler={() => {
                setEditingPayer(null);
                setModalOpen(null);
            }}>
            <div className="py-4 space-y-4">
                <h3 className="font-heading text-2xl border-b border-gray-400">
                    Credentialed Providers
                </h3>
                <ul className="mt-2">
                    {tempCredProviders.map((admin) => (
                        <li key={admin.id} className=" font-bold flex">
                            <p className="min-w-[200px]">
                                {admin.firstName} {admin.lastName}
                            </p>
                            <button
                                type="button"
                                className="text-red hover:text-red-hover"
                                onClick={() => {
                                    editCredentialedPayers(admin.id, true);
                                }}>
                                Remove
                            </button>
                        </li>
                    ))}
                </ul>
                <button
                    type="button"
                    className="border-b-2 border-gem-green font-bold"
                    onClick={() => {
                        setAddingCred(!addingCred);
                    }}>
                    {addingCred ? 'Hide Other Providers' : 'Show Other Providers'}
                </button>
                {addingCred && (
                    <ul>
                        {tempNonCredProviders.map((admin) => (
                            <li key={admin.id} className="font-bold flex">
                                <p className="min-w-[200px]">
                                    {admin.firstName} {admin.lastName}
                                </p>
                                <button
                                    type="button"
                                    className="text-gem-green hover:text-green-hover"
                                    onClick={() => {
                                        editCredentialedPayers(admin.id, false);
                                    }}>
                                    Add
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <button className="btn-primary w-full" onClick={() => handleSubmit()}>
                Save Providers
            </button>
        </Modal>
    );
}

export default EditPayerProviders;
