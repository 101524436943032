import { Form, Formik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import {
    useLoginMutation,
    useResendCodeMutation,
    useVerifyCodeMutation,
} from '../../app/services/auth';
import AccountPasswordReset from '../../components/MyAccount/AccountPasswordReset';
import { CustomInput } from '../../components/InputComponents';
import { toggle, toggleModal } from '../../features/ui/uiSlice';
import { overlayVariants } from '../../utils/constants';
import { authenticationSchema } from '../../utils/schemas';

const UserRedirect = ({ isMD, locationState }) => {
    if (isMD && !locationState?.from) return <Navigate to="/admin" replace={true} />;

    if (locationState?.from) return <Navigate to={locationState.from} replace={true} />;

    return <Navigate to="/dashboard" replace={true} />;
};

const SignIn = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { user, isMD, twoFactorRequired } = useSelector((state) => state.auth);
    const { passwordResetScreenOpen } = useSelector((state) => state.ui);
    const [login, { isLoading, error, isSuccess }] = useLoginMutation();
    const [
        verifyCode,
        {
            isLoading: checkingCode,
            error: verifyError,
            reset: verifyReset,
            isSuccess: verifySuccess,
        },
    ] = useVerifyCodeMutation();
    const [resendCode, { isLoading: resendingCode, error: resendError, reset: resendReset }] =
        useResendCodeMutation();

    const submitHandler = async (data) => {
        await login(data);
    };

    const verifyCodeHandler = async (data) => {
        if (verifyError?.data) {
            verifyReset();
        }
        if (resendError?.data) {
            resendReset();
        }
        await verifyCode(data);
    };

    useEffect(() => {
        // this error happens when a verficaiton link is visited twice, as in the case with links from Outlook.
        // when this is detected we want to show the email verification modal.
        if (location.state?.withError === 'INVALID_VERIFICATION_TOKEN' && dispatch) {
            dispatch(toggleModal('emailVerified'));
        }
    }, [location, dispatch]);

    if (user && (isSuccess || verifySuccess)) {
        return <UserRedirect isMD={isMD} locationState={location.state} />;
    } else if (twoFactorRequired) {
        return (
            <div className="container max-w-3xl">
                <h2 className="mb-9 font-heading text-5xl uppercase text-offwhite lg:mb-12 lg:text-[80px]">
                    Hey there! <span className="xs:block opacity-60">Nice to see you again.</span>
                </h2>

                {verifyError?.data?.statusCode === 401 && (
                    <p className="mb-4 font-bold text-red">{verifyError.data.message}</p>
                )}
                {resendError?.data?.statusCode === 401 && (
                    <p className="mb-4 font-bold text-red">{resendError.data.message}</p>
                )}

                <Formik initialValues={{ verificationCode: '' }} onSubmit={verifyCodeHandler}>
                    {({ dirty }) => (
                        <Form
                            id="gem-verification-code"
                            name="gem-verification-code"
                            className="mb-9 space-y-6 border-8 border-purple-medium border-opacity-20 p-8 lg:mb-12 lg:space-y-9 lg:p-14">
                            <CustomInput
                                type="verificationCode"
                                name="verificationCode"
                                label="Verification Code"
                                className="ph-no-capture"
                            />
                            <span
                                className="inline-block cursor-pointer font-bold text-green-200"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    resendCode();
                                }}>
                                {resendingCode ? 'Resending New Code...' : 'Resend New Code'}
                            </span>
                            <button
                                type="submit"
                                className="btn-primary mt-0 w-full"
                                disabled={!dirty || checkingCode}>
                                {checkingCode ? 'Verifying Code...' : 'Verify Code'}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    } else {
        return (
            <AnimatePresence>
                <div className="container max-w-3xl">
                    <h2 className="mb-9 font-heading text-5xl uppercase text-offwhite lg:mb-12 lg:text-[80px] mt-2">
                        Hey there!{' '}
                        <span className="xs:block opacity-60">Nice to see you again.</span>
                    </h2>

                    {error?.data?.statusCode === 401 && (
                        <p className="mb-4 font-bold text-red">
                            Incorrect user name or password, please try again.
                        </p>
                    )}

                    {location.state?.withError && (
                        <p className="mb-4 font-bold">
                            {location.state.withError === 'INVALID_VERIFICATION_TOKEN'
                                ? 'Your email address has been verified. Please sign in below.'
                                : location.state.withError}
                        </p>
                    )}

                    <Formik
                        validationSchema={authenticationSchema}
                        initialValues={{ email: '', password: '' }}
                        onSubmit={submitHandler}>
                        {({ dirty, isValid, isSubmitting }) => (
                            <Form
                                id="gem-sign-in"
                                name="gem-sign-in"
                                className="mb-6 space-y-6 border-8 border-purple-medium border-opacity-20 p-8 lg:space-y-8 lg:p-14">
                                <CustomInput
                                    type="email"
                                    name="email"
                                    label="Email"
                                    autoComplete="username"
                                    className="ph-no-capture"
                                />
                                <CustomInput
                                    type="password"
                                    name="password"
                                    label="Password"
                                    autoComplete="current-password"
                                    className="ph-no-capture"
                                />
                                <span
                                    className="inline-block cursor-pointer font-bold text-green-200"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        dispatch(toggle({ key: 'passwordResetScreenOpen' }));
                                    }}>
                                    Forgot password?
                                </span>
                                <button
                                    type="submit"
                                    className="btn-primary mt-0 w-full"
                                    disabled={!dirty || !isValid || isSubmitting || isLoading}>
                                    {isLoading ? 'Signing in...' : 'Sign into GEM SLEEP'}
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>

                {passwordResetScreenOpen && (
                    <motion.div
                        key="account-overlay"
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={overlayVariants}
                        onClick={() => {
                            dispatch(toggle({ key: 'passwordResetScreenOpen' }));
                        }}
                        className="fixed top-0 bottom-0 z-30 w-screen bg-purple-dark/90 backdrop-blur-md"></motion.div>
                )}
                <motion.div
                    key="account-password-reset"
                    className={`fixed top-0 right-0 z-40 h-screen w-screen overflow-y-auto bg-white px-5 py-16 text-black transition duration-300 lg:w-1/3 lg:px-9 lg:py-32 ${
                        passwordResetScreenOpen ? 'translate-x-0' : 'translate-x-full'
                    }`}>
                    <AccountPasswordReset />
                </motion.div>
            </AnimatePresence>
        );
    }
};

export default SignIn;
