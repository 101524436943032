import { api } from '../auth';

const organizationsAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        connectToOrganization: builder.mutation({
            query: ({ userId, orgId, referTo, referFrom }) => ({
                url: `/organization/${orgId}/connect`,
                method: 'PUT',
                body: {
                    referTo: referTo,
                    referFrom: referFrom,
                },
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.userId }],
        }),
        createPartnerOrganization: builder.mutation({
            query: (data) => ({
                url: '/organization',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Organization', id: 'PARTIAL_LIST' }],
        }),
        disconnectFromOrganization: builder.mutation({
            query: ({ userId, orgId, referTo, referFrom }) => ({
                url: `/organization/${orgId}/disconnect`,
                method: 'PUT',
                body: {
                    referTo: referTo,
                    referFrom: referFrom,
                },
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.userId }], //forces refetch on specific user
        }),
        getPartnerOrganizationTypes: builder.query({
            query: () => `/organization/types`,
        }),
        updatePartnerOrganization: builder.mutation({
            query: ({ id, ...partnerFields }) => ({
                url: `/organization/${id}`,
                method: 'PUT',
                body: partnerFields,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Organization', id: arg.id }],
        }),
    }),
    overrideExisting: false,
});

export const {
    useConnectToOrganizationMutation,
    useCreatePartnerOrganizationMutation,
    useGetPartnerOrganizationTypesQuery,
    useDisconnectFromOrganizationMutation,
    useUpdatePartnerOrganizationMutation,
} = organizationsAPI;
