import { api } from './auth';
const ONE_HOUR = 60 * 60;
// default cache time is 60 seconds, so we override it to 1 hour because this stuff
// will most likely never change during a user's session
const webflowAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getAdviceQuotes: builder.query({
            query: () => '/webflow?collection=advice-quotes',
            keepUnusedDataFor: ONE_HOUR,
        }),
        getBlogCategories: builder.query({
            query: () => '/webflow?collection=blog-category',
            keepUnusedDataFor: ONE_HOUR,
        }),
        getBlogPosts: builder.query({
            query: () => '/webflow?collection=post',
            transformResponse: (response) => {
                const filteredResponses = response.items
                    //Eliminate any blog posts still in draft status
                    .filter((blogPost) => blogPost._draft === false)
                    //Sort by date published on, so that republishing can put it to the front of the list
                    .sort((a, b) =>
                        new Date(b['published-on']) < new Date(a['published-on']) ? -1 : 1,
                    );

                return filteredResponses;
            },
            keepUnusedDataFor: ONE_HOUR,
        }),
        getWebflowTerms: builder.query({
            query: () => '/webflow?collection=terms',
            keepUnusedDataFor: ONE_HOUR,
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetAdviceQuotesQuery,
    useGetBlogCategoriesQuery,
    useGetBlogPostsQuery,
    useGetWebflowTermsQuery,
} = webflowAPI;
