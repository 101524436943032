import {
    useAddToPoolMutation,
    useGetCareTeamPoolsQuery,
    useRemoveFromPoolMutation,
} from '../../../app/services/admin/careteam';
import { hasRole } from '../../../utils/roleUtils';
import { CustomReactSelect } from '../../InputComponents';
import { Formik, Form } from 'formik';
import { motion } from 'framer-motion';

function determineIfMemberOfPool(user, pools) {
    if (user && pools) {
        return pools.find((pool) => pool.poolMembers.some((member) => member.memberId === user.id));
    } else {
        return undefined;
    }
}

const CareTeamPools = ({ adminUser }) => {
    //Queries
    const { data: pools, isLoading: poolsLoading } = useGetCareTeamPoolsQuery();
    const [addToPool] = useAddToPoolMutation();
    const [removeFromPool] = useRemoveFromPoolMutation();

    const isMemberOfPool = determineIfMemberOfPool(adminUser, pools);

    return (
        <>
            {adminUser && hasRole(adminUser.userRoles, 'COACH') && !poolsLoading && (
                <div className="rounded-xl bg-gray-darker p-5">
                    <p className="mb-3 text-xl font-bold">Careteam Pool:</p>

                    {poolsLoading ? (
                        <motion.div
                            className="space-y-10 p-12"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}>
                            <h2 className="mb-10 font-heading text-2xl lg:text-5xl">
                                Loading Carteam Pool Info...
                            </h2>
                        </motion.div>
                    ) : (
                        <div className="flex items-center space-x-4">
                            <Formik
                                initialValues={{
                                    poolId: isMemberOfPool ? isMemberOfPool.id : 0,
                                }}>
                                {({ values, dirty, isValid, isSubmitting }) => (
                                    <Form>
                                        <div className="relative flex items-center space-x-4">
                                            <CustomReactSelect
                                                disabled={isMemberOfPool}
                                                name="poolId"
                                                placeholder="Choose a pool"
                                                options={pools.map((pool) => ({
                                                    label: pool.name,
                                                    value: pool.id,
                                                }))}
                                                className="flex-1 min-w-[250px]"
                                            />
                                            {isMemberOfPool !== undefined && (
                                                <button
                                                    className="btn-primary-small"
                                                    onClick={async (ev) => {
                                                        await removeFromPool({
                                                            poolId: values.poolId,
                                                            memberId: adminUser.id,
                                                        });
                                                    }}>
                                                    Remove From Pool
                                                </button>
                                            )}

                                            {isMemberOfPool === undefined && (
                                                <button
                                                    className="btn-primary-small"
                                                    disabled={values.poolId === 0}
                                                    onClick={async (ev) => {
                                                        await addToPool({
                                                            poolId: values.poolId,
                                                            memberId: adminUser.id,
                                                        });
                                                    }}>
                                                    Add to Pool
                                                </button>
                                            )}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default CareTeamPools;
