import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    CreateOrganization,
    OrganizationList,
    UpdateOrganization,
} from '../../components/Admin/Organizations';
import { ReferralsList } from '../../components/Admin/Referrals';

function Organizations() {
    const navigate = useNavigate();
    const { orgTab } = useParams();

    useEffect(() => {
        if (!orgTab) {
            navigate('list', { replace: true });
        }
    }, [navigate, orgTab]);

    return (
        <>
            <h2 className="mb-5 pt-6 flex items-center justify-between">
                <span className="font-heading text-5xl">Organizations</span>
                <Link
                    to="/admin/organizations/create"
                    className="border-b-2 border-gem-green font-bold">
                    Add a new organization
                </Link>
            </h2>
            <nav className="my-4">
                <button
                    onClick={(ev) => {
                        navigate(`/admin/organizations/list`, {
                            replace: true,
                        });
                    }}
                    className={`border-gray border-b py-2 min-w-[150px] ${orgTab === 'list' ? 'text-gem-green border-gem-green! font-bold' : 'hover:text-gem-green hover:font-bold'}`}>
                    Organization List
                </button>
                <button
                    onClick={(ev) => {
                        navigate(`/admin/organizations/referrals`, {
                            replace: true,
                        });
                    }}
                    className={`border-gray border-b py-2 min-w-[150px] ${orgTab === 'referrals' ? 'text-gem-green border-gem-green! font-bold' : 'hover:text-gem-green hover:font-bold'}`}>
                    Referrals
                </button>
                {orgTab === 'create' && (
                    <button
                        key="create-org-nav"
                        className="border-gray border-b py-2 min-w-[150px] text-gem-green border-gem-green! font-bold">
                        Create
                    </button>
                )}
                {orgTab === 'update' && (
                    <button
                        key="update-org-nav"
                        className="border-gray border-b py-2 min-w-[150px] text-gem-green border-gem-green! font-bold">
                        Update
                    </button>
                )}
            </nav>
            {orgTab === 'list' && <OrganizationList />}
            {orgTab === 'referrals' && <ReferralsList />}
            {orgTab === 'create' && <CreateOrganization />}
            {orgTab === 'update' && <UpdateOrganization />}
        </>
    );
}

export default Organizations;
