import { UploadCardComponent } from '../../components/Insurance';
import { PageHeaderWithBack } from '../../components/LayoutComponents';
import { useNavigate } from 'react-router';

function UploadCard() {
    const navigate = useNavigate();

    return (
        <>
            <PageHeaderWithBack
                onBack={() => navigate('/dashboard/insurance')}
                title="Upload Card"
            />
            <UploadCardComponent onSuccess={() => navigate('/dashboard/insurance/')} />
        </>
    );
}

export default UploadCard;
