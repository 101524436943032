import { useNavigate } from 'react-router-dom';
import { useInsuranceContext } from '../../components/Insurance/InsuranceContext';
import { InsuranceCardDisplay } from '../../components/Insurance';

const CurrentInsurance = () => {
    const navigate = useNavigate();
    const { ready, insurance, isFetching } = useInsuranceContext();

    if (!ready) {
        return (
            <p className="h-screen font-heading text-[44px] font-bold text-gray-six lg:text-7xl">
                Loading...
            </p>
        );
    } else {
        return (
            <>
                <header>
                    <h3 className="mb-4 font-heading text-[44px] lg:text-7xl">Insurance</h3>
                </header>

                {insurance ? (
                    <>
                        <p>Here is the insurance we have saved for you.</p>

                        <div className="space-y-6 mb-6">
                            <p className="text-left mt-3 space-y-2 xs:space-y-4 lg:space-y-0 lg:mt-0 lg:text-right">
                                <button
                                    className="text-sm xs:text-base font-bold text-gem-green pr-4"
                                    onClick={() => {
                                        navigate('/dashboard/insurance/update');
                                    }}>
                                    Update My Insurance
                                </button>
                                <button
                                    className="text-sm xs:text-base font-bold text-gem-green"
                                    onClick={() => {
                                        navigate('/dashboard/insurance/add', {
                                            state: { from: '/dashboard/insurance/' },
                                        });
                                    }}>
                                    Add NEW Insurance
                                </button>
                            </p>
                            <div className="space-y-3 xs:space-y-6 border-8 border-purple-medium border-opacity-20 p-6 xs:p-8 lg:p-14">
                                <p>
                                    <span className="text-xs text-gray-six lg:text-sm">
                                        Insurance Name
                                    </span>
                                    <span className="block w-full appearance-none border-b border-gray-darker bg-transparent pb-4 font-semibold text-black  transition duration-200 ease-out-quad lg:text-lg">
                                        {isFetching ? 'Loading...' : insurance?.payer?.name}
                                    </span>
                                </p>
                                {insurance?.payer?.payerType === 'OTHER' && (
                                    <p>
                                        <span className="text-xs text-gray-six lg:text-sm">
                                            Other Insurance Name
                                        </span>
                                        <span className="block w-full appearance-none border-b border-gray-darker bg-transparent pb-4 font-semibold text-black  transition duration-200 ease-out-quad lg:text-lg">
                                            {isFetching ? 'Loading...' : insurance?.otherPayerName}
                                        </span>
                                    </p>
                                )}
                                {insurance?.payer?.payerType !== 'OTHER' && (
                                    <p>
                                        <span className="text-xs text-gray-six lg:text-sm">
                                            Member ID/Policy Number
                                        </span>
                                        <span className="block w-full appearance-none border-b border-gray-darker bg-transparent pb-4 font-semibold text-black  transition duration-200 ease-out-quad lg:text-lg">
                                            {isFetching ? 'Loading...' : insurance?.memberId}
                                        </span>
                                    </p>
                                )}
                                <InsuranceCardDisplay
                                    frontId={insurance?.cardImageFrontId}
                                    backId={insurance?.cardImageBackId}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <h3 className="font-heading text-[44px] font-bold lg:text-5xl text-center pb-4">
                            No insurance on file.
                        </h3>
                        <p className="text-center w-1/2 m-auto pb-4">
                            We don't have any insurance on file for you. Would you like to add your
                            insurance information?
                        </p>
                        <div className="text-center">
                            <button
                                className="btn-primary"
                                onClick={() => {
                                    navigate('/dashboard/insurance/add', {
                                        state: { from: '/dashboard/insurance/' },
                                    });
                                }}>
                                Add NEW Insurance
                            </button>
                        </div>
                    </>
                )}
            </>
        );
    }
};

export default CurrentInsurance;
