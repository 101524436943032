import { AnimatePresence, motion } from 'framer-motion';
import { FiArrowUpRight } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useGetBlogPostsQuery } from '../../app/services/webflow';
import { SupportCard, SupportFilters } from '../../components';
import denturesSrc from '../../images/dentures.jpg';
import { ReactComponent as QuestionMark } from '../../images/green-question-mark.svg';
import watchpatImageSrc from '../../images/sleep-study-product/watchpat.webp';
import { PageHeader } from '../../components/LayoutComponents';

const gridVariants = {
    hidden: {},
    show: {
        transition: {
            staggerChildren: 0.1,
        },
    },
};

const itemVariants = {
    hidden: {
        opacity: 0,
        y: 50,
    },
    show: {
        opacity: 1,
        y: 0,
    },
};

const Home = () => {
    const { user } = useSelector((state) => state.auth);
    const { supportFilters } = useSelector((state) => state.ui);

    const { data: blogPosts, isLoading } = useGetBlogPostsQuery();

    return (
        <>
            <PageHeader>
                {<span className="ph-no-capture">{user?.firstName}</span>}'s{' '}
                <span className="text-gray-six">resources</span>
            </PageHeader>
            <div className="lg:flex lg:items-start">
                <div className="relative mb-7 flex aspect-[3/2] flex-col justify-between overflow-hidden bg-green-hover p-5 pt-12 text-white lg:sticky lg:top-9 lg:order-2 lg:mt-9 lg:ml-12 lg:aspect-1 lg:w-80 lg:pt-14 max-h-[260px] lg:max-h-[320px]">
                    <h2 className="relative z-10">
                        <span className="text-xl font-bold lg:text-3xl">Got a question?</span>{' '}
                        <br />
                        <span className="text-xs lg:text-sm">You're not alone</span>
                    </h2>

                    <a
                        href="https://www.mygemsleep.com/faqs"
                        target="_blank"
                        rel="noreferrer"
                        className="group relative z-10 w-full bg-white p-4 text-left font-heading tracking-widest text-black hover:drop-shadow-xl lg:text-lg">
                        Frequently asked ?'s
                        <span className="absolute right-4 top-1/2 h-8 w-8 -translate-y-1/2 rounded-full bg-green-400 text-white transition group-hover:scale-105 lg:h-12 lg:w-12">
                            <FiArrowUpRight className="absolute-center transition" />
                        </span>
                    </a>
                    <QuestionMark className="absolute right-0 top-10 w-32 origin-top-right lg:-right-3 scale-[0.8] xs:scale-[1.0] lg:scale-[1.4]" />
                </div>
                <div className="lg:flex-1">
                    <SupportFilters />
                    {!isLoading && (
                        <motion.div
                            className="grid grid-cols-2 gap-5"
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            variants={gridVariants}>
                            <AnimatePresence>
                                {blogPosts
                                    .filter(
                                        (i) =>
                                            supportFilters.categories.length === 0 ||
                                            supportFilters.categories.filter((c) =>
                                                i.categories?.includes(c),
                                            ).length > 0,
                                    )
                                    .filter((i) => {
                                        if (
                                            supportFilters.type.length === 0 ||
                                            supportFilters.type.length === 2
                                        )
                                            return true;

                                        const hasVideo = 'video' in i;

                                        return supportFilters.type.includes('video')
                                            ? hasVideo
                                            : !hasVideo;
                                    })
                                    .map((blogPost, idx) => (
                                        <SupportCard
                                            key={`support-card-${blogPost._id}`}
                                            data={blogPost}
                                            variants={itemVariants}
                                            type={blogPost.video ? 'video' : 'article'}
                                        />
                                    ))}
                            </AnimatePresence>
                            <SupportCard
                                variants={itemVariants}
                                imageUrl={watchpatImageSrc}
                                to="https://www.mygemsleep.com/hst-how-to-guide"
                                title="HST How-To Guide"
                            />
                            <SupportCard
                                variants={itemVariants}
                                imageUrl={denturesSrc}
                                to="https://www.mygemsleep.com/dental-sleep-medicine"
                                title="Dental sleep medicine"
                            />
                        </motion.div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Home;
