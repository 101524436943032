import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { BsPencil, BsSave, BsXLg } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    useRemoveOrderItemMutation,
    useUpdateOrderItemMutation,
} from '../../../app/services/admin/orders';
import {
    formatSquarePrice,
    getFormattedVariationOptionsBySku,
    getVariationDataFromId,
    stringPriceToNumber,
} from '../../../utils/constants';
import Modal from '../../Modal/Modal';

function extractCustomAttribute(customAttributes, attributeName) {
    if (!customAttributes) return null;
    return Object.values(customAttributes)?.find((att) => att.name.toLowerCase() === attributeName)
        ?.stringValue;
}

function OrderItem({ gridLayout, item, isCart, orderId, patientId }) {
    //Redux state values
    const { catalogItems, catalogOptions } = useSelector((state) => state.catalog);

    //Local State
    const [submitting, setSubmitting] = useState(false);
    const [isEditting, setIsEditting] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    //Queries and Mutations
    const [updateOrderItem] = useUpdateOrderItemMutation();
    const [removeOrderItem] = useRemoveOrderItemMutation();

    //Determine variation data
    const variationData = getVariationDataFromId(item.variantId, catalogItems);
    const variationCustomAttributes = variationData?.variation?.customAttributeValues;

    //Collect data on item options if applicable as value/name
    const formattedData = getFormattedVariationOptionsBySku(item.sku, catalogItems, catalogOptions);

    const vendorSkus = extractCustomAttribute(variationCustomAttributes, 'vendor skus');

    const supplyQty = extractCustomAttribute(variationCustomAttributes, 'supply qty');

    return (
        <>
            <Formik
                key={item.id + 'Formik'}
                enableReinitialize
                initialValues={{
                    id: item?.orderId,
                    productId: item?.productId,
                    quantity: item?.quantity,
                    price: formatSquarePrice(item?.price),
                    sku: item.sku,
                    name: item.name,
                    imageUrl: item?.imageUrl,
                    catalogObjectId: item?.catalogObjectId,
                    variantId: item?.variantId,
                    categoryId: item?.categoryId,
                }}
                onSubmit={(values) => {
                    updateOrderItem({
                        orderId: orderId,
                        userId: patientId,
                        orderItemId: item?.id,
                        values: {
                            ...values,
                            quantity: parseInt(values.quantity),
                            price: stringPriceToNumber(values.price),
                        },
                    })
                        .then(() => {
                            toast.success('Order updated!', { theme: 'dark' });
                            setIsEditting(false);
                        })
                        .catch((error) => {
                            toast.error('Unable to update order', { theme: 'dark' });
                        });
                }}>
                {({ values, handleChange, handleSubmit, resetForm }) => (
                    <Form
                        className={`grid ${gridLayout} text-white text-left`}
                        onSubmit={handleSubmit}>
                        <div
                            className={`daisy-tooltip daisy-tooltip-right bg-[#717780] text-left pl-2`}
                            data-tip={item.name}>
                            <p className="py-3 pr-4 whitespace-nowrap overflow-hidden text-ellipsis">
                                {item.name}
                            </p>
                        </div>
                        <p className="pl-2 py-3 whitespace-nowrap overflow-hidden text-ellipsis bg-[#717780]">
                            {supplyQty}
                        </p>
                        <p className="py-3 whitespace-nowrap overflow-hidden text-ellipsis bg-[#717780]">
                            {item.sku}
                        </p>
                        <Field
                            disabled={!isEditting}
                            className={`pl-2 ${isEditting ? 'bg-[#9c9c9c]' : 'bg-[#717780]'}`}
                            component="input"
                            type="text"
                            onChange={handleChange}
                            value={values.quantity}
                            name="quantity"
                        />
                        <Field
                            disabled={!isEditting || isCart}
                            className={`pl-2 ${isEditting && !isCart ? 'bg-[#9c9c9c]' : 'bg-[#717780]'}`}
                            component="input"
                            type="text"
                            onChange={handleChange}
                            value={values.price}
                            name="price"
                        />
                        <button
                            type="submit"
                            disabled={!isEditting}
                            className="align-middle px-[9px] bg-[#717780]">
                            <BsSave className={`${isEditting ? 'text-white' : 'text-black'}`} />
                        </button>
                        <button
                            type="reset"
                            className="align-middle px-[5px] bg-[#717780]"
                            onClick={() => {
                                setIsEditting(!isEditting);
                            }}>
                            <BsPencil />
                        </button>
                        <button
                            type="button"
                            className="align-middle px-[5px] bg-[#717780]"
                            onClick={() => {
                                setModalOpen(true);
                            }}>
                            <BsXLg />
                        </button>
                    </Form>
                )}
            </Formik>
            <p
                key={item.id + 'vendor skus'}
                className={`bg-gray-darker text-white py-3 pl-2 w-full min-w-[1150px] ${formattedData.length === 0 && 'mb-2'}`}>
                Vendor SKUs:{' '}
                <span className="text-gray">{vendorSkus ? vendorSkus : 'No SKUs Listed'}</span>
            </p>
            {formattedData.length > 0 && (
                <div
                    key={item.id + 'options'}
                    className="bg-gray-darker mb-2 text-white py-3 pl-2 w-full min-w-[1150px]">
                    Options:{' '}
                    <div className="inline space-x-2">
                        {formattedData?.map((option) => (
                            <p key={`option-${option.name}`} className="inline text-gray">
                                {option.name}: <span className="text-white">{option.value}</span>
                            </p>
                        ))}
                    </div>
                </div>
            )}
            <Modal showClose={true} isOpen={modalOpen} closeHandler={() => setModalOpen(false)}>
                <span className="font-heading text-3xl block pb-2">DELETE ITEM</span>
                <p className="bg-[#b7bcc4] py-2 text-center">{item.name}</p>
                <p className="py-1">Are you sure you want to delete this item?</p>
                <div className="flex mt-2">
                    <button
                        disabled={submitting}
                        type="button"
                        onClick={() => {
                            setSubmitting(true);
                            removeOrderItem({
                                orderId: orderId,
                                userId: patientId,
                                orderItemId: item.id,
                            })
                                .then(() => {
                                    setSubmitting(false);
                                    setModalOpen(false);
                                })
                                .catch((error) => {
                                    toast.error(error);
                                    setSubmitting(false);
                                });
                        }}
                        className="btn-primary-small w-full text-base lg:text-lg">
                        Yes
                    </button>
                    <button
                        type="button"
                        onClick={() => setModalOpen(false)}
                        className="btn-secondary-small w-full text-base text-white lg:text-lg ml-2">
                        No
                    </button>
                </div>
            </Modal>
        </>
    );
}

export default OrderItem;
