import { useField } from 'formik';
import { isEqual } from 'lodash';
import Select from 'react-select';
import { reactSelectCustomStyles } from '../../utils/constants';

const CustomReactSelect = ({
    options,
    placeholder,
    theme = 'dark',
    className,
    label,
    showLabel,
    ...props
}) => {
    const [field, meta, helpers] = useField(props.name);
    const customStyles = reactSelectCustomStyles(theme);
    customStyles.placeholder = (provided) => ({
        ...provided,
        color: theme === 'light' ? 'rgba(43, 47, 58, 1)' : '#f0f2f5',
        opacity: 1,
        fontWeight: 400,
    });
    const newOnChange = (selectedOption) => {
        helpers.setError(undefined);
        helpers.setValue(selectedOption?.value);
        // hack to get around react-select bug triggering validation before value is set
        setTimeout(() => {
            helpers.setTouched(true);
        }, 250);
    };
    const handleBlur = () => {
        setTimeout(() => {
            helpers.setTouched(true);
        }, 250);
    };

    return (
        <div className={className}>
            {showLabel && (
                <label htmlFor={props.id} className="text-xs text-gray-six lg:text-sm">
                    {label}
                </label>
            )}
            <Select
                className="text-base  lg:text-lg"
                options={options}
                defaultValue={options.find((option) => {
                    if (option.value instanceof Object) {
                        return isEqual(option.value, field.value);
                    } else {
                        return option.value === field.value;
                    }
                })}
                onChange={newOnChange}
                onBlur={handleBlur}
                placeholder={
                    showLabel ? '' : <div className="text-xs lg:text-lg">{placeholder}</div>
                }
                styles={customStyles}
                isDisabled={props.disabled}
            />
            {meta.touched && meta.error && (
                <p className="mt-2 text-sm font-bold text-red">{meta.error}</p>
            )}
        </div>
    );
};

export default CustomReactSelect;
