import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useCreateConsentMutation,
    useGetPatientConsentsQuery,
    useGetPayersQuery,
} from '../../app/services/insurance';
import { useGetWebflowTermsQuery } from '../../app/services/webflow';
import { toggle, toggleModal } from '../../features/ui/uiSlice';
import { ReactComponent as ErrorIcon } from '../../images/icons/error.svg';
import { formatDate } from '../../utils/constants';
import { CustomCheckbox, CustomReactSelect, CustomInput } from '../InputComponents';
import posthog from 'posthog-js';

export const FormType = {
    ADD: 'ADD',
    UPDATE: 'UPDATE',
    RECHECK: 'RECHECK',
};

const InsuranceForm = ({
    targetInsurance,
    user,
    buttonCopy,
    validationSchema,
    formType = FormType.ADD,
    onSubmit,
}) => {
    const dispatch = useDispatch();
    const { agreedToInsuranceBenefits } = useSelector((state) => state.ui);
    const [isPolicyHolder, setIsPolicyHolder] = useState(true);

    //Queries and mutations
    const [createConsent] = useCreateConsentMutation();
    const { data: consents, isLoading: consentsLoading } = useGetPatientConsentsQuery();
    const { data: payers } = useGetPayersQuery();
    const { data: webflowTerms } = useGetWebflowTermsQuery();

    const referredPayerId = user?.patientProfile?.referFromOrganization?.payerId || '';
    const payer = payers?.find((p) => {
        if (targetInsurance && targetInsurance.payerId === p.id) {
            return true;
        } else if (referredPayerId && referredPayerId === p.id) {
            return true;
        } else {
            return false;
        }
    });

    const availableFields = {
        payer: payer ?? null,
        otherPayerName: '',
        agreeToBenefits: false,
        memberId: targetInsurance?.memberId ?? '',
        groupId: targetInsurance?.groupId ?? '',
        requiresGroupId: targetInsurance?.requiresGroupId ?? false,
        patientFirstName: user?.firstName ?? '',
        patientLastName: user?.lastName ?? '',
        patientDob: user ? formatDate(user.birthdate) : '',
        policyHolderFirstName: user?.firstName ?? '',
        policyHolderLastName: user?.lastName ?? '',
        policyHolderDob: user ? formatDate(user.birthdate) : '',
        policyHolderRelationship: targetInsurance?.policyHolderRelationship || 'Self',
    };

    const getFieldsFromFormType = () => {
        switch (formType) {
            case FormType.ADD:
                if (
                    consents?.results.some(
                        (consent) =>
                            consent.documentType === 'assignment-of-benefit-responsibility',
                    )
                ) {
                    return ['payer', 'memberId', 'groupId', 'otherPayerName'];
                }
                return ['payer', 'memberId', 'groupId', 'otherPayerName', 'agreeToBenefits'];
            case FormType.RECHECK:
            case FormType.UPDATE:
                return [
                    'payer',
                    'memberId',
                    'groupId',
                    'patientFirstName',
                    'patientLastName',
                    'patientDob',
                    'policyHolderFirstName',
                    'policyHolderLastName',
                    'policyHolderDob',
                    'policyHolderRelationship',
                ];
            default:
                return [];
        }
    };

    const initialValues = getFieldsFromFormType().reduce((values, key) => {
        if (key in availableFields) {
            values[key] = availableFields[key];
        }
        return values;
    }, {});

    const insuranceSubmitHandler = async (values) => {
        //determine if we need to create a new consent
        if ('agreeToBenefits' in values && values.agreeToBenefits) {
            const benefitsConsentData = webflowTerms?.items?.find(
                (t) => t.slug === 'assignment-of-benefit-responsibility',
            );

            if (benefitsConsentData) {
                await createConsent([
                    {
                        id: benefitsConsentData._id,
                        version: '' + benefitsConsentData?.version,
                        name: benefitsConsentData.name,
                        documentType: benefitsConsentData.slug,
                    },
                ]);
            }
        }

        //Remove otherPayerName if it is not necessary
        if (values.payer.payerType !== 'OTHER') delete values.otherPayerName;

        const result = onSubmit(values);

        if (formType === FormType.RECHECK) {
            posthog?.capture('Submitted insurance information for recheck');
        } else {
            posthog?.capture('Added insurance information', { payer: values.payer.name });
        }

        return result;
    };

    if (consentsLoading) {
        return <p className="py-6 font-heading text-5xl">Loading...</p>;
    } else
        return (
            <Formik
                initialValues={initialValues}
                initialErrors={{}}
                initialTouched={{}}
                onSubmit={insuranceSubmitHandler}
                validationSchema={validationSchema}>
                {({ isValid, isSubmitting, values, setFieldValue }) => (
                    <Form className="ph-no-capture space-y-4 border-8 border-purple-medium border-opacity-20 p-8 lg:p-14">
                        {formType === FormType.RECHECK && (
                            <div className="flex space-x-3 text-xs lg:text-sm">
                                <ErrorIcon className="min-w-[22px]" />
                                <p className="text-red">
                                    <b>
                                        We could not find your insurance. Please check the
                                        information you entered. For example does your name match
                                        your insurance card?
                                    </b>
                                </p>
                            </div>
                        )}

                        <CustomReactSelect
                            theme="light"
                            name="payer"
                            label="Insurance Name"
                            showLabel={true}
                            options={payers?.map((p) => ({
                                value: p,
                                label: p.name,
                            }))}
                        />

                        <CustomInput
                            theme="light"
                            name="otherPayerName"
                            placeholder="Enter insurance name"
                            className={`border-b-gray-darker lg:text-lg ${
                                values.payer?.payerType !== 'OTHER' ? 'hidden' : ''
                            }`}
                        />

                        <CustomInput
                            theme="light"
                            name="memberId"
                            label="Member ID/Policy Number"
                            showLabel={true}
                            className={`border-b-gray-darker lg:text-lg ${
                                values.payer?.payerType === 'OTHER' ? 'hidden' : ''
                            }`}
                        />
                        <CustomInput
                            theme="light"
                            name="groupId"
                            label="Group ID/Group Number"
                            showLabel={true}
                            className={`border-b-gray-darker lg:text-lg ${
                                values.payer?.payerType === 'OTHER' ||
                                (!(formType === FormType.RECHECK) && !values.payer?.requiresGroupId)
                                    ? 'hidden'
                                    : ''
                            }`}
                        />
                        <button
                            className={`link text-xs lg:text-sm ${
                                values.payer?.payerType === 'OTHER' ? 'hidden' : ''
                            }`}
                            type="button"
                            onClick={() => {
                                dispatch(toggleModal('whereToFindInsuranceInfo'));
                            }}>
                            Where do I find this?
                        </button>

                        {'agreeToBenefits' in initialValues && (
                            <CustomCheckbox
                                theme="light"
                                name="agreeToBenefits"
                                id="agreeToBenefits"
                                className={`${values.insuranceName === '' ? 'hidden' : ''}`}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    if (!agreedToInsuranceBenefits) {
                                        dispatch(toggleModal('assignmentOfBenefits'));
                                        setFieldValue('agreeToBenefits', true);
                                    } else {
                                        dispatch(toggle({ key: 'agreedToInsuranceBenefits' }));
                                        setFieldValue('agreeToBenefits', false);
                                    }
                                }}
                                label={
                                    <>
                                        {'I agree to the '}
                                        <span className="link break-words hover:cursor-pointer">
                                            Assignment of Benefits and Patient Responsibility
                                        </span>
                                    </>
                                }
                            />
                        )}

                        {'patientFirstName' in initialValues && (
                            <CustomInput
                                theme="light"
                                name="patientFirstName"
                                label="Patient First Name"
                                showLabel={true}
                                className={`border-b-gray-darker lg:text-lg`}
                            />
                        )}

                        {'patientLastName' in initialValues && (
                            <CustomInput
                                theme="light"
                                name="patientLastName"
                                label="Patient Last Name"
                                showLabel={true}
                                className={`border-b-gray-darker lg:text-lg`}
                            />
                        )}

                        {'patientDob' in initialValues && (
                            <CustomInput
                                theme="light"
                                name="patientDob"
                                label="Patient Date of Birth (mm/dd/yyyy)"
                                showLabel={true}
                                className={`border-b-gray-darker lg:text-lg`}
                            />
                        )}
                        {'policyHolderRelationship' in initialValues && (
                            <>
                                <h5 className="font-heading text-2xl font-bold lg:text-3xl">
                                    Are you the Policy Holder?
                                </h5>

                                <nav className="flex items-center justify-between space-x-6">
                                    <button
                                        className={`btn-shell w-32 flex-1 ${
                                            isPolicyHolder ? 'active' : ''
                                        }`}
                                        type="button"
                                        onClick={async () => {
                                            setIsPolicyHolder(true);
                                            await setFieldValue('policyHolderRelationship', 'Self');
                                            await setFieldValue(
                                                'policyHolderFirstName',
                                                user?.firstName,
                                            );
                                            await setFieldValue(
                                                'policyHolderLastName',
                                                user?.lastName,
                                            );
                                            await setFieldValue(
                                                'policyHolderDob',
                                                formatDate(user.birthdate),
                                            );
                                            await setFieldValue('policyHolderGender', user?.gender);
                                        }}>
                                        Yes
                                    </button>
                                    <button
                                        className={`btn-shell w-32 flex-1 ${
                                            !isPolicyHolder ? 'active' : ''
                                        }`}
                                        type="button"
                                        onClick={async () => {
                                            await setIsPolicyHolder(false);
                                            await setFieldValue(
                                                'policyHolderRelationship',
                                                'Spouse',
                                            );
                                            await setFieldValue('policyHolderFirstName', '');
                                            await setFieldValue('policyHolderLastName', '');
                                            await setFieldValue('policyHolderDob', '');
                                            // flip gender
                                            await setFieldValue(
                                                'policyHolderGender',
                                                user?.gender === 'Male' ? 'Female' : 'Male',
                                            );
                                        }}>
                                        No
                                    </button>
                                </nav>

                                <CustomInput
                                    theme="light"
                                    name="policyHolderFirstName"
                                    label="Policy Holder First Name"
                                    showLabel={true}
                                    className={`border-b-gray-darker lg:text-lg ${
                                        isPolicyHolder ? 'hidden' : ''
                                    }`}
                                />

                                <CustomInput
                                    theme="light"
                                    name="policyHolderLastName"
                                    label="Policy Holder Last Name"
                                    showLabel={true}
                                    className={`border-b-gray-darker lg:text-lg ${
                                        isPolicyHolder ? 'hidden' : ''
                                    }`}
                                />

                                <CustomInput
                                    theme="light"
                                    name="policyHolderDob"
                                    label="Policy Holder Date of Birth (mm/dd/yyyy)"
                                    showLabel={true}
                                    className={`border-b-gray-darker lg:text-lg ${
                                        isPolicyHolder ? 'hidden' : ''
                                    }`}
                                />
                            </>
                        )}

                        <button
                            className="btn-primary w-full"
                            type="submit"
                            disabled={!isValid || isSubmitting}>
                            {`${buttonCopy}`}
                        </button>

                        {formType !== FormType.RECHECK && (
                            <p className="text-xs lg:text-sm">
                                If your insurance is with an HMO plan, you need to request a
                                referral to GEM from your PCP (Primary Care Provider).
                            </p>
                        )}
                    </Form>
                )}
            </Formik>
        );
};

export default InsuranceForm;
