import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useGetAdminUsersQuery } from '../../../app/services/admin/admin';
import {
    useAddCareTeamMemberMutation,
    useRemoveCareTeamMemberMutation,
} from '../../../app/services/admin/careteam';
import { CustomReactSelect } from '../../InputComponents';
import { hasRole } from '../../../utils/roleUtils';

const CareTeam = ({ patient }) => {
    const [addCareTeamMember] = useAddCareTeamMemberMutation();
    const [removeCareTeamMember] = useRemoveCareTeamMemberMutation();
    const adminUserQuery = [
        { userRoles: { some: { role: 'MD' } } },
        { userRoles: { some: { role: 'COACH' } } },
    ];
    const { data: users } = useGetAdminUsersQuery(adminUserQuery);
    const [addToCareTeamFormActive, setAddToCareTeamFormActive] = useState(false);

    const addCareTeamMemberHandler = async (formData) => {
        await addCareTeamMember({
            userId: patient.id,
            careTeamMember: {
                userId: formData.careTeamUserId,
                role: formData.role === 'MD' ? 'CLINICIAN' : formData.role,
            },
        });
        setAddToCareTeamFormActive(false);
    };
    return (
        <div>
            {addToCareTeamFormActive && (
                <Formik
                    initialValues={{
                        role: 'COACH',
                        careTeamUserId: '',
                    }}
                    onSubmit={addCareTeamMemberHandler}>
                    {({ values, dirty, isValid, isSubmitting }) => (
                        <Form className="relative space-y-6 rounded-lg border border-gray p-4">
                            <div className="flex items-center space-x-4">
                                <span className="w-32 text-gray">Role</span>
                                <CustomReactSelect
                                    name="role"
                                    options={[
                                        {
                                            label: 'Coach',
                                            value: 'COACH',
                                        },
                                        {
                                            label: 'Clinician',
                                            value: 'MD',
                                        },
                                    ]}
                                    className="flex-1"
                                />
                            </div>
                            <div className="flex items-center space-x-4">
                                <span className="w-32 text-gray">Member</span>
                                <CustomReactSelect
                                    name="careTeamUserId"
                                    placeholder="Choose member"
                                    options={users.results
                                        .filter((member) => hasRole(member.userRoles, values.role))
                                        .filter(
                                            (member) =>
                                                !patient.careTeam
                                                    .map((ct) => ct.careTeamUserId)
                                                    .includes(member.id),
                                        )
                                        .map((member) => ({
                                            label: `${member.firstName} ${member.lastName}`,
                                            value: member.id,
                                        }))}
                                    className="flex-1"
                                />
                            </div>
                            <div className="my-4 flex gap-4">
                                <button
                                    type="submit"
                                    className="btn-primary-small"
                                    disabled={isSubmitting}>
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    className="btn-secondary-small"
                                    onClick={() => setAddToCareTeamFormActive(false)}>
                                    Cancel
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}

            {!addToCareTeamFormActive && (
                <h2 className="justify-right mb-10 flex items-center">
                    <button
                        className="border-b-2 border-gem-green font-bold"
                        onClick={async (ev) => {
                            setAddToCareTeamFormActive(true);
                        }}>
                        Add member to care team
                    </button>
                </h2>
            )}

            <ul className="grid min-w-[1000px] grid-cols-3 gap-4 p-4 opacity-100 transition">
                {patient.careTeam.length > 0 ? (
                    patient.careTeam.map((careTeamMember, idx) => (
                        <div
                            key={`user-${careTeamMember.id}`}
                            className="relative rounded-lg border border-gray p-4">
                            <p>
                                <span className="font-bold">{`${careTeamMember.role}`}</span>
                            </p>
                            <p>
                                <span className="font-bold">{`${careTeamMember.careTeamUser.firstName} ${careTeamMember.careTeamUser.lastName}`}</span>
                            </p>
                            <p className="mb-5 text-sm">{careTeamMember.careTeamUser.email}</p>
                            <p className="mb-5 text-sm">
                                Assigned on:{' '}
                                {new Date(careTeamMember.createdAt).toLocaleDateString()}
                            </p>
                            <button
                                className="btn-secondary-small text-black hover:text-white"
                                onClick={async (ev) => {
                                    await removeCareTeamMember({
                                        userId: patient.id,
                                        careTeamMember: {
                                            userId: careTeamMember.careTeamUserId,
                                            role: careTeamMember.role,
                                        },
                                    });
                                }}>
                                Remove from careteam
                            </button>
                        </div>
                    ))
                ) : (
                    <li className="font-heading text-3xl">No careteam members found.</li>
                )}
            </ul>
        </div>
    );
};

export default CareTeam;
