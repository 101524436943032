import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill-overrides.css';

const RichTextEditor = ({ value, onChangeHandler, className }) => {
    const modules = {
        toolbar: [['bold', 'italic'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']],
    };

    return (
        <ReactQuill
            theme="snow"
            modules={modules}
            value={value}
            onChange={onChangeHandler}
            className={className}
        />
    );
};

export default RichTextEditor;
