import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetBlogPostsQuery } from '../../app/services/webflow';

const Article = () => {
    const { articleSlug } = useParams();
    const [blogPost, setBlogPost] = useState(null);
    const { data: blogPosts, isLoading } = useGetBlogPostsQuery();

    useEffect(() => {
        if (!articleSlug || !blogPosts) return;

        const targetPost = blogPosts.find((p) => p.slug === articleSlug);

        if (targetPost) setBlogPost(targetPost);
    }, [articleSlug, blogPosts]);

    return isLoading ? (
        <h3 className="mb-7 font-heading text-[44px]">Loading...</h3>
    ) : !blogPost ? (
        <h3 className="mb-7 font-heading text-[44px]">No article found.</h3>
    ) : (
        <div className="mx-auto max-w-5xl">
            {!blogPost?.video && blogPost['main-image-link'] ? (
                <a
                    href={`${blogPost['main-image-link']}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <img src={blogPost['main-image']?.url} alt="" className="mb-4 lg:mb-6" />
                </a>
            ) : (
                <img src={blogPost['main-image']?.url} alt="" className="mb-4 lg:mb-6" />
            )}
            {blogPost?.video && (
                <div
                    className="responsive-video mb-5 bg-black"
                    dangerouslySetInnerHTML={{ __html: blogPost.video.metadata.html }}></div>
            )}

            <h3
                className={`mb-5 border-b border-gray pb-5 leading-none text-[44px] font-heading
			${blogPost.name.length < 40 && 'lg:text-7xl'}
			`}>
                {blogPost.name}
            </h3>
            <div className="leading-normal lg:flex lg:space-x-12">
                {blogPost['post-summary'] && (
                    <p className="lg:min-w-[300px]">{blogPost['post-summary']}</p>
                )}
                <div
                    className="article-content"
                    dangerouslySetInnerHTML={{ __html: blogPost['post-body'] }}></div>
            </div>
        </div>
    );
};

export default Article;
