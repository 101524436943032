import { Formik, Form } from 'formik';
import { FiArrowUpRight } from 'react-icons/fi';
import { heightSchema } from '../../utils/schemas';
import { BigInput } from '.';
import { useSelector } from 'react-redux';

const HeightInput = ({ submitHandler }) => {
    const { answers } = useSelector((state) => state.assessment);

    const ft = answers.heightInches ? Math.floor(answers.heightInches / 12) : '';
    const inc = answers.heightInches ? answers.heightInches % 12 : '';

    return (
        <Formik
            initialValues={{ feet: ft, inches: inc }}
            validationSchema={heightSchema}
            onSubmit={(data) => {
                submitHandler(+data.feet * 12 + +data.inches);
            }}>
            {({ dirty, isValid }) => (
                <Form className="max-w-4xl mx-auto mb-10 relative pb-1 lg:pb-2 flex items-end justify-center space-x-5">
                    <BigInput
                        name="feet"
                        type="text"
                        inputMode="numeric"
                        placeholder="FEET"
                        maxLength={1}
                        className="w-full max-w-[45%] lg:max-w-none"
                        autoFocus
                        data-attr="feet-input"
                    />
                    <BigInput
                        name="inches"
                        type="text"
                        inputMode="numeric"
                        placeholder="INCHES"
                        maxLength={2}
                        className="w-full max-w-[45%] lg:max-w-none"
                        data-attr="inches-input"
                    />
                    <button
                        type="submit"
                        disabled={!dirty || !isValid}
                        className="text-lg bg-green-400 rounded-full w-8 h-8 lg:w-12 lg:h-12 absolute right-0 bottom-2 lg:top-1/2 lg:-translate-y-1/2 disabled:opacity-50 translate-x-3 lg:translate-x-0">
                        <FiArrowUpRight className="absolute-center" />
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export default HeightInput;
