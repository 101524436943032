import { createRoot } from 'react-dom/client';
import App from './App';

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import {
    BrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { store } from './app/store';

import 'react-toastify/dist/ReactToastify.min.css';
import './index.css';

let errorLink = undefined;
if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
    const strippedBaseUrl = process.env.REACT_APP_API_BASE_URL?.replace(/(^\w+:|^)\/\//, '');
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
        ],
        tracePropagationTargets: [strippedBaseUrl],
        release: process.env.REACT_APP_CURRENT_VERSION,
        tracesSampleRate: process.env.REACT_APP_ENVIRONMENT === 'production' ? 0.1 : 0.2,
        environment: process.env.REACT_APP_ENVIRONMENT,
    });

    errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.map(({ message, locations, path }) => Sentry.captureMessage(message));
        if (networkError) {
            Sentry.captureException(networkError);
        }
    });
}

const links = errorLink
    ? [errorLink, new HttpLink({ uri: process.env.REACT_APP_HEALTHIE_ENDPOINT })]
    : [new HttpLink({ uri: process.env.REACT_APP_HEALTHIE_ENDPOINT })];

const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: from(links),
});

let params = new URLSearchParams(window.location.search);
let distinctPostHogId = params.get('phid');

if (process.env.REACT_APP_PUBLIC_POSTHOG_KEY) {
    if (distinctPostHogId) {
        posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
            api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
            bootstrap: { distinctID: distinctPostHogId, isIdentifiedID: false },
        });
    } else {
        posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
            api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        });
    }
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <ApolloProvider client={apolloClient}>
        <Provider store={store}>
            <BrowserRouter>
                <PostHogProvider client={posthog}>
                    <App />
                    <ToastContainer autoClose={1500} />
                </PostHogProvider>
            </BrowserRouter>
        </Provider>
    </ApolloProvider>,
);
